import { useRequest } from 'hooks/useRequest'
import { TextBlock, LoadBar, CardError, InputArea } from 'components'
import { useTranslation } from 'react-i18next'
import { disapproveScan, redoScanResponses } from 'store/qcScans/actions'
import ScanReviewSection from 'qualityControl/scanReview/ScanReviewSection/ScanReviewSection'
import { connect } from 'react-redux'
import { formatDisplayName } from 'utils/formatting'
import { Button } from 'react-md'
import { useCurrent } from 'contexts/index'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { qcPacketFields } from 'constants/qualityControl'
import RedoQcButton from '../RedoQcButton'
import styles from './IssuePanel.module.scss'

const IssuePanel = ({
  currentScan,
  currentReviews,
  redoScanResponses,
  disapproveScan,
  goToNext,
}) => {
  const { t } = useTranslation()
  const { isLoading, hasErrors, makeRequest } = useRequest(redoScanResponses)
  const currentScanNotes = currentScan.notes
  const { doesCurrentUserHavePermission } = useCurrent()
  const { getPackets, inspectedUser, getInspectedUser } = useQualityControl()
  const canPerformVisualQC = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'visual_qc',
  })

  const isAutoReviewed =
    currentReviews.responses.length &&
    !currentReviews.responses.filter(r => r.user).length

  const reviewerName = new Set(
    currentReviews.responses.map(r => formatDisplayName(r.user))
  )

  const { makeRequest: makeDisapproveRequest } = useRequest(disapproveScan, {
    onSuccess: () => {
      inspectedUser.id
        ? getInspectedUser(inspectedUser.id)
        : getPackets({
            fields: qcPacketFields,
          })

      goToNext()
    },
  })

  const issuesRaised = currentReviews.responses.map(r => r.response.id)

  return (
    <>
      <LoadBar show={isLoading} />
      <ScanReviewSection initExpanded={isAutoReviewed}>
        {reviewerName.size > 0 && (
          <TextBlock>
            <p className={styles.label}>{t('Found by')}</p>
            <p>{reviewerName}</p>
          </TextBlock>
        )}
        <InputArea label={t('Reasons')}>
          <ul>
            {currentReviews.responses.length &&
              currentReviews.responses.map(r => (
                <li key={`${r.response.response}-issue`}>
                  {t(r.response.description)}
                </li>
              ))}
          </ul>
        </InputArea>
        <InputArea label={t('Notes')}>{currentScanNotes}</InputArea>
        <div className="button__group button__block">
          <RedoQcButton
            redoResponses={() => makeRequest(currentScan)}
            isAutoReviewed={isAutoReviewed}
            issuesRaised={issuesRaised}
          />
          {isAutoReviewed && (
            <Button
              raised
              secondary
              disabled={!canPerformVisualQC}
              onClick={() => {
                makeDisapproveRequest(currentScan.id, '', issuesRaised)
              }}
            >
              {t('Submit responses')}
            </Button>
          )}
        </div>
      </ScanReviewSection>
      <CardError hide={!hasErrors} />
    </>
  )
}

export default connect(null, { redoScanResponses, disapproveScan })(IssuePanel)
