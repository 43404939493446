import { createContext, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { debounce } from 'lodash/function'
import {
  fetchImportErrors,
  fetchImportError,
} from 'requests/duplicatePrevention'
import { fetchPeopleDuplicates } from 'requests/people'
import { useReactRouter, useRequest } from 'hooks'
import { unmaskPhone } from 'utils/inputMasks'
import { getExtraFields, getMissingFields } from './utils'

export const RecordContext = createContext()

const PER = 25

const RecordContextProvider = ({ children }) => {
  const { match, history } = useReactRouter()

  const [row, setRow] = useState(0)
  const [resolvedFields, setResolvedFields] = useState({})
  const [hideConfirmMerge, setHideConfirmMerge] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [mainRecordDifferences, setMainRecordDifferences] = useState({})
  const setDifferences = incomingDiff => {
    const newDiff = {}
    Object.keys(incomingDiff).forEach(diffKey => {
      newDiff[diffKey] =
        incomingDiff[diffKey] || !!mainRecordDifferences[diffKey]
    })
    setMainRecordDifferences(newDiff)
  }

  const DUPLICATE_REQ_BASE_PARAMS = {
    associations: [
      'residential_address',
      'contact_methods',
      'attended_events',
      'teams',
      'organizations',
    ],
    fields: [
      'id',
      'first_name',
      'middle_name',
      'last_name',
      'prefix',
      'suffix_name',
      'primary_phone_number',
      'primary_email_address',
      'polling_info',
      'birth_date',
      'state',
      'external_id',
      { attended_events: ['id'] },
      { teams: ['id'] },
      { organizations: ['id'] },
      {
        residential_address: [
          'line_one',
          'line_two',
          'city',
          'state',
          'zipcode',
        ],
      },
    ],
  }

  const {
    makeRequest: importReq,
    response: importRes,
    isLoading: importReqLoading,
    hasErrors: importReqError,
  } = useRequest(params =>
    fetchImportErrors(match.params.importId, {
      ...params,
      per: PER,
      fields: ['id', 'row_data', 'duplicate_found'],
    })
  )

  const {
    makeRequest: initialImportRecordReq,
    response: initialImportRecordRes,
    isLoading: initialImportRecordReqLoading,
    hasErrors: initialImportRecordReqError,
  } = useRequest(
    () =>
      fetchImportError(match.params.importId, match.params.recordId, {
        fields: ['id', 'row_data', 'errors_triggered', 'index'],
      }),
    {
      onSuccess: result => {
        const {
          'imports/error_row': { index },
        } = result
        importReq(result)
        setRow(index)
      },
    }
  )

  const {
    makeRequest: duplicateReq,
    response: { people: duplicateRes } = {},
    isLoading: duplicateReqLoading,
  } = useRequest(fetchPeopleDuplicates)

  useEffect(() => {
    initialImportRecordReq()
  }, [])

  const errorRecord = useMemo(() => {
    if (row === 0 && initialImportRecordRes) {
      const errorRow = initialImportRecordRes['imports/error_row']
      const errorRecord = { ...errorRow.row_data, id: errorRow.id }
      Object.keys(errorRecord).forEach(key => {
        if (key === 'residential_address') {
          Object.keys(errorRecord[key]).forEach(addressKey => {
            if (
              errorRecord[key][addressKey] === '' ||
              errorRecord[key][addressKey] === null
            ) {
              delete errorRecord[key][addressKey]
            }
          })
        }
        if (errorRecord[key] === '' || errorRecord[key] === null) {
          delete errorRecord[key]
        }
      })
      return errorRecord
    }
    const currentPage = importRes?.meta.current_page || 1
    const i = row - (currentPage - 1) * PER - 1
    const errorRow = importRes?.['imports/error_rows']?.[i]
    const errorRecord = errorRow
      ? { ...errorRow.row_data, id: errorRow.id }
      : null
    if (!errorRecord) {
      return errorRecord
    }
    Object.keys(errorRecord).forEach(key => {
      if (key === 'residential_address') {
        Object.keys(errorRecord[key]).forEach(addressKey => {
          if (
            errorRecord[key][addressKey] === '' ||
            errorRecord[key][addressKey] === null
          ) {
            delete errorRecord[key][addressKey]
          }
        })
      }
      if (errorRecord[key] === '' || errorRecord[key] === null) {
        delete errorRecord[key]
      }
    })
    return errorRecord
  }, [initialImportRecordRes, importRes, row])

  const [debouncedErrorRecordChange] = useDebounce(errorRecord, 500)

  const missingFields = useMemo(
    () => (errorRecord ? getMissingFields(errorRecord) : []),
    [debouncedErrorRecordChange]
  )

  const extraFields = useMemo(
    () => (errorRecord ? getExtraFields(errorRecord) : []),
    [debouncedErrorRecordChange]
  )

  useEffect(() => {
    if (errorRecord && errorRecord.id !== +match.params.recordId) {
      history.replace(
        `/data_sources/imports/${match.params.importId}/duplicate_prevention/${errorRecord.id}`
      )
    }
  }, [debouncedErrorRecordChange])

  useEffect(() => {
    setResolvedFields({})
  }, [debouncedErrorRecordChange])

  const reqDuplicateUpdate = useMemo(
    () =>
      debounce(
        fields =>
          duplicateReq({
            ...DUPLICATE_REQ_BASE_PARAMS,
            attrs: {
              first_name: errorRecord?.first_name,
              last_name: errorRecord?.last_name,
              primary_email_address: errorRecord?.primary_email_address,
              primary_phone_number: unmaskPhone(
                errorRecord?.primary_phone_number
              ),
              external_id: errorRecord?.external_id,
              ...fields,
            },
          }),
        500
      ),
    [debouncedErrorRecordChange]
  )

  useEffect(() => {
    if (errorRecord) {
      duplicateReq({
        ...DUPLICATE_REQ_BASE_PARAMS,
        attrs: {
          first_name: errorRecord.first_name,
          last_name: errorRecord.last_name,
          primary_email_address: errorRecord.primary_email_address,
          primary_phone_number: unmaskPhone(errorRecord.primary_phone_number),
          external_id: errorRecord.external_id,
        },
      })
    }
  }, [debouncedErrorRecordChange])

  useEffect(() => {
    if (!importRes?.meta) return
    const pageCount = Math.ceil(importRes.meta.total_count / PER)
    const page = importRes.meta.current_page

    const maxRow = page * PER
    const minRow = (page - 1) * PER + 1

    if (row > maxRow && page + 1 <= pageCount) {
      importReq({ current_page: page + 1 })
    }

    if (row < minRow && page - 1 > 0) {
      importReq({ current_page: page - 1 })
    }
  }, [importRes?.meta, row])

  useEffect(() => {
    const errorRows = importRes?.['imports/error_rows']
    if (errorRows && errorRows.length === 0) {
      history.push(
        `/data_sources/imports/${match.params.importId}/duplicate_prevention/done`
      )
    }
  }, [importRes])

  const hasDuplicateWithSameExternalId = !!(duplicateRes || []).find(
    person =>
      person.external_id === errorRecord?.external_id &&
      errorRecord?.external_id
  )

  return (
    <RecordContext.Provider
      value={{
        errorRecord,
        row,
        resolvedFields,
        setResolvedFields,
        setRow,
        importRes,
        duplicateRes,
        importReqLoading,
        initialImportRecordReqLoading,
        duplicateReqLoading,
        importReq,
        missingFields,
        extraFields,
        hideConfirmMerge,
        setHideConfirmMerge,
        reqDuplicateUpdate,
        importReqError,
        initialImportRecordReqError,
        readOnly,
        setReadOnly,
        hasDuplicateWithSameExternalId,
        mainRecordDifferences,
        setDifferences,
      }}
    >
      {children}
    </RecordContext.Provider>
  )
}

export default RecordContextProvider
