import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { Button, ButtonBlock, CardError, Toast, TextBlock } from 'components'
import { Modal } from '@politechdev/blocks-design-system'
import { useReactRouter, DEPRECATED_useRequest } from 'hooks'
import { putDiscardAllImportErrors } from 'requests/duplicatePrevention'
import { RecordsContext } from '../RecordsContext/RecordsContext'

const RecordsDiscardAllModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const [toastText, setToastText] = useState('')
  const { count } = useContext(RecordsContext)
  const onSuccess = () => {
    setToastText(t('Success'))
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      history.push(
        `/data_sources/imports/${match.params.importId}/duplicate_prevention/done`
      )
    }, 1500)
  }

  const { makeRequest, isLoading, errorMsg } = DEPRECATED_useRequest(
    putDiscardAllImportErrors,
    onSuccess
  )

  return (
    <Modal
      id="merge-error-record-dialog"
      title={t('Confirm discard')}
      showLoading={isLoading}
      isOpen={isOpen}
    >
      <Modal.Body>
        <TextBlock>
          {`${t('You are about to discard')} ${count} ${t(
            'import records that have errors without seeing them.'
          )}`}
        </TextBlock>
        <CardError
          hide={!errorMsg}
          message={t('An error occurred while merging this record.')}
        />
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button flat onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={isLoading || toastText}
            onClick={() => {
              makeRequest(match.params.importId)
            }}
            raised
            warn
          >
            {t('Confirm discard')}
          </Button>
          <Toast visible={!!toastText} text={toastText} success />
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default RecordsDiscardAllModal
