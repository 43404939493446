import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentBlock,
  FieldBlock,
  Section,
  SectionLabel,
  Font,
  Radio,
  DateField,
  SelectField,
  TextField,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { CheckboxGroup } from 'components/index'
import { useParams } from 'react-router'
import { get } from 'lodash'
import { COUNTRIES, US_STATES } from 'utils/constants'
import NameField from './components/NameField'
import AddressLookup from './components/AddressLookup'
import DocumentViewer from './components/DocumentViewer'
import VDRBlock from './components/VDRBlock'
import styles from './CustomDataEntry.module.scss'
import { FIELD_TYPES } from './constants'
import { getHardCopyAttribute } from './utils'
import { validateDate } from './validation'

const FormRenderer = ({
  localForm,
  formConfig,
  submitAttempted,
  isEditing,
  setField,
}) => {
  const { t: _t } = useTranslation()
  const t = (key, options) => _t(key, { context: 'form_renderer', ...options })
  const { getField, setFieldError, fieldErrors } = localForm

  const { pageId } = useParams()

  const fieldSetter =
    (field, validate = () => null) =>
    value => {
      setFieldError(field, validate(value))
      setField(value, field)
    }

  const translateLabelUnlessCustom = (field, key) => {
    const lableable = key || field
    return field.custom ? lableable.label : t(lableable.label)
  }

  return (
    <>
      {formConfig.map(section => {
        const isDocumentAlreadySelected = getField(
          `documents.${section.id}`
        ).fileUrl

        if (section.scanRequired && !isDocumentAlreadySelected) {
          return null
        }

        const sectionHasHardCopyField = section.fields.find(
          ({ attribute }) => attribute === getHardCopyAttribute(section)
        )

        const shouldShowSectionForm =
          section.hasDocument ||
          !sectionHasHardCopyField ||
          getField(getHardCopyAttribute(section))

        return (
          <Section key={section.id}>
            <ContentBlock>
              <SectionLabel>{t(section.label)}</SectionLabel>
              {sectionHasHardCopyField ? (
                <Checkbox
                  label={t('Has associated {{ record }}', {
                    record: section.label.toLowerCase(),
                  })}
                  onChange={fieldSetter(getHardCopyAttribute(section))}
                  checked={getField(getHardCopyAttribute(section))}
                />
              ) : null}
              {getField(`meta.${section.id}.isEmpty`) && submitAttempted && (
                <Font.Copy variant="hint" className={styles['error-text']}>
                  {t('This section cannot be empty')}
                </Font.Copy>
              )}
            </ContentBlock>
            <hr />
            {shouldShowSectionForm ? (
              <div
                className={
                  section.hasDocument ? styles['two-panel'] : undefined
                }
              >
                <div>
                  {section.fields.map(field => {
                    switch (field.component) {
                      case FIELD_TYPES.NAME_BLOCK:
                        return (
                          <NameField
                            key={field.attribute}
                            getField={getField}
                            fieldSetter={fieldSetter}
                            fieldErrors={fieldErrors}
                            attributes={field.attributeKeyTuples}
                            section={section.id}
                            field={field}
                            formConfig={formConfig}
                          />
                        )
                      case FIELD_TYPES.ADDRESS_BLOCK:
                        return (
                          <Fragment key={field.prefix}>
                            <div style={{ padding: '2rem 2rem 0rem' }}>
                              <Font.Copy Element="label" variant="secondary">
                                {translateLabelUnlessCustom(field)}
                              </Font.Copy>
                            </div>
                            <AddressLookup
                              fieldSetter={fieldSetter}
                              getField={getField}
                              fieldErrors={fieldErrors}
                              attributes={field.attributeKeyTuples}
                              section={section.id}
                              group={field.group}
                              field={field}
                              formConfig={formConfig}
                              isEditing={isEditing}
                            />
                          </Fragment>
                        )
                      case FIELD_TYPES.RADIO:
                        return (
                          <FieldBlock key={field.attribute}>
                            <div>
                              <Font.Copy Element="label" variant="secondary">
                                {translateLabelUnlessCustom(field)}
                              </Font.Copy>
                              <Radio.Group
                                options={[
                                  ...field.options,
                                  { label: t('Empty'), value: null },
                                ]}
                                onChange={fieldSetter(field.attribute)}
                                value={getField(field.attribute)}
                              />
                            </div>
                          </FieldBlock>
                        )
                      case FIELD_TYPES.DATE:
                        return (
                          <FieldBlock key={field.attribute}>
                            {field.showPresenceCheckbox && (
                              <Checkbox
                                label={t(field.checkboxName)}
                                onChange={fieldSetter(field.checkboxField)}
                                checked={getField(field.checkboxField)}
                              />
                            )}
                            <DateField
                              label={translateLabelUnlessCustom(field)}
                              onChange={fieldSetter(
                                field.attribute,
                                validateDate
                              )}
                              value={getField(field.attribute)}
                              error={!!get(fieldErrors, field.attribute)}
                              errorMessage={get(fieldErrors, field.attribute)}
                            />
                          </FieldBlock>
                        )
                      case FIELD_TYPES.COUNTRY_STATE_SELECT:
                        return (
                          <FieldBlock key={field.component}>
                            <div>
                              <Font.Copy Element="label" variant="secondary">
                                {translateLabelUnlessCustom(field)}
                              </Font.Copy>
                              <SelectField
                                label={t('Country')}
                                options={Object.keys(COUNTRIES).map(key => ({
                                  label: COUNTRIES[key],
                                  value: key,
                                }))}
                                value={getField(
                                  `${field.attribute}_country_part`
                                )}
                                onSelect={country => {
                                  fieldSetter(
                                    `${field.attribute}_country_part`
                                  )(country)
                                  if (country !== 'US') {
                                    fieldSetter(
                                      `${field.attribute}_state_part`
                                    )(undefined)
                                    fieldSetter(field.attribute)(country)
                                  }
                                }}
                              />
                              {getField(`${field.attribute}_country_part`) ===
                                'US' && (
                                <SelectField
                                  label={t('State')}
                                  options={Object.keys(US_STATES).map(key => ({
                                    label: US_STATES[key],
                                    value: key,
                                  }))}
                                  value={getField(
                                    `${field.attribute}_state_part`
                                  )}
                                  onSelect={state => {
                                    fieldSetter(
                                      `${field.attribute}_state_part`
                                    )(state)
                                    fieldSetter(field.attribute)(`US-${state}`)
                                  }}
                                />
                              )}
                            </div>
                          </FieldBlock>
                        )
                      case FIELD_TYPES.CHECKBOX:
                        return (
                          <ContentBlock key={field.attribute}>
                            <Checkbox
                              label={translateLabelUnlessCustom(field)}
                              onChange={fieldSetter(field.attribute)}
                              checked={getField(field.attribute)}
                              error={!!get(fieldErrors, field.attribute)}
                              errorMessage={get(fieldErrors, field.attribute)}
                            />
                          </ContentBlock>
                        )
                      case FIELD_TYPES.TEXT:
                        return (
                          <FieldBlock
                            key={field.attribute}
                            variant={field.blockSize}
                          >
                            <TextField
                              type={field.type || 'text'}
                              label={translateLabelUnlessCustom(field)}
                              value={getField(field.attribute)}
                              onChange={fieldSetter(field.attribute)}
                              onValidate={({ isError }) =>
                                setFieldError(field.attribute, isError || null)
                              }
                              error={!!get(fieldErrors, field.attribute)}
                              errorMessage={get(fieldErrors, field.attribute)}
                            />
                          </FieldBlock>
                        )
                      case FIELD_TYPES.VDR_BLOCK:
                        return (
                          <VDRBlock
                            key={field.attribute}
                            getField={getField}
                            fieldSetter={fieldSetter}
                            attributes={field.attributeKeyTuples}
                            fieldErrors={fieldErrors}
                          />
                        )
                      case FIELD_TYPES.SELECT: {
                        return (
                          <FieldBlock key={field.attribute}>
                            <SelectField
                              label={translateLabelUnlessCustom(field)}
                              value={getField(field.attribute)}
                              onSelect={fieldSetter(field.attribute)}
                              options={field.options.map(option => ({
                                ...option,
                                label: translateLabelUnlessCustom(
                                  field,
                                  option
                                ),
                              }))}
                              clearable
                              onClear={() => fieldSetter(field.attribute)(null)}
                              error={!!get(fieldErrors, field.attribute)}
                              errorMessage={get(fieldErrors, field.attribute)}
                            />
                          </FieldBlock>
                        )
                      }
                      case FIELD_TYPES.CHECKBOX_GROUP: {
                        return (
                          <FieldBlock key={field.attribute}>
                            <div>
                              <Font.Copy Element="label" variant="secondary">
                                {translateLabelUnlessCustom(field)}
                              </Font.Copy>
                              <CheckboxGroup
                                values={getField(field.attribute, [])}
                                onChange={fieldSetter(field.attribute)}
                                options={field.options}
                                error={!!get(fieldErrors, field.attribute)}
                                errorText={get(fieldErrors, field.attribute)}
                              />
                            </div>
                          </FieldBlock>
                        )
                      }
                      default:
                        return undefined
                    }
                  })}
                </div>
                <div>
                  {section.hasDocument && (
                    <ContentBlock>
                      <DocumentViewer
                        fileUrl={getField(`documents.${section.id}`).fileUrl}
                        page={+pageId}
                        documentName={section.label}
                      />
                    </ContentBlock>
                  )}
                </div>
              </div>
            ) : null}
          </Section>
        )
      })}
    </>
  )
}

export default FormRenderer
