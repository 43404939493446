/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react'

export const ToastContext = createContext()

const ToastProvider = ({ children }) => {
  const [text, setText] = useState('')
  const [visible, setVisible] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const setToast = ({ text, success = false, error = false }) => {
    setText(text)
    setSuccess(success)
    setError(error)
    setVisible(true)
    setTimeout(() => {
      setText('')
      setSuccess(false)
      setError(false)
      setVisible(false)
    }, 3000)
  }
  return (
    <ToastContext.Provider
      value={{
        text,
        visible,
        success,
        error,
        setToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)

export default ToastProvider
