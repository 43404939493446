import React from 'react'
import {
  ButtonBlock,
  Button,
  Checkbox,
  Icon,
} from '@politechdev/blocks-design-system'
import { connect } from 'react-redux'
import {
  getVisualUnreviewedScans,
  getVisualUnapprovedScans,
  getCurrentVoterRegistrationScans,
} from 'store/qcScans/reducer'
import styles from './PacketPaginationControls.module.scss'
import PageIndicator from './PageIndicator'

const getScanStatus = visualReviews => {
  if (!visualReviews.filter(vr => vr.user).length) return 'unreviewed'

  if (visualReviews?.every(review => review.response.response === 'approved')) {
    return 'approved'
  }

  return 'issue'
}

const PacketPaginationControls = ({
  scans,
  currentScanIndex,
  scansCount,
  goToNext,
  goToPrevious,
  unreviewedScans,
  unapprovedScans,
  shouldSkip,
  toggleSkip,
  skipLabel,
  showSkipCheckbox = true,
  showStatusIcons,
}) => (
  <div className={styles.container}>
    <ButtonBlock justify="center">
      <Button.Secondary
        onClick={goToPrevious}
        disabled={scansCount < 2 || (currentScanIndex <= 0 && !shouldSkip)}
      >
        <Icon.ArrowLeft />
      </Button.Secondary>
      <Button.Secondary
        onClick={goToNext}
        disabled={
          scansCount < 2 || (currentScanIndex + 1 === scansCount && !shouldSkip)
        }
      >
        <Icon.ArrowRight />
      </Button.Secondary>
    </ButtonBlock>
    <div className={styles['page-indicators']}>
      {scans.map((scan, index) => (
        <PageIndicator
          isActive={index === currentScanIndex}
          key={scan.scan_number}
          status={getScanStatus(scan.visual_reviews)}
          showStatusIcons={showStatusIcons}
          scan={scan}
        />
      ))}
    </div>
    {showSkipCheckbox && (
      <Checkbox
        id="approved-scans-checkbox"
        name="approved-scans-checkbox"
        label={skipLabel}
        className={styles.checkbox}
        disabled={!unreviewedScans.length || !unapprovedScans.length}
        checked={shouldSkip}
        onChange={() => toggleSkip(!shouldSkip)}
      />
    )}
  </div>
)

export default connect(state => ({
  unreviewedScans: getVisualUnreviewedScans(state),
  unapprovedScans: getVisualUnapprovedScans(state),
  scans: getCurrentVoterRegistrationScans(state),
}))(PacketPaginationControls)
