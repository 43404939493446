import {
  Checkbox,
  FieldBlock,
  TextField,
} from '@politechdev/blocks-design-system'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { createAttributeMap, getCopyFromData } from '../utils'

const NameField = ({
  getField,
  fieldSetter,
  attributes = [],
  section,
  field,
  formConfig,
  fieldErrors,
}) => {
  const { t } = useTranslation()
  const [fieldMap, setFieldMap] = useState({})
  const { copyMap, copiedFieldLabel, copiedSectionLabel } = getCopyFromData(
    section,
    field,
    formConfig
  )
  const hasCopyableFields = !!copyMap && !!Object.entries(copyMap).length
  const isCopied = getField(`meta.copiedFields.${field.localId}.shouldCopy`)

  const setField = attribute => {
    const setter = fieldSetter(attribute)
    return value => setter(value)
  }

  useEffect(() => {
    setFieldMap(createAttributeMap(attributes))
  }, [attributes])

  const handleCopyToggle = shouldCopy => {
    const copyPairs = Object.entries(copyMap)
    setField(`meta.copiedFields.${field.localId}`)({ copyPairs, shouldCopy })
  }

  return (
    <>
      {hasCopyableFields ? (
        <FieldBlock>
          <Checkbox
            variant="large"
            label={t('Same as {{section}} - {{field}}', {
              section: copiedSectionLabel,
              field: copiedFieldLabel,
            })}
            checked={isCopied}
            onChange={handleCopyToggle}
          />
        </FieldBlock>
      ) : null}
      {!isCopied && (
        <FieldBlock>
          {fieldMap.last_name ? (
            <TextField
              type="text"
              name="last-name"
              id="last-name"
              label={t('Last name')}
              value={getField(fieldMap.last_name)}
              onChange={setField(fieldMap.last_name)}
              error={!!get(fieldErrors, fieldMap.last_name)}
              errorMessage={get(fieldErrors, fieldMap.last_name)}
            />
          ) : null}
          {fieldMap.first_name ? (
            <TextField
              type="text"
              name="first-name"
              id="first-name"
              label={t('First name')}
              value={getField(fieldMap.first_name)}
              onChange={setField(fieldMap.first_name)}
              error={!!get(fieldErrors, fieldMap.first_name)}
              errorMessage={get(fieldErrors, fieldMap.first_name)}
            />
          ) : null}
          {fieldMap.middle_name ? (
            <TextField
              type="text"
              name="middle-name"
              id="middle-name"
              label={t('Middle name')}
              value={getField(fieldMap.middle_name)}
              onChange={setField(fieldMap.middle_name)}
              error={!!get(fieldErrors, fieldMap.middle_name)}
              errorMessage={get(fieldErrors, fieldMap.middle_name)}
            />
          ) : null}
          {fieldMap.name_suffix ? (
            <TextField
              type="text"
              name="name-suffix"
              id="name-suffix"
              label={t('Suffix')}
              value={getField(fieldMap.name_suffix)}
              onChange={setField(fieldMap.name_suffix)}
              error={!!get(fieldErrors, fieldMap.name_suffix)}
              errorMessage={get(fieldErrors, fieldMap.name_suffix)}
            />
          ) : null}
        </FieldBlock>
      )}
    </>
  )
}

export default NameField
