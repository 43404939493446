import { uniq } from 'lodash'
import { formatDeliveryStatus } from 'utils/reporting'

export const shiftPacketCsvCell = data => {
  const packet = data.packet || data.petition_packet
  return packet?.original_filename || packet?.filename
}

export const shiftPacketCountyCsvCell = data => data.petition_packet?.county

export const shiftTurfNameCsvCell = data => data.turf?.name

export const shiftLocationNameCsvCell = data => data.location?.name

export const shiftCanvasserNameCsvCell = data => data.canvasser?.full_name

export const assigneeNameCsvCell = data =>
  data.petition_packet?.assignee?.full_name

export const shiftCampaignNameCsvCell = data => data.campaign?.name

export const shiftPacketUploadDateCsvCell = data => {
  const packet = data.packet || data.petition_packet
  return packet?.created_at
}

export const shiftDueDateCsvCell = data => data.packet?.qc_deadline

export const shiftVRRCsvCell = data =>
  uniq(data.visual_review_responses.map(response => response.description)).join(
    ', '
  )

export const DeliveryStatusCSVCell = data =>
  formatDeliveryStatus(data.packet?.delivered)
