import { DetailItem, SectionLabel, DenominationSelectField } from 'components'
import { ContentBlock } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const Denominations = ({ denominations, setDemographics, isEditing, hide }) => {
  const { t } = useTranslation()

  if (hide) {
    return null
  }
  return isEditing ? (
    <>
      <ContentBlock>
        <SectionLabel>{t('Denominations')}</SectionLabel>
      </ContentBlock>
      <ContentBlock>
        <div data-testid="denomination-multi-select">
          <DenominationSelectField
            denominations={denominations}
            onSelect={denominations => {
              setDemographics(prevDemographics => ({
                ...prevDemographics,
                denominations,
              }))
            }}
          />
        </div>
      </ContentBlock>
    </>
  ) : (
    <DetailItem label={t('Denominations')}>
      <div className="row--wrap align--right">
        {denominations
          .map((denomination, i) => (
            <span className="capitalize" key={i}>
              &nbsp;
              {denomination}
            </span>
          ))
          .reduce(
            (accumulator, element) =>
              accumulator === null ? [element] : [...accumulator, ',', element],
            null
          )}
      </div>
    </DetailItem>
  )
}

export default Denominations
