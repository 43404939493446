import moment from 'moment'
import { sumBy } from 'lodash/math'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { View, ViewContainer, HeaderBlock } from 'components'
import { Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchProjections } from 'requests/fieldManagement'
import { deriveStatus } from 'utils/fieldManagement'
import tableConfig from './tableConfig'

const ProjectionSelect = () => {
  const { t } = useTranslation()

  const { renderTable } = useAutoTable()

  const [rows, setRows] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const projectionMapper = projection => {
    const totalCollected = sumBy(projection.targets, p => p.actual_total_forms)

    return {
      id: projection.id,
      name: projection.name,
      turfName: projection.turf.name,
      creatorName: projection.creator.name,
      total: projection.total_collected,
      startDate: projection.start_date,
      endDate: projection.end_date,
      status: deriveStatus(
        moment(projection.start_date),
        moment(projection.end_date),
        projection.total_collected,
        totalCollected
      ),
    }
  }

  const projectionsRequestMapper = params => {
    const apiNameMap = {
      creatorFullName: 'creator_full_name',
      startDate: 'start_date',
      endDate: 'end_date',
      total: 'total_collected',
    }
    const mappedParams = {
      sort_attr: params.sortColumn
        ? apiNameMap[params.sortColumn] || params.sortColumn
        : 'id',
      sort_dir: params.sortOrder,
      per: params.pageSize,
      current_page: params.page,
      filters: {
        rules: params.filterRules.map(r => ({
          ...r,
          column: apiNameMap[r.column] || r.column,
        })),
        conjunction: params.filterConjunction,
      },
    }
    return fetchProjections({
      ...mappedParams,
      fields: [
        'id',
        'name',
        'start_date',
        'end_date',
        'targets',
        'total_collected',
        { turf: ['id', 'name'] },
        { creator: ['name'] },
      ],
      associations: ['turf', 'creator'],
    }).then(({ meta, 'field_management/projections': projections }) => ({
      tableRows: projections.map(projectionMapper),
      totalRecords: meta.total_count,
    }))
  }

  const { makeRequest: projectionsRequest } = useRequest(
    projectionsRequestMapper,
    {
      onSuccess: ({ tableRows, totalRecords }) => {
        setTotalRecords(totalRecords)
        setRows(tableRows)
      },
    }
  )

  const { columns } = tableConfig
  return (
    <View>
      <>
        <HeaderBlock title={t('Projections')}>
          <ButtonBlock justify="right">
            <Link to="/field-management/projections/new">
              <Button.Accent>{t('New projection')}</Button.Accent>
            </Link>
          </ButtonBlock>
        </HeaderBlock>
        <ViewContainer>
          {renderTable({
            data: rows,
            loading: false,
            error: null,
            totalRecords,
            columns,
            fetchAction: projectionsRequest,
          })}
        </ViewContainer>
      </>
    </View>
  )
}
export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <ProjectionSelect />
  </AutoTableProvider>
)
