import { createContext, useContext, useEffect, useState } from 'react'
import { useRequest } from 'hooks'
import { fetchTurfs } from 'requests/turfs'

export const TurfsContext = createContext()

export const TurfsProvider = ({ children }) => {
  const [currentTurfs, setCurrentTurfs] = useState([])

  const { makeRequest: turfsRequest } = useRequest(
    () => fetchTurfs({ sort_attr: 'name' }),
    {
      onSuccess: ({ turfs }) => {
        setCurrentTurfs(turfs)
      },
    }
  )

  const {
    makeRequest: refreshCurrentTurfs,
    isLoading: turfRefreshIsLoading,
    isRequestComplete: turfRefreshComplete,
  } = useRequest(() => fetchTurfs({ sort_attr: 'name' }), {
    onSuccess: ({ turfs }) => {
      setCurrentTurfs(turfs)
    },
  })

  useEffect(() => {
    turfsRequest()
  }, [])

  const preparedTurfs = currentTurfs.map(turf => ({
    ...turf,
    value: turf.id,
    label: turf.name,
  }))

  return (
    <TurfsContext.Provider
      value={{
        currentTurfs,
        refreshCurrentTurfs,
        turfRefreshIsLoading,
        turfRefreshComplete,
        preparedTurfs,
      }}
    >
      {children}
    </TurfsContext.Provider>
  )
}

export const useTurfs = () => useContext(TurfsContext)
