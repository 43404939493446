import { dateFormat } from 'utils/constants'
import moment from 'moment'

export const metrics = [
  {
    value: 'totalFormsCover',
    label: 'Total forms (cover)',
    type: 'line',
    percentage: false,
  },
  {
    value: 'totalFieldHoursCover',
    label: 'Total field hours (cover)',
    type: 'line',
    percentage: false,
  },
  {
    value: 'totalShiftHours',
    label: 'Total shift hours',
    type: 'line',
    percentage: false,
  },
  {
    value: 'totalScans',
    label: 'Total scans',
    type: 'line',
    percentage: false,
  },
  {
    value: 'totalFormsThroughQc',
    label: 'Total forms through QC',
    type: 'line',
    percentage: false,
  },
  {
    value: 'formsPerFieldHourCover',
    label: 'Forms per field hour (cover)',
    type: 'scatter',
    xKey: 'totalFieldHoursCover',
    xName: 'Total field hours (cover)',
    percentage: false,
  },
  {
    value: 'completeFormsQc',
    label: 'Complete form percent (QC)',
    type: 'scatter',
    xKey: 'totalFormsThroughQc',
    xName: 'Total forms through QC',
    percentage: true,
  },
  {
    value: 'percentMissingPhoneQc',
    label: 'Percent missing phone (QC)',
    type: 'scatter',
    xKey: 'totalFormsThroughQc',
    xName: 'Total forms through QC',
    percentage: true,
  },
  {
    value: 'percentHandwritingQc',
    label: 'Percent handwriting (QC)',
    type: 'scatter',
    xKey: 'totalFormsThroughQc',
    xName: 'Total forms through QC',
    percentage: true,
  },
  {
    value: 'percentCanvasserAssistanceQc',
    label: 'Percent canvasser assistance (QC)',
    type: 'scatter',
    xKey: 'totalFormsThroughQc',
    xName: 'Total forms through QC',
    percentage: true,
  },
  {
    value: 'percentConfirmedRegisteredQc',
    label: 'Percent confirmed registration on phone (QC)',
    type: 'scatter',
    xKey: 'totalScansCalledQc',
    xName: 'Total scans called (QC)',
    percentage: true,
  },
  {
    value: 'percentDisconnectedQc',
    label: 'Percent disconnected (QC)',
    type: 'scatter',
    xKey: 'totalScansCalledQc',
    xName: 'Total scans called (QC)',
    percentage: true,
  },
  {
    value: 'percentWrongNumberQc',
    label: 'Percent wrong number (QC)',
    type: 'scatter',
    xKey: 'totalScansCalledQc',
    xName: 'Total scans called (QC)',
    percentage: true,
  },
  {
    value: 'percentValidAddresses',
    label: 'Valid addresses %',
    type: 'scatter',
    xKey: 'totalFormsAddressValidationAttempted',
    xName: 'Total forms with verifiable addresses',
    percentage: true,
  },
  {
    value: 'percentValidAutomatedPhones',
    label: 'Valid automated phones %',
    type: 'scatter',
    xKey: 'totalFormsPhoneValidationAttempted',
    xName: 'Total forms with verifiable phone numbers',
    percentage: true,
  },
  {
    value: 'percentSameApplicantName',
    label: 'Forms with same applicant name %',
    type: 'scatter',
    xKey: 'totalFormsAddressValidationAttempted',
    xName: 'Total forms with verifiable addresses',
    percentage: true,
  },
  {
    value: 'percentDuplicateForms',
    label: 'Duplicate forms (DOB or zip) %',
    type: 'scatter',
    xKey: 'totalFormsAddressValidationAttempted',
    xName: 'Total forms with verifiable addresses',
    percentage: true,
  },
]

export const buildScatterChartData = (
  canvasserStats,
  otherCanvasserStats,
  avgStats,
  yKey,
  xKey
) => {
  const xAvg = avgStats[xKey]
  const yAvg = avgStats[yKey]
  const canvasserPoint = [
    {
      [yKey]: +canvasserStats[yKey],
      [xKey]: +canvasserStats[xKey],
    },
  ]
  const otherData = otherCanvasserStats.map(exampleCanvasser => ({
    [yKey]: +exampleCanvasser[yKey],
    [xKey]: +exampleCanvasser[xKey],
  }))

  return {
    xKey,
    xAvg,
    yAvg,
    canvasserPoint,
    otherData,
  }
}

const buildDatesArray = (startDate, endDate) => {
  const now = moment(startDate).clone()
  const dates = []
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(dateFormat))
    now.add(1, 'days')
  }
  return dates
}

export const buildLineChartData = (
  reportRange,
  canvasserStatsByDate,
  avgStatsByDate,
  key
) => {
  const dates = buildDatesArray(reportRange.startDate, reportRange.endDate)
  const lineChartData = []
  const sortedCanvasserStatsByDate = canvasserStatsByDate
    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
    .map(stats => ({ ...stats, date: moment(stats.date).format(dateFormat) }))
  const sortedAvgStatsByDate = avgStatsByDate
    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
    .map(stats => ({ ...stats, date: moment(stats.date).format(dateFormat) }))
  dates.forEach(date =>
    lineChartData.push({
      date,
      canvasser: sortedCanvasserStatsByDate.find(row => row.date === date)?.[
        key
      ],
      average: sortedAvgStatsByDate.find(row => row.date === date)?.[key],
    })
  )
  return lineChartData
}
