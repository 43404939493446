import { useState } from 'react'
import { LoadBar, CardError, FormattedData } from 'components'
import {
  Button,
  Icon,
  Radio,
  ButtonBlock,
  ContentBlock,
  Font,
  Checkbox,
  TextField,
  FieldBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import {
  ACTIVE,
  REVIEW,
  COMPLETE,
  nonParticipationOptions,
  NON_PARTICIPATION_VALUES,
} from 'constants/phoneBankSessions'
import {
  OTHER_PHONE_CONTACT_TYPE,
  PHONE_TYPES,
  PRIMARY_PHONE_CONTACT_TYPE,
} from 'constants/people'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { putPerson } from 'requests/people'
import useEvent from 'hooks/useEvent'
import { useCurrent } from 'contexts/index'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { endsWith } from 'lodash'
import styles from './SessionResponses.module.scss'

const SessionResponses = () => {
  const {
    callLoading,
    callError,
    currentCall,
    currentParticipant: participant,
    callStep,
    submitCall,
    setParticipation,
    setNonParticipationReason,
    receiveInvalidNumbers,
    clearInvalidNumbers,
  } = useCallSessionContext()

  const showParticipationPicker = [ACTIVE, REVIEW, COMPLETE].includes(callStep)
  const pickerDisabled = callStep === COMPLETE || callLoading
  const showSubmitButton = [ACTIVE, REVIEW].includes(callStep)
  const showSubmitResponse = callStep === COMPLETE
  const {
    participated,
    non_participation_reason: nonParticipationReason,
    invalid_numbers: invalidNumbers,
    responses,
  } = currentCall

  const canSubmit =
    !callLoading &&
    ((participated === true && responses.length) ||
      (participated === false && nonParticipationReason)) &&
    callStep !== ACTIVE

  const phoneNumbers = [...participant.contact_methods]
    .concat(
      participant.primary_phone_number
        ? {
            contact_type: PRIMARY_PHONE_CONTACT_TYPE,
            content: participant.primary_phone_number,
          }
        : undefined
    )
    .filter(
      contactMethod =>
        !!contactMethod &&
        !contactMethod.invalid &&
        endsWith(contactMethod.contact_type, 'phone_number')
    )

  const [isDidNotParticipateModalOpen, setIsDidNotParticipateModalOpen] =
    useState(false)

  const [notes, setNotes] = useState()

  const isInvalidNumberSelected =
    nonParticipationReason === NON_PARTICIPATION_VALUES.WRONG_NUMBER ||
    nonParticipationReason === NON_PARTICIPATION_VALUES.DISCONNECTED

  const [isInvalidNumberModalOpen, setIsInvalidNumberModalOpen] =
    useState(false)

  const {
    tenantOptions: { voip_conferences_enabled: voipConferencesEnabled },
    currentTenant: { subdomain },
  } = useCurrent()

  const invalidNumberIdsSet = invalidNumbers.reduce((contactSet, contact) => {
    contactSet.add(contact.id)

    return contactSet
  }, new Set())

  const { t } = useTranslation()

  const { makeRequest: updatePersonReq, isLoading: updatePersonLoading } =
    useRequest(putPerson)

  const updateInvalidNumbers = useEvent(invalidNumbers => {
    const invalidPrimary = invalidNumbers.find(
      contact => contact.contact_type === PRIMARY_PHONE_CONTACT_TYPE
    )
    const badNumbers = Object.fromEntries(
      invalidNumbers.map(contact => [contact.id, contact])
    )

    const updatedContactMethods = participant.contact_methods.map(
      contact => badNumbers[contact.id] || contact
    )
    if (invalidPrimary) {
      updatedContactMethods.push({
        ...invalidPrimary,
        contact_type: OTHER_PHONE_CONTACT_TYPE,
      })
    }
    return updatePersonReq(participant.id, {
      primary_phone_number: invalidPrimary ? null : undefined,
      contact_methods: updatedContactMethods,
    })
  })

  const submit = () => {
    invalidNumbers.length && updateInvalidNumbers(invalidNumbers)
    submitCall({ ...currentCall, notes }, { voipConferencesEnabled, subdomain })
    setNotes('')
  }

  if (!showParticipationPicker) return null

  const participatedOptions = [
    {
      label: t('Participated'),
      value: true,
      id: 'participated',
    },
    {
      label: t('Did not participate'),
      value: false,
      onClick: () => setIsDidNotParticipateModalOpen(true),
      id: 'not-participated',
    },
  ]

  return (
    <form>
      {callLoading && <LoadBar show />}
      {callError && <CardError hide={!callError} />}
      <ContentBlock>
        <label htmlFor="participated-select">{t('Status')}</label>
        <Radio.Group
          id="participated-select"
          name="participated"
          className={styles.offset}
          defaultValue={null}
          options={participatedOptions}
          onChange={val => {
            setParticipation(val)
            val === false && setIsDidNotParticipateModalOpen(true)
          }}
          value={participated}
          disabled={pickerDisabled}
        />
      </ContentBlock>
      <Modal
        id="did-not-participate-modal"
        title={t('Did not participate')}
        isOpen={isDidNotParticipateModalOpen}
      >
        <Modal.Body>
          <ContentBlock>
            <label htmlFor="non-participation-reason-select">
              {t('Reason')}
            </label>
            <Radio.Group
              id="non-participation-reason-select"
              name="non-participation-reason"
              className={styles.offset}
              defaultValue={null}
              options={nonParticipationOptions}
              onChange={setNonParticipationReason}
              value={nonParticipationReason}
              disabled={pickerDisabled}
            />
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary
              onClick={() => {
                setParticipation('')
                setNonParticipationReason(null)
                setIsDidNotParticipateModalOpen(false)
              }}
            >
              {t('Cancel')}
            </Button.Secondary>
            <Button
              onClick={() => {
                setIsDidNotParticipateModalOpen(false)
                if (isInvalidNumberSelected) {
                  setIsInvalidNumberModalOpen(true)
                }
              }}
            >
              {t('Select reason')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      <Modal
        id="invalid-number-modal"
        title={t('Which numbers are wrong')}
        isOpen={isInvalidNumberModalOpen}
      >
        <Modal.Body>
          <ContentBlock>
            <label>{t('Phone numbers')}</label>
            {phoneNumbers.map((contact, i) => (
              <Checkbox
                key={contact.id}
                id={`choice-${contact.content}-${i}`}
                name={`choice-${contact.content}-${i}`}
                label={
                  <span className={styles['phone-label']}>
                    <Font.Copy Element="span">
                      <FormattedData value={contact.content} type="phone" />
                    </Font.Copy>
                    <Font.Copy Element="span" variant="hint">
                      ({t(PHONE_TYPES[contact.contact_type])})
                    </Font.Copy>
                  </span>
                }
                checked={invalidNumberIdsSet.has(contact.id)}
                value={contact}
                onChange={val => {
                  let numbers
                  if (!val) {
                    numbers = invalidNumbers.filter(
                      invalidContact => invalidContact.id !== contact.id
                    )
                  } else {
                    numbers = [...invalidNumbers, { ...contact, invalid: true }]
                  }
                  receiveInvalidNumbers(numbers)
                }}
              />
            ))}
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary
              onClick={() => {
                setIsInvalidNumberModalOpen(false)
                setIsDidNotParticipateModalOpen(true)
                clearInvalidNumbers()
              }}
            >
              {t('Back to reasons')}
            </Button.Secondary>
            <Button
              onClick={() => {
                setIsInvalidNumberModalOpen(false)
              }}
            >
              {t('continue')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      <FieldBlock>
        <TextField
          label={t('Notes (optional)')}
          value={notes}
          onChange={setNotes}
        />
      </FieldBlock>
      {showSubmitButton && (
        <ButtonBlock>
          <Button.Accent disabled={!canSubmit} onClick={submit}>
            {t('Submit')}
          </Button.Accent>
        </ButtonBlock>
      )}
      {(!canSubmit || updatePersonLoading) && (
        <Font.Copy className="margin--right margin--left">
          {t('Enter a response before submitting')}
        </Font.Copy>
      )}
      {showSubmitResponse && (
        <p className="pb-session__sidebar__submitted">
          <span className="pb-session__sidebar__submitted-text">
            {t('Submitted')}
          </span>
          <Icon.Check alt="" />
        </p>
      )}
    </form>
  )
}

export default SessionResponses
