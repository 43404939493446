/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react'
import {
  Font,
  FieldBlock,
  Button,
  Checkbox,
  Radio,
  SectionLabel,
  Section,
  ContentBlock,
  TextField,
  TextBlock,
  SelectField,
  DateField,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './ShiftDataEntry.module.scss'
import AddressLookup from './AddressLookup'
import { isRegistrationFormValid } from './utils'

const getRegistrationTypeOptions = t => [
  { value: 'new_registration', label: t('New Applicant') },
  {
    value: 'other_change',
    label: t('Change of Address, Name, or Other Information'),
  },
  {
    value: 'replacement_card',
    label: t('Request for a Replacement Card'),
  },
]

const getCheckboxOptions = t => [
  { value: true, label: t('Yes') },
  { value: false, label: t('No') },
  { value: null, label: t('Empty') },
]

const RegistrationForm = ({
  setField,
  getField,
  formData,
  fieldErrors,
  setFieldError,
  submitAttempted,
}) => {
  const { t } = useTranslation()
  const fieldSetter = field => value => {
    setField(value, field)
    setFieldError(field, null)
  }
  const registrationTypeOptions = getRegistrationTypeOptions(t)
  const checkboxOptions = getCheckboxOptions(t)

  useEffect(() => {
    isRegistrationFormValid(formData)
      ? setFieldError('meta.registration', null)
      : setFieldError('meta.registration', 'needs registration data')
  }, [formData])

  if (!getField('meta.hasRegistrationForm')) {
    return (
      <ContentBlock>
        <RegistrationFormHeader getField={getField} setField={setField} />
      </ContentBlock>
    )
  }
  return (
    <Section>
      <ContentBlock>
        <RegistrationFormHeader getField={getField} setField={setField} />
        <hr className={styles.hr} />
        {fieldErrors['meta.registration'] && submitAttempted && (
          <Font.Copy variant="hint" className={styles['error-text']}>
            {t('This section cannot be empty')}
          </Font.Copy>
        )}
      </ContentBlock>
      <FieldBlock className={styles['field-block']}>
        <TextField
          type="text"
          name="last-name"
          id="last-name"
          label={t('Last name')}
          value={getField('last_name')}
          onChange={fieldSetter('last_name')}
        />
        <TextField
          type="text"
          name="first-name"
          id="first-name"
          label={t('First name')}
          value={getField('first_name')}
          onChange={fieldSetter('first_name')}
        />
        <TextField
          type="text"
          name="middle-name"
          id="middle-name"
          label={t('Middle name')}
          value={getField('middle_name')}
          onChange={fieldSetter('middle_name')}
        />
      </FieldBlock>
      <ContentBlock>
        <div>
          <Font.Copy Element="label" variant="secondary">
            {t('Reason for registration')}
          </Font.Copy>
          <Radio.Group
            options={registrationTypeOptions}
            onChange={fieldSetter('reason_for_registration')}
            value={getField('reason_for_registration')}
          />
        </div>
        <br />
        <Button
          onClick={() => fieldSetter('reason_for_registration')(undefined)}
        >
          Clear
        </Button>
      </ContentBlock>
      <ContentBlock>
        <div>
          <Font.Copy Element="label" variant="secondary">
            {t('US Citizen')}
          </Font.Copy>
          <Radio.Group
            options={checkboxOptions}
            onChange={fieldSetter('us_citizen')}
            value={getField('us_citizen')}
          />
        </div>
      </ContentBlock>
      <ContentBlock>
        <div>
          <Font.Copy Element="label" variant="secondary">
            {t('Voting Age')}
          </Font.Copy>
          <Radio.Group
            options={checkboxOptions}
            onChange={fieldSetter('voting_age')}
            value={getField('voting_age')}
          />
        </div>
      </ContentBlock>
      <FieldBlock variant="large">
        <Checkbox
          label={t('Interested in being an election worker')}
          onChange={fieldSetter('election_worker_interest')}
          checked={getField('election_worker_interest')}
        />
      </FieldBlock>
      <TextBlock>
        <Font.Copy variant="secondary">{t('Residential address')}</Font.Copy>
      </TextBlock>
      <AddressLookup
        prefix="voting_address"
        setField={fieldSetter}
        getField={getField}
        hasCounty
        fieldErrors={fieldErrors}
        showPresenceCheckbox
      />
      <FieldBlock>
        <div>
          <Font.Copy Element="label" variant="secondary">
            {t('Mailing Address')}
          </Font.Copy>
          <Checkbox
            label={t('Same as residential address')}
            onChange={fieldSetter(
              'meta.registration.mailingAddressSameAsResidential'
            )}
            checked={getField(
              'meta.registration.mailingAddressSameAsResidential'
            )}
          />
        </div>
      </FieldBlock>

      {getField('meta.registration.mailingAddressSameAsResidential') ? null : (
        <AddressLookup
          prefix="mailing_address"
          setField={fieldSetter}
          getField={getField}
        />
      )}
      <FieldBlock className={styles['field-block']}>
        <SelectField
          id="gender"
          label={t('Gender')}
          options={['Male', 'Female'].map(value => ({
            value,
            label: t(value),
          }))}
          value={getField('gender')}
          onSelect={fieldSetter('gender')}
        />
      </FieldBlock>
      <ContentBlock>
        <div>
          <Checkbox
            label={t("Has driver's license")}
            onChange={fieldSetter('has_drivers_license')}
            checked={getField('has_drivers_license')}
          />
          <Checkbox
            label={t('Has social security number')}
            onChange={fieldSetter('has_ssn')}
            checked={getField('has_ssn')}
          />
          <Checkbox
            label={t('Has phone number')}
            onChange={fieldSetter('has_phone_number')}
            checked={getField('has_phone_number')}
          />
          <Checkbox
            label={t('Has date of birth')}
            onChange={fieldSetter('has_date_of_birth')}
            checked={getField('has_date_of_birth')}
          />
          <Checkbox
            label={t(
              "Has not been issued a Texas driver's license #, Personal Identification Number, or SSN"
            )}
            onChange={fieldSetter('has_no_id')}
            checked={getField('has_no_id')}
          />
        </div>
      </ContentBlock>
      <FieldBlock className={styles['field-block']}>
        <Checkbox
          label={t('Has applicant signature')}
          onChange={fieldSetter('has_applicant_signature')}
          checked={getField('has_applicant_signature')}
        />
        <DateField
          label={t('Registration Date')}
          onChange={fieldSetter('registration_date')}
          value={getField('registration_date')}
        />
      </FieldBlock>
      <FieldBlock>
        <Checkbox
          label={t('Signed as agent')}
          onChange={fieldSetter('signed_as_agent')}
          checked={getField('signed_as_agent')}
        />
      </FieldBlock>
      <FieldBlock className={styles['field-block']}>
        <Checkbox
          label={t('Has a canvasser (VDR) signature')}
          onChange={fieldSetter('has_vdr_signature')}
          checked={getField('has_vdr_signature')}
        />
        <DateField
          label={t('Signature Date')}
          onChange={fieldSetter('signature_date')}
          value={getField('signature_date')}
        />
      </FieldBlock>
      <FieldBlock>
        <Checkbox
          label={t('Has VDR number')}
          onChange={fieldSetter('has_vdr_number')}
          checked={getField('has_vdr_number')}
        />
      </FieldBlock>
    </Section>
  )
}

const RegistrationFormHeader = ({ getField, setField }) => {
  const { t } = useTranslation()
  return (
    <div>
      <SectionLabel>{t('Registration form data')}</SectionLabel>
      <Checkbox
        label={t('I have the associated registration card')}
        checked={getField('meta.hasRegistrationForm')}
        onChange={() => {
          setField(
            !getField('meta.hasRegistrationForm'),
            'meta.hasRegistrationForm'
          )
        }}
      />
    </div>
  )
}

export default RegistrationForm
