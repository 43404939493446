import { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReactRouter } from 'hooks'
import {
  View,
  ViewContainer,
  HeaderBlock,
  CardError,
  TurfSelectField,
} from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  FieldBlock,
  Sheet,
  Section,
  SelectField,
  DateField,
  Grid,
  ProgressBar,
  NumberField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { postGoal } from 'requests/fieldManagement'
import { formatDateStringAsISOString } from 'utils/formatting'
import { useCurrent, useTurfs } from 'contexts/index'
import GoalSummaryContextProvider, {
  GoalSummaryContext,
} from '../GoalSummaryContext/GoalSummaryContext'
import OtherGoalsListContextProvider, {
  OtherGoalsListContext,
} from '../OtherGoalsList/OtherGoalsListContext'
import OtherGoalsList from '../OtherGoalsList/OtherGoalsList'
import GoalSummary from '../GoalSummary/GoalSummary'
import GoalBreakdown from '../GoalBreakdown/GoalBreakdown'
import HandleDeltaModal from '../HandleDeltaModal/HandleDeltaModal'
import generateDisabledGoalDates from '../generateDisabledGoalDates'

const GoalNew = () => {
  const { t } = useTranslation()
  const { history, match } = useReactRouter()
  const { goalTagOptions: tagOptions } = useCurrent()
  const { preparedTurfs: turfs } = useTurfs()
  const {
    totalStartDate,
    setTotalStartDate,
    totalEndDate,
    setTotalEndDate,
    totalTarget,
    setTotalTarget,
    targets,
    weeklySum,
    isSplit,
  } = useContext(GoalSummaryContext)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [maxAvailableDate, setMaxAvailableDate] = useState()
  const [disabledDates, setDisabledDates] = useState([])
  const [selectedTurf, setSelectedTurf] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const [handleDeltaModalIsVisible, setHandleDeltaModalIsVisible] =
    useState(false)
  const { otherGoals } = useContext(OtherGoalsListContext)

  useEffect(() => {
    const [incomingMaxAvailableDate, incomingDisabledDates] =
      generateDisabledGoalDates({
        otherGoals,
        totalStartDate,
      })
    setMaxAvailableDate(incomingMaxAvailableDate)
    setDisabledDates(incomingDisabledDates)
  }, [otherGoals, totalStartDate])

  useEffect(() => {
    const turfId = match.params?.turfId || undefined
    setSelectedTurf(turfId && +turfId)
  }, [])

  useEffect(() => {
    const goalOverlap = otherGoals.reduce((overlap, goal) => {
      if (!overlap) {
        return !(
          moment(totalStartDate).isAfter(goal.end_date) ||
          moment(totalEndDate).isBefore(goal.start_date)
        )
      }
      return false
    }, false)
    if (goalOverlap) {
      setTotalStartDate(undefined)
      setTotalEndDate(undefined)
    }
  }, [otherGoals])

  const postGoalCallbacks = {
    onSuccess: () => {
      history.push('/field-management/goals')
    },
    onError: () => {
      setErrorMessage('An error occurred while trying to create your goal')
      setIsLoading(false)
    },
  }

  const { makeRequest: makePostGoalReq } = useRequest(
    reqBody => postGoal(reqBody),
    postGoalCallbacks
  )

  const formatTargetsForReq = target => ({
    start_date: target.startDate,
    end_date: target.endDate,
    forms_to_collect: target.registrationsToCollect,
  })

  const handleCreateGoal = () => {
    setIsLoading(true)
    const newTargets = isSplit
      ? Object.values(targets).map(formatTargetsForReq)
      : [
          {
            start_date: totalStartDate,
            end_date: totalEndDate,
            forms_to_collect: totalTarget,
          },
        ]

    const goalReqObject = {
      start_date: totalStartDate,
      end_date: totalEndDate,
      labels: selectedTag ? [selectedTag] : undefined,
      turf_id: selectedTurf,
      targets: newTargets,
    }

    makePostGoalReq(goalReqObject)
  }

  const checkIfCanSave = () => {
    setErrorMessage(null)

    if (+weeklySum === +totalTarget || !isSplit) {
      handleCreateGoal()
    } else {
      setHandleDeltaModalIsVisible(true)
    }
  }

  const isValid =
    selectedTurf && totalStartDate && totalEndDate && totalTarget && selectedTag

  return (
    <View>
      <ViewContainer>
        <Sheet>
          <HeaderBlock title={t('New goal')}>
            <ButtonBlock justify="right">
              <Link to="/field-management/goals">
                <Button.Secondary aria-label={t('Exit')}>
                  <Icon.Times />
                </Button.Secondary>
              </Link>
            </ButtonBlock>
          </HeaderBlock>
          <ProgressBar show={isLoading} />
          <CardError hide={!errorMessage} message={errorMessage} />
          <Grid>
            <Section>
              <FieldBlock>
                <TurfSelectField
                  id="turf_id"
                  label={t('Turf')}
                  turfs={turfs.filter(
                    turf => turf.voter_registration_enabled && !turf.archived
                  )}
                  value={selectedTurf}
                  onSelect={setSelectedTurf}
                  required
                />
              </FieldBlock>
              <FieldBlock variant="medium">
                <DateField
                  label={t('Goal date range')}
                  type="range"
                  startDate={formatDateStringAsISOString(totalStartDate)}
                  endDate={formatDateStringAsISOString(totalEndDate)}
                  onChange={(start, end) => {
                    setTotalStartDate(start)
                    setTotalEndDate(end)
                  }}
                  disabled={!selectedTurf}
                  max={maxAvailableDate}
                  disabledDates={disabledDates}
                  required
                />
              </FieldBlock>
              <FieldBlock>
                <NumberField
                  label={t('Registrations to collect')}
                  value={totalTarget}
                  onChange={val => {
                    setTotalTarget(val)
                  }}
                  required
                />
              </FieldBlock>
              <FieldBlock>
                <SelectField
                  label={t('Tag')}
                  value={selectedTag}
                  onSelect={val => {
                    setSelectedTag(val)
                  }}
                  options={tagOptions}
                  required
                />
              </FieldBlock>
            </Section>
            <OtherGoalsList turfId={selectedTurf} />
          </Grid>
          <GoalSummary />
          <GoalBreakdown />
        </Sheet>
      </ViewContainer>
      <ButtonBlock>
        <Button.Accent onClick={checkIfCanSave} disabled={!isValid}>
          Save
        </Button.Accent>
        <Link to="/field-management/goals">
          <Button.Secondary>Cancel</Button.Secondary>
        </Link>
      </ButtonBlock>
      <HandleDeltaModal
        isVisible={handleDeltaModalIsVisible}
        setIsVisible={setHandleDeltaModalIsVisible}
        handleCreateGoal={handleCreateGoal}
      />
    </View>
  )
}

export default () => (
  <GoalSummaryContextProvider>
    <OtherGoalsListContextProvider>
      <GoalNew />
    </OtherGoalsListContextProvider>
  </GoalSummaryContextProvider>
)
