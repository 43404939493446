import { useRequest } from 'hooks/useRequest'
import { fetchShift } from 'requests/shifts'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import DataEntry from './CustomDataEntry/DataEntry'
import TexasDataEntry from './TexasDataEntry/TexasDataEntry'

export const ShiftDataEntry = () => {
  const [usesTexasDataEntry, setUsesTexasDataEntry] = useState()
  const [usesLegacyEntry, setUsesLegacyEntry] = useState()
  const { shiftId } = useParams()
  const { makeRequest } = useRequest(fetchShift, {
    onSuccess: ({ shift }) => {
      setUsesTexasDataEntry(
        shift?.turf?.voter_registration_config?.uses_texas_data_entry
      )
      setUsesLegacyEntry(
        shift?.turf?.voter_registration_config?.uses_legacy_entry
      )
    },
  })
  useEffect(() => {
    makeRequest(shiftId, {
      fields: [{ turf: ['voter_registration_config'] }],
    })
  }, [])

  if (usesTexasDataEntry) {
    return <TexasDataEntry />
  }
  if (!usesLegacyEntry) {
    return <DataEntry />
  }
  return null
}
