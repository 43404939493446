import { useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { ContextMenu } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { useScroll, useContextMenu } from 'contexts'
import { moveDocument } from 'requests/documents'
import { useRequest } from 'hooks/useRequest'
import { useContext } from 'react'
import { useModalTrigger } from '../ModalProvider/ModalProvider'
import { useFolderClipboard } from '../ClipboardProvider/ClipboardProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import { INTERACTABLE_TYPES, MODAL_TYPES } from '../constants'
import { isItemDocument, isItemFolder } from '../utils'
import styles from './FolderBreadcrumb.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const cx = classNames.bind(styles)

const FolderBreadcrumb = ({ folder }) => {
  const { t } = useTranslation()
  const { canModify } = usePermissions()
  const { endScroll } = useScroll()

  const { setDocuments, moveFolder } = useContext(DocumentContext)

  const { makeRequest: moveDocumentRequest } = useRequest(
    folderProps => moveDocument(folderProps),
    {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    }
  )

  const [{ isOver, isDescendant }, dropRef] = useDrop({
    accept: [
      INTERACTABLE_TYPES.SIDEBAR_FOLDER,
      INTERACTABLE_TYPES.PANEL_FOLDER,
      INTERACTABLE_TYPES.PANEL_DOCUMENT,
    ],
    drop: (item, monitor) => {
      if (monitor.didDrop()) return
      endScroll()

      if (isItemDocument(item)) {
        moveDocumentRequest({
          documentId: item.id,
          folderId: folder.id,
        })
      }

      if (isItemFolder(item)) {
        moveFolder(item.id, folder.id)
      }
    },
    collect: monitor => {
      const item = monitor.getItem()
      const isOver = monitor.isOver()
      if (!item) {
        return {
          isOver,
        }
      }

      if (isItemDocument(item)) {
        return {
          isOver,
        }
      }

      return {
        isOver,
        isDescendant: item.lft <= folder.lft && item.rgt >= folder.rgt,
      }
    },
    canDrop: item => {
      if (!canModify) return false
      if (isItemDocument(item)) return true

      return !(item.lft <= folder.lft && item.rgt >= folder.rgt)
    },
  })

  const { showMenu, isMenuActive } = useContextMenu({
    id: [INTERACTABLE_TYPES.PANEL_BREADCRUMB, folder.id],
  })

  const openModal = useModalTrigger()

  const { canPaste, pasteItem } = useFolderClipboard({ folder })

  const isRoot = !folder.parent_id

  return (
    <>
      <div ref={dropRef} className={styles.breadcrumb}>
        <Link
          to={`/share/documents/${folder.id}`}
          className={cx('link', {
            'link--root': isRoot,
            'link--descendant': isDescendant,
            'link--active': (isOver && !isDescendant) || isMenuActive,
          })}
          onContextMenu={showMenu}
        >
          {folder.name}
        </Link>
      </div>
      <ContextMenu id={[INTERACTABLE_TYPES.PANEL_BREADCRUMB, folder.id]}>
        <ContextMenu.Item
          label={t('Paste')}
          icon={<Icon.Paste alt="" />}
          onClick={pasteItem}
          isDisabled={!canPaste || !canModify}
        />
        <ContextMenu.Divider />
        <ContextMenu.Item
          label={t('Rename')}
          icon={<Icon.Pen alt="" />}
          isDisabled={!canModify}
          onClick={() => {
            openModal(MODAL_TYPES.RENAME_FOLDER, {
              folder,
            })
          }}
        />
      </ContextMenu>
    </>
  )
}

export default FolderBreadcrumb
