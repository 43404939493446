import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getSession } from 'requests/phoneBankSessions'
import { LoadBar, CardError, HeaderBlock } from 'components'
import { TextBlock } from '@politechdev/blocks-design-system'
import CallSessionProvider, {
  useCallSessionContext,
} from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { useCurrent } from 'contexts/index'
import { useReactRouter } from 'hooks/router'
import { useRequest } from 'hooks/useRequest'
import ActiveMainPanel from './ActiveMainPanel'
import CallPanel from './callPanel/CallPanel'
import NoVoipPanel from './callPanel/NoVoipPanel'
import ParticipantProfile from './NaiveParticipantProfile'
import SessionResponses from './SessionResponses'
import SessionSidebar from '../SessionSidebar'

const SessionActive = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const { phoneBankId, id, mode } = match.params
  const {
    tenantOptions: { voip_enabled: hasVoip },
  } = useCurrent()
  const {
    phoneBankComplete,
    currentSession: {
      phone_bank: { name: phoneBankName },
    },
    setCurrentSession,
    updateRadioResponse,
    updateCheckboxResponse,
    updateOpenEnded,
    clearCall,
    fetchNextCall,
  } = useCallSessionContext()

  const showCallPanel = mode !== 'external' && hasVoip

  const {
    makeRequest: makeCallRequest,
    isLoading: callLoading,
    hasErrors: callHasError,
    isRequestComplete: isCallRequestComplete,
  } = useRequest(fetchNextCall)

  const {
    makeRequest: makeSessionRequest,
    isLoading: sessionLoading,
    hasErrors: sessionHasError,
    isRequestComplete: isSessionRequestComplete,
  } = useRequest(getSession, {
    onSuccess: ({ 'phone_banking/session': session }) => {
      setCurrentSession(session)
      makeCallRequest(phoneBankId, mode, hasVoip)
    },
  })

  const { makeRequest: makeResponseRequest } = useRequest(
    (questionId, response, type) => {
      switch (type) {
        case 'single_choice':
          updateRadioResponse(questionId, response)
          break
        case 'multiple_choice':
          updateCheckboxResponse(questionId, response)
          break
        default:
          updateOpenEnded(questionId, response)
          break
      }
    }
  )

  const isLoading = callLoading || sessionLoading
  const hasError = callHasError || sessionHasError

  useEffect(() => {
    makeSessionRequest(phoneBankId, id)
    return () => {
      clearCall()
    }
  }, [])

  if (!isSessionRequestComplete || !isCallRequestComplete) {
    return <LoadBar show />
  }

  if (phoneBankComplete) {
    return (
      <TextBlock>
        <h2>{t('Phone bank closed')}</h2>
        <p>
          {t(
            'All potential voters have been contacted, so the phone bank has ended.'
          )}
        </p>
        <p>
          {t(
            'Thank you for participating in this phone bank, your time and effort is greatly appreciated.'
          )}
        </p>
      </TextBlock>
    )
  }

  if (hasError) {
    return (
      <CardError
        hide={false}
        message={t(
          'An error occurred fetching a number to call. Please refresh your browser and try again'
        )}
        hideSupportText
      />
    )
  }

  return (
    <div className="pb-session">
      <HeaderBlock title={phoneBankName} />
      <LoadBar show={isLoading} />
      <div className="pb-session__container">
        <SessionSidebar>
          {showCallPanel ? (
            <CallPanel fetchNextCall={makeCallRequest} />
          ) : (
            <NoVoipPanel fetchNextCall={makeCallRequest} />
          )}
          <ParticipantProfile />
          <SessionResponses />
        </SessionSidebar>
        <ActiveMainPanel
          hasVoip={hasVoip}
          handleResponseChange={makeResponseRequest}
        />
      </div>
    </div>
  )
}

export default props => (
  <CallSessionProvider.Standard>
    <SessionActive {...props} />
  </CallSessionProvider.Standard>
)
