import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { init as fullstoryInit } from '@fullstory/browser'
import blocks from 'store/blocks'
import { AuthProvider, useAuth } from 'contexts'
import { loadPolyfills } from 'utils/polyfills'
import { NetworkToast } from 'components'
import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'
import { ErrorBoundary } from './ErrorBoundary'
import Loader from './Loader'
import './assets/styles/app.scss'

window.React = React

fullstoryInit({ orgId: 'o-1VT21E-na1' })

void loadPolyfills()

const Application = () => {
  const { isLoggedIn, locked } = useAuth() as {
    isLoggedIn: boolean
    locked: boolean
  }

  return isLoggedIn && !locked ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

ReactDOM.render(
  <ErrorBoundary>
    <NetworkToast />
    <BrowserRouter>
      <Provider store={blocks}>
        <Loader>
          <AuthProvider>
            <Application />
          </AuthProvider>
        </Loader>
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById('root')
)
