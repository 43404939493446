import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import {
  destroyOrganization,
  fetchOrganizations,
  organizationFields,
} from 'requests/organizations'
import { useState } from 'react'
import { formatErrorMessage } from 'utils/formatting'
import tableConfig from './tableConfig'

const OrganizationTable = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const { renderTable, refreshPage } = useAutoTable()
  const { doesCurrentUserHavePermission } = useCurrent()

  const [organizations, setOrganizations] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const canModifyOrganization = doesCurrentUserHavePermission({
    resource: 'organization',
    ability: 'modify',
  })

  const canDeleteOrganization = doesCurrentUserHavePermission({
    resource: 'organization',
    ability: 'remove',
  })

  const {
    makeRequest: deleteRequest,
    isLoading,
    errors,
  } = useRequest(destroyOrganization, {
    onSuccess: refreshPage,
  })

  const errorMsg = formatErrorMessage(errors)

  const handleEdit = rowData => {
    history.push(`${match.path}/${rowData.id}/edit`)
  }

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const { makeRequest: fetchAction } = useRequest(
    params =>
      fetchOrganizations({
        current_page: params.page,
        per: params.pageSize,
        sort_attr: params.sortColumn || 'name',
        sort_dir: params.sortOrder || 'asc',
        filters: {
          rules: params.filterRules,
          conjunction: params.filterConjunction,
        },
        indexed: true,
        fields: organizationFields,
      }),
    {
      onSuccess: ({ organizations, meta }) => {
        setOrganizations(organizations)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyOrganization),
        createRowAction(t('Delete'), handleDelete, canDeleteOrganization),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: organizations,
    loading: isLoading,
    error: errorMsg,
    totalRecords,
    columns,
    fetchAction,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
    defaultFilters={tableConfig.defaultFilters}
  >
    <OrganizationTable />
  </AutoTableProvider>
)
