import { UserSelectField, SectionContainer } from 'components'
import {
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { putPerson } from 'requests/people'
import { useRequest } from 'hooks/useRequest'
import { formatDisplayName } from 'utils/formatting'
import styles from './PersonActivismOrganizer.module.scss'

const personParams = {
  fields: [{ user: ['name', 'email', 'id'] }],
}

const PersonActivismOrganizer = ({ person }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [organizer, setOrganizer] = useState()
  const { t } = useTranslation()

  const { makeRequest: updatePersonReq } = useRequest(putPerson, {
    onSuccess: ({ person }) => {
      setOrganizer(person.user)
      setIsEditing(false)
    },
  })

  useEffect(() => {
    setOrganizer(person.user)
  }, [person])

  const updateOrganizer = ({ id }) =>
    updatePersonReq(person.id, { assigned_to_user_id: id }, personParams)

  return (
    <SectionContainer>
      <ButtonBlock>
        <Font.Label>{t('Organizer')}</Font.Label>
      </ButtonBlock>
      {isEditing ? (
        <FieldBlock>
          <UserSelectField
            id="organizers"
            label={t('Organizer')}
            onSelect={updateOrganizer}
            user={organizer}
          />
        </FieldBlock>
      ) : (
        <TextBlock className={styles['editable-value']}>
          <div>
            {organizer ? formatDisplayName(organizer) : t('No organizer')}
          </div>
          {!isEditing && (
            <Button.Secondary
              data-testid="organizer-edit-button"
              aria-label={t('Change')}
              onClick={() => setIsEditing(true)}
            >
              <Icon.Pencil />
            </Button.Secondary>
          )}
        </TextBlock>
      )}
    </SectionContainer>
  )
}

export default PersonActivismOrganizer
