import { useEffect, useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  SectionContainer,
  Section,
  DetailItem,
  SectionLabel,
  CardError,
  PersonSelectField,
} from 'components'
import {
  ButtonBlock,
  Button,
  Icon,
  FieldBlock,
  SelectField,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import {
  deleteRelationship,
  postRelationship,
  putRelationship,
} from 'requests/people'
import { useCurrent } from 'contexts/index'

const PersonRelationships = ({ person, refreshPerson }) => {
  const { relationshipTypeOptions } = useCurrent()

  const [isEditing, toggleIsEditing] = useReducer(
    isEditing => !isEditing,
    false
  )
  const [relationships, setRelationships] = useState([])
  const [newRelationships, setNewRelationships] = useState([])
  const [addRelationshipsError, setAddRelationshipsError] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const personRelationships = [
      ...person.primary_relationships,
      ...person.secondary_relationships,
    ]

    setRelationships(personRelationships)
  }, [person])

  const {
    makeRequest: updateRelationshipReq,
    errorMsg: updateRelationshipError,
  } = useRequest(
    (personId, relationshipId, relationship) => {
      setAddRelationshipsError(false)
      return putRelationship(relationshipId, {
        ...relationship,
        first_person_id: personId,
      })
    },
    {
      onSuccess: refreshPerson,
    }
  )

  const {
    makeRequest: deleteRelationshipReq,
    errorMsg: deleteRelationshipError,
  } = useRequest(
    relationshipId => {
      setAddRelationshipsError(false)
      return deleteRelationship(relationshipId)
    },
    {
      onSuccess: refreshPerson,
    }
  )

  const isError =
    addRelationshipsError || updateRelationshipError || deleteRelationshipError

  const cancel = () => {
    toggleIsEditing()
    setNewRelationships([])
    setAddRelationshipsError(false)
  }

  const createRelationships = async () => {
    setAddRelationshipsError(false)

    const validRelationships = newRelationships.filter(
      ({ type, second_person_id }) =>
        type &&
        second_person_id &&
        !relationships.some(
          existingRelationship =>
            existingRelationship.type === type &&
            existingRelationship.second_person.id === second_person_id
        )
    )
    const promises = validRelationships.map(async relationship =>
      postRelationship({ ...relationship, first_person_id: person.id })
    )

    await Promise.allSettled(promises)
    setNewRelationships([])
    toggleIsEditing()
    refreshPerson()
  }

  const addNewRelationshipField = () => {
    setNewRelationships([
      ...newRelationships,
      { type: null, second_person_id: null },
    ])
  }

  if (isEditing) {
    if (!newRelationships.length && !relationships.length) {
      addNewRelationshipField()
    }

    return (
      <SectionContainer>
        <ButtonBlock>
          <SectionLabel primary>{t('Relationships')}</SectionLabel>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={cancel}>{t('Cancel')}</Button.Secondary>
            <Button onClick={createRelationships}>{t('Save')}</Button>
          </ButtonBlock>
        </ButtonBlock>
        <CardError
          hide={!isError}
          message={t(
            'An internal error occurred while trying to update your relationships'
          )}
        />
        {relationships.map(relationship => (
          <FieldBlock key={relationship.id}>
            <SelectField
              id="relationship"
              value={relationship.type}
              options={relationshipTypeOptions}
              onSelect={type => {
                if (
                  !relationships.some(
                    r =>
                      r.second_person.id === relationship.second_person_id &&
                      r.type === type
                  )
                ) {
                  updateRelationshipReq(person.id, relationship.id, {
                    type,
                  })
                }
              }}
            />
            {person.id === relationship.second_person.id ? (
              <Link to={`/organize/people/${relationship.first_person.id}`}>
                {relationship.first_person.name}
              </Link>
            ) : (
              <Link to={`/organize/people/${relationship.second_person.id}`}>
                {relationship.second_person.name}
              </Link>
            )}
            <Button.Secondary
              onClick={() => {
                deleteRelationshipReq(relationship.id)
              }}
            >
              <Icon.Times />
            </Button.Secondary>
          </FieldBlock>
        ))}
        {newRelationships.map((relationship, index) => (
          <FieldBlock key={relationship.id}>
            <div data-testid="new-relationship-picker">
              <SelectField
                id="relationship-type"
                data-testid="relationship-type-picker"
                label={t('Relationship type')}
                options={relationshipTypeOptions}
                value={newRelationships[index].type || ''}
                onSelect={value => {
                  setNewRelationships(
                    newRelationships.map((relationship, i) =>
                      i === index
                        ? { ...relationship, type: value }
                        : relationship
                    )
                  )
                }}
              />
            </div>
            <PersonSelectField
              id="searchPeople"
              label={t('Name')}
              person={
                newRelationships[index].second_person_id
                  ? {
                      id: newRelationships[index].second_person_id,
                      name: newRelationships[index].second_person_name,
                    }
                  : null
              }
              onSelect={value =>
                setNewRelationships(
                  newRelationships.map((relationship, i) =>
                    i === index
                      ? {
                          ...relationship,
                          second_person_id: value.id,
                          second_person_name: value.name,
                        }
                      : relationship
                  )
                )
              }
            />
            <Button.Secondary
              onClick={() =>
                setNewRelationships(prevRelationships =>
                  prevRelationships.filter((_, i) => i !== index)
                )
              }
            >
              <Icon.Times />
            </Button.Secondary>
          </FieldBlock>
        ))}
        <ButtonBlock>
          <Button.Secondary
            aria-label={t('Add')}
            onClick={addNewRelationshipField}
          >
            <Icon.Plus />
          </Button.Secondary>
        </ButtonBlock>
      </SectionContainer>
    )
  }

  return (
    <SectionContainer>
      <ButtonBlock>
        <SectionLabel primary>{t('Relationships')}</SectionLabel>
        <Button.Secondary
          aria-label={t('Edit relationships')}
          data-testid="edit-person-relationships"
          onClick={toggleIsEditing}
        >
          <Icon.Pencil />
        </Button.Secondary>
      </ButtonBlock>
      <Section
        empty={!relationships.length}
        emptyMessage={t('No relationships set')}
      >
        {relationships.map(relationship => (
          <DetailItem label={relationship.type} key={relationship.id}>
            {person.id === relationship.second_person.id ? (
              <Link to={`/organize/people/${relationship.first_person.id}`}>
                {relationship.first_person.name}
              </Link>
            ) : (
              <Link to={`/organize/people/${relationship.second_person.id}`}>
                {relationship.second_person.name}
              </Link>
            )}
          </DetailItem>
        ))}
      </Section>
    </SectionContainer>
  )
}

export default PersonRelationships
