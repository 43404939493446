import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useRoutePathParams } from 'hooks/router'
import { useHistory } from 'react-router'
import {
  Section,
  ContentBlock,
  ButtonBlock,
  Button,
  Font,
  Icon,
  FieldBlock,
  TextField,
  ProgressBar,
  Checkbox,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { CardError, Paginator } from 'components/index'
import useLocalForm from 'hooks/useLocalForm'
import {
  formatDisplayName,
  formatName,
  getGeneralError,
} from 'utils/formatting'
import { useRequest } from 'hooks/useRequest'
import useEvent from 'hooks/useEvent'
import { fetchPetitionSignatures, putPetitionSignature } from 'requests/shifts'
import { fetchVoterMatch } from 'requests/ostraka'
import { useCurrent } from 'contexts/index'
import styles from './PetitionPacketSingle.module.scss'
import {
  APPROVED_RESPONSE,
  serializeFields,
  signatureReqParams,
  FORM_STATE,
  dupePinSignatureReqParams,
  potentialDupeSignatureReqParams,
} from './constants'
import { formatSignatureForVoterMatch, matchConfig } from './utils'
import DuplicatePinModal from './DuplicatePinModal'
import PotentialDuplicateModal from './PotentialDuplicateModal'

const cx = classNames.bind(styles)
const serializeFormData = (formData, fields) => {
  const serialized = {}
  fields.forEach(field => {
    serialized[field] = formData[field]
  })
  return serialized
}

const FormTextField = ({
  disabled,
  label,
  attribute,
  formProps,
  regex,
  errorMessage = null,
}) => (
  <TextField
    label={label}
    onChange={value => formProps.setField(value, attribute)}
    value={formProps.getField(attribute)}
    error={!!formProps.fieldErrors[attribute]}
    errorMessage={errorMessage ?? formProps.fieldErrors[attribute]}
    disabled={disabled}
    regex={regex}
  />
)

const PinSection = ({
  isPinned,
  id,
  duplicateSignatureOstrakaIdIsLoading,
  setField,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles['signature-block__pin-section-block']}>
      {t(isPinned ? 'Pinned match' : 'Partial match')}{' '}
      {duplicateSignatureOstrakaIdIsLoading ? (
        <Font.Copy variant="hint">{t('Checking for duplicates...')}</Font.Copy>
      ) : (
        <Button
          onClick={() => {
            if (isPinned) {
              setField(null, 'ostraka_external_id')
              return
            }
            setField(id, 'ostraka_external_id')
          }}
        >
          <Icon.Thumbtack />
          {t(isPinned ? 'Unpin match' : 'Pin match')}
        </Button>
      )}
    </div>
  )
}

const SignatureForm = ({
  isEditable,
  signatures,
  visualReviewResponses,
  handleSignatureChange,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [{ signatureId, id: shiftId, shiftType }] = useRoutePathParams()
  const {
    currentUser: { id: currentUserId },
  } = useCurrent()

  const [duplicatePinMatches, setDuplicatePinMatches] = useState([])
  const [duplicatePinModalIsOpen, setDuplicatePinModalOpen] = useState(false)
  const [potentialDuplicates, setPotentialDuplicates] = useState([])
  const [potentialDuplicateModalIsOpen, setPotentialDuplicateModalOpen] =
    useState(false)
  const approveResponse = visualReviewResponses.find(
    visualResponse =>
      visualResponse.response === APPROVED_RESPONSE &&
      visualResponse.reviewable_type === 'petition_signature'
  )
  const issueResponses = visualReviewResponses.filter(
    visualResponse =>
      visualResponse.response !== APPROVED_RESPONSE &&
      visualResponse.reviewable_type === 'petition_signature'
  )

  const formProps = useLocalForm()
  const { setFormData, formData, setFieldError, setField } = formProps

  const {
    makeRequest: updateSignatureOstrakaIdRequest,
    isLoading: signatureOstrakaIdIsLoading,
    errors: signatureOstrakaIdErrors,
  } = useRequest(putPetitionSignature)

  const {
    makeRequest: updateSignatureRequest,
    isLoading: signatureIsLoading,
    errors: signatureErrors,
  } = useRequest(putPetitionSignature, {
    onSuccess: ({ petition_signatures }) => {
      handleSignatureChange(petition_signatures)
    },
    onError: errors =>
      Object.entries(errors).forEach(([field, error]) =>
        setFieldError(field, error)
      ),
  })

  const {
    makeRequest: fetchDuplicateSignatureOstrakaIdRequest,
    isLoading: duplicateSignatureOstrakaIdIsLoading,
    errors: duplicateSignatureOstrakaIdErrors,
  } = useRequest(fetchPetitionSignatures, {
    onSuccess: ({ petition_signatures }) => {
      if (petition_signatures.length === 0) {
        updateSignatureOstrakaIdRequest(
          signatureId,
          { ostraka_external_id: formData.ostraka_external_id },
          signatureReqParams
        )
      } else {
        setField(null, 'ostraka_external_id')
        setDuplicatePinMatches(petition_signatures)
        setDuplicatePinModalOpen(true)
      }
    },
  })

  useEffect(() => {
    if (formData.ostraka_external_id) {
      fetchDuplicateSignatureOstrakaIdRequest(
        dupePinSignatureReqParams(formData.ostraka_external_id)
      )
    } else {
      updateSignatureOstrakaIdRequest(
        signatureId,
        { ostraka_external_id: null },
        signatureReqParams
      )
    }
  }, [formData.ostraka_external_id])

  const handleClose = () => {
    history.push(`/collect/${shiftType}/shifts/${shiftId}/packet`)
  }

  const handleSubmitApprove = useEvent(async () => {
    const signatureAttributes = serializeFormData(formData, serializeFields)
    signatureAttributes.visual_reviews = [
      { visual_review_response_id: approveResponse.id },
    ]
    signatureAttributes.reviewer_id = currentUserId
    await updateSignatureRequest(
      signatureId,
      signatureAttributes,
      signatureReqParams
    )
    handleClose()
  })

  const {
    makeRequest: fetchPotentialDuplicateSignaturesRequest,
    isLoading: potentialDuplicateSignaturesAreLoading,
    errors: potentialDuplicateSignatureErrors,
  } = useRequest(fetchPetitionSignatures, {
    onSuccess: ({ petition_signatures }) => {
      if (petition_signatures.length === 0) {
        handleSubmitApprove()
      } else {
        setPotentialDuplicates(petition_signatures)
        setPotentialDuplicateModalOpen(true)
      }
    },
  })

  const isLoading =
    signatureIsLoading ||
    signatureOstrakaIdIsLoading ||
    potentialDuplicateSignaturesAreLoading
  const errors = {
    ...signatureErrors,
    ...signatureOstrakaIdErrors,
    ...duplicateSignatureOstrakaIdErrors,
    ...potentialDuplicateSignatureErrors,
  }

  const [formState, setFormState] = useState(FORM_STATE.READ_ONLY)
  const [areIssuesOpen, setAreIssuesOpen] = useState(false)
  const [matchPage, setMatchPage] = useState(0)

  const voterMatch = useRequest(fetchVoterMatch, {
    onSuccess: ({ data }) => {
      setField(data?.votersMatchConfidence || [], 'voterMatch')
      setMatchPage(0)
    },
  })

  const handleMatchPaginate = change => () => {
    setMatchPage(current => {
      const totalPages = formData.voterMatch?.length || 0
      return (current + change + totalPages) % totalPages
    })
  }

  const handleCheckboxChange = useEvent((id, value) => {
    let issues = formData.issues || []
    issues = value ? [...issues, id] : issues.filter(issue => issue !== id)
    setField(issues, 'issues')
  })

  const getIsCheckboxChecked = response => {
    const issues = formData.issues || []
    return issues.includes(response.id)
  }

  const handleSubmitIssues = useEvent(async () => {
    const signatureAttributes = serializeFormData(formData, serializeFields)
    signatureAttributes.visual_reviews = formData.issues
      .filter(id => id !== approveResponse.id)
      .map(id => ({
        visual_review_response_id: id,
      }))
    signatureAttributes.reviewer_id = currentUserId
    await updateSignatureRequest(
      signatureId,
      signatureAttributes,
      signatureReqParams
    )
    handleClose()
  })

  const handleSave = useEvent(() => {
    const signatureAttributes = serializeFormData(formData, serializeFields)
    updateSignatureRequest(signatureId, signatureAttributes, signatureReqParams)
  })

  useEffect(() => {
    if (signatures) {
      const signature = signatures.find(({ id }) => +signatureId === id)
      setFormData(
        {
          ...signature,
          issues: signature.visual_review_responses.map(({ id }) => id),
        } || {}
      )

      const hasBeenReviewed = !!signature.visual_review_responses?.length
      const isApproved =
        hasBeenReviewed &&
        signature.visual_review_responses.some(
          visualResponse => visualResponse.response === APPROVED_RESPONSE
        )
      setAreIssuesOpen(hasBeenReviewed && !isApproved)

      if (!isEditable) {
        setFormState(FORM_STATE.READ_ONLY)
      } else if (hasBeenReviewed) {
        setFormState(FORM_STATE.VIEW_REVIEW)
      } else {
        setFormState(FORM_STATE.REVIEW)
      }

      const voterMatchParams = formatSignatureForVoterMatch(signature)
      voterMatch.makeRequest(voterMatchParams)
    }
  }, [signatureId, signatures])

  const errorMessage = getGeneralError(errors)

  const hasBeenReviewed = !!formData.visual_review_responses?.length
  const isApproved =
    hasBeenReviewed &&
    formData.visual_review_responses.some(
      visualResponse => visualResponse.response === APPROVED_RESPONSE
    )

  const BadgeIcon = isApproved ? Icon.Check : Icon.Xmark

  const buildVoterMatch = ([, fields]) => (
    <div>
      {fields.map(({ signatureKey, matchKey, label }) => {
        const currentMatch = formData.voterMatch?.[matchPage]
        if (!currentMatch || !formData) return null

        const signature =
          typeof signatureKey === 'function'
            ? signatureKey(formData)
            : formData[signatureKey]
        const voterMatch = currentMatch[matchKey]
        const isSame = signature?.toLowerCase() === voterMatch?.toLowerCase()
        const MatchIcon = isSame ? Icon.Check : Icon.Xmark
        return (
          <div className={cx('voter-match__field')}>
            <MatchIcon
              className={cx('voter-match__icon', {
                'voter-match__icon--match': isSame,
              })}
            />
            <TextBlock>
              <Font.Copy variant="hint">{t(label)}</Font.Copy>
              <Font.Copy>{voterMatch}</Font.Copy>
            </TextBlock>
          </div>
        )
      })}
    </div>
  )

  return (
    <Section label={t('Signatures')}>
      <ContentBlock>
        <div className={cx('signature-block__header')}>
          <ContentBlock>
            <Font.Copy>{t('Signature #{{ line_number }}', formData)}</Font.Copy>
            <Font.Copy variant="reading-block">{formData.county}</Font.Copy>
          </ContentBlock>
          <ContentBlock>
            <Font.Copy>
              {formatName([
                formData.first_name,
                formData.middle_name,
                formData.last_name,
              ])}
            </Font.Copy>
          </ContentBlock>
          <ContentBlock>
            {!!formData.reviewer && (
              <>
                <Font.Copy>{t('Reviewed by')}</Font.Copy>
                <Font.Copy variant="reading-block">
                  {formatDisplayName(formData.reviewer)}
                </Font.Copy>
              </>
            )}
          </ContentBlock>
          <ButtonBlock justify="right">
            {hasBeenReviewed ? (
              <div
                className={cx('review-badge', {
                  'review-badge--approved': isApproved,
                  'review-badge--rejected': !isApproved,
                })}
              >
                <Font.Action>
                  {isApproved ? t('Approved') : t('Rejected')}
                </Font.Action>
                <BadgeIcon className={cx('review-badge__icon')} />
              </div>
            ) : null}

            <Button.Secondary onClick={handleClose}>
              <Icon.Times aria-label={t('close')} />
            </Button.Secondary>
          </ButtonBlock>
        </div>
        <div className={cx('signature-block__container')}>
          <ProgressBar show={isLoading} />
          <CardError hide={!errorMessage} message={errorMessage} />
          <div
            className={cx('loading-overlay', {
              'loading-overlay--visible': isLoading,
            })}
          />
          <div className={cx('signature-block__content')}>
            <form>
              <FieldBlock>
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  label="First name"
                  attribute="first_name"
                />
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="middle_name"
                  label="Middle name"
                />
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="last_name"
                  label="Last name"
                />
              </FieldBlock>
              <FieldBlock>
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="address_one"
                  label="Address line 1"
                />
              </FieldBlock>
              <FieldBlock>
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="address_two"
                  label="Address Line 2"
                />
              </FieldBlock>
              <FieldBlock>
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="city"
                  label="City"
                />
              </FieldBlock>
              <FieldBlock>
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="state"
                  label="State"
                />
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="zipcode"
                  label="Zip"
                  regex="(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)"
                  errorMessage={t('Invalid zip code')}
                />
              </FieldBlock>
              <FieldBlock>
                <FormTextField
                  disabled={formState !== FORM_STATE.REVIEW}
                  formProps={formProps}
                  attribute="county"
                  label="County"
                />
              </FieldBlock>
            </form>
            {formState !== FORM_STATE.READ_ONLY && (
              <div className={cx('voter-match__container')}>
                <div className={cx('voter-match__header')}>
                  <TextBlock>
                    <Font.Label>{t('Potential matches')}</Font.Label>
                  </TextBlock>
                  <Paginator
                    onNext={handleMatchPaginate(1)}
                    onPrevious={handleMatchPaginate(-1)}
                    currentPage={matchPage + 1}
                    totalPages={formData.voterMatch?.length}
                  />
                </div>
                <ContentBlock>
                  {formData.voterMatch?.length ? (
                    <>
                      <div>
                        <PinSection
                          isPinned={
                            formData.voterMatch[matchPage].externalId ===
                            formData.ostraka_external_id
                          }
                          id={formData.voterMatch[matchPage].externalId}
                          duplicateSignatureOstrakaIdIsLoading={
                            duplicateSignatureOstrakaIdIsLoading
                          }
                          setField={setField}
                        />
                      </div>
                      {Object.entries(matchConfig).map(buildVoterMatch)}
                    </>
                  ) : (
                    <Font.Copy>{t('No matches found')}</Font.Copy>
                  )}
                </ContentBlock>
              </div>
            )}
          </div>
          {areIssuesOpen ? (
            <div className={cx('review__container')}>
              <Section label={t('Issues found')}>
                <ContentBlock>
                  {issueResponses.map(visualResponse => (
                    <Checkbox
                      name={visualResponse.response}
                      label={visualResponse.description}
                      onChange={isChecked =>
                        handleCheckboxChange(visualResponse.id, isChecked)
                      }
                      checked={getIsCheckboxChecked(visualResponse)}
                      disabled={formState !== FORM_STATE.REVIEW}
                    />
                  ))}
                </ContentBlock>
              </Section>
            </div>
          ) : null}
          {formState === FORM_STATE.VIEW_REVIEW ? (
            <ButtonBlock>
              <Button.Secondary
                onClick={() => {
                  setFormState(FORM_STATE.REVIEW)
                  setAreIssuesOpen(false)
                }}
              >
                {t('Redo review')}
              </Button.Secondary>
            </ButtonBlock>
          ) : null}
          {formState === FORM_STATE.REVIEW && areIssuesOpen ? (
            <ButtonBlock>
              <Button.Accent onClick={handleSubmitIssues}>
                {t('Complete review')}
              </Button.Accent>
              <Button.Secondary onClick={() => setAreIssuesOpen(false)}>
                {t('Cancel')}
              </Button.Secondary>
            </ButtonBlock>
          ) : null}
          {formState === FORM_STATE.REVIEW && !areIssuesOpen ? (
            <ButtonBlock>
              <Button.Accent
                disabled={isLoading}
                onClick={() =>
                  fetchPotentialDuplicateSignaturesRequest(
                    potentialDupeSignatureReqParams(
                      formData,
                      approveResponse,
                      signatureId
                    )
                  )
                }
              >
                <Icon.Check />
                <span>{t('Approve')}</span>
              </Button.Accent>
              <Button.Danger
                disabled={isLoading}
                onClick={() => setAreIssuesOpen(true)}
              >
                <Icon.Times />
                <span>{t('Raise issue')}</span>
              </Button.Danger>
              <Button.Secondary disabled={isLoading} onClick={handleSave}>
                {t('Save without approving')}
              </Button.Secondary>
            </ButtonBlock>
          ) : null}
        </div>
      </ContentBlock>
      <DuplicatePinModal
        isOpen={duplicatePinModalIsOpen}
        setIsOpen={setDuplicatePinModalOpen}
        issueResponses={issueResponses}
        handleCheckboxChange={handleCheckboxChange}
        getIsCheckboxChecked={getIsCheckboxChecked}
        handleSubmitIssues={handleSubmitIssues}
        duplicatePinMatches={duplicatePinMatches}
      />
      <PotentialDuplicateModal
        isOpen={potentialDuplicateModalIsOpen}
        setIsOpen={setPotentialDuplicateModalOpen}
        issueResponses={issueResponses}
        handleCheckboxChange={handleCheckboxChange}
        getIsCheckboxChecked={getIsCheckboxChecked}
        handleSubmitIssues={handleSubmitIssues}
        approve={handleSubmitApprove}
        potentialDuplicates={potentialDuplicates}
      />
    </Section>
  )
}

export default SignatureForm
