import { useState, useEffect } from 'react'
import {
  Checkbox,
  Icon,
  TextBlock,
  Font,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import styles from './IssueOption.module.scss'

const IssueOption = ({ preparedOption, isChecked, toggleCheckbox }) => {
  const { t } = useTranslation()
  const [childrenVisible, setChildrenVisible] = useState(false)
  const { children, subcategories } = preparedOption

  const isChildChecked = children.some(child => isChecked(child.id))
  const isSubcategoryChildChecked = subcategories.some(subcategory =>
    subcategory.children.some(child => isChecked(child.id))
  )

  useEffect(() => {
    if (isChildChecked || isSubcategoryChildChecked) {
      setChildrenVisible(true)
    }
  }, [isChildChecked, isSubcategoryChildChecked])

  const toggleChildren = () => {
    setChildrenVisible(!childrenVisible)
  }

  return (
    <div className={styles.item}>
      <button
        type="button"
        className={styles.category__drawer}
        onClick={toggleChildren}
      >
        <Font.Copy variant="highlight">{t(preparedOption.label)}</Font.Copy>
        <div className={styles.icon}>
          {childrenVisible ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
        </div>
      </button>
      {childrenVisible && (
        <div className={styles.children}>
          {subcategories.map(subcategory => (
            <div className={styles.subcategory}>
              <TextBlock>
                <Font.Action>{t(subcategory.label)}</Font.Action>
              </TextBlock>
              {subcategory.children.map(child => (
                <Checkbox
                  className={styles.checkbox}
                  checked={isChecked(child.id)}
                  onChange={() => toggleCheckbox(child.id)}
                  id={`${child.id}-id`}
                  name={child.id}
                  label={t(child.description)}
                  value={child.response}
                  key={`${child.id}-issue`}
                />
              ))}
            </div>
          ))}
          {children.map(child => (
            <Checkbox
              className={styles.checkbox}
              checked={isChecked(child.id)}
              onChange={() => toggleCheckbox(child.id)}
              id={`${child.id}-id`}
              name={child.id}
              label={t(child.description)}
              value={child.response}
              key={`${child.id}-issue`}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default IssueOption
