import { useContext, useState, useMemo } from 'react'
import {
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  SelectField,
  Modal,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { putPackets } from 'requests/tableQcManager'
import { useRequest } from 'hooks/useRequest'
import { useAutoTable } from 'contexts/index'
import { usePacketAssignment } from 'qualityControl/packetAssignment/PacketAssignmentContext'
import { SummaryPacketsCheckboxGroupContext } from '../../packetSummary/CheckboxContextProvider'

const AssignPacketsToUserModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()

  const [user, setUser] = useState()
  const { refreshPage } = useAutoTable()
  const { getCheckedCheckboxValues, clearCheckboxes } = useContext(
    SummaryPacketsCheckboxGroupContext
  )
  const { scheduledUsers } = usePacketAssignment()
  const userOptions = useMemo(
    () => [
      ...scheduledUsers.map(user => ({ value: user, label: user.name })),
      { value: null, label: t('Unassign') },
    ],
    [scheduledUsers]
  )

  const assignPackets = useRequest(putPackets, {
    onSuccess: () => {
      refreshPage()
      setIsOpen(false)
      setUser(undefined)
    },
  })

  const handleReassign = async () => {
    const packets = getCheckedCheckboxValues()
    const updatedPackets = packets.map(packet => ({
      id: packet.id,
      assignee_id: user && user.id,
    }))
    await assignPackets.makeRequest(updatedPackets)
    clearCheckboxes()
  }

  return (
    <Modal
      id="assign-packets-to-user"
      isOpen={isOpen}
      title={t('Bulk Reassign')}
      showLoading={assignPackets.isLoading}
    >
      <Modal.Body>
        <TextBlock>
          <p>
            {t(
              'All selected packets will be reassigned. This process ignores assignment rules and may result in assigning packets to staff members who do not have access to review these packets. Please check that all selected packets can be reviewed by the staff member you are assigning them to.'
            )}
          </p>
        </TextBlock>
        <FieldBlock>
          <SelectField
            label={t('Assign to')}
            options={userOptions}
            value={user}
            onSelect={setUser}
          />
        </FieldBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary
            onClick={() => {
              setIsOpen(false)
            }}
          >
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={handleReassign}>
            {t('Reassign Packets')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default AssignPacketsToUserModal
