import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isNumber } from 'lodash'
import {
  TextField,
  ProgressBar,
  SectionLabel,
  SelectField,
  DateField,
  Button,
  ButtonBlock,
  FieldBlock,
  TextBlock,
} from '@politechdev/blocks-design-system'
import {
  CardError,
  CanvasserSelectField,
  LocationSelectField,
  TurfSelectField,
} from 'components'
import { useForm } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { LOCATION_TYPES } from 'constants/locations'
import { postDelivery, putDelivery } from 'requests/deliveries'
import { DEFAULT_ERROR } from 'constants/appwide'
import { buildForm, buildRequest } from './utils'

const DELIVERY_TYPES = [
  { label: 'In person', value: 'in_person' },
  { label: 'Mail', value: 'mail' },
]

const DeliveryForm = ({ delivery }) => {
  const isEditForm = !!delivery
  const formAction = isEditForm ? putDelivery : postDelivery
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const { formData, setField, setFormData } = useForm()
  const { makeRequest, isLoading, hasErrors } = useRequest(formAction, {
    onSuccess: ({ delivery }) => {
      history.push(`/collect/voter_registration/deliveries/${delivery.id}`)
    },
  })
  const [updatedDeliveryFields, setUpdatedDeliveryFields] = useState({})

  useEffect(() => {
    delivery && setFormData(buildForm(delivery))
  }, [])

  useEffect(() => {
    if (isEditForm) {
      const initialForm = buildRequest(buildForm(delivery))
      const updatedForm = buildRequest(formData)
      const changedFields = Object.keys(updatedForm).reduce((result, key) => {
        if (
          ['canvasser', 'office', 'turn_in_location'].includes(key) ||
          initialForm[key] === updatedForm[key]
        ) {
          return result
        }
        return { ...result, [key]: updatedForm[key] }
      }, {})
      setUpdatedDeliveryFields(changedFields)
    }
  }, [isEditForm, delivery, formData])

  const isFormValid = () =>
    isNumber(formData.office_id) &&
    formData.canvasser &&
    formData.turn_in_location &&
    formData.turn_in_date &&
    formData.delivery_method

  const submitForm = e => {
    e.preventDefault()
    if (isEditForm) {
      Object.keys(updatedDeliveryFields).length
        ? makeRequest(delivery.id, updatedDeliveryFields)
        : history.push(`/collect/voter_registration/deliveries/${delivery.id}`)
    } else {
      makeRequest(buildRequest(formData))
    }
  }

  const selectedCanvasser = useMemo(
    () => formData.canvasser,
    [JSON.stringify(formData.canvasser)]
  )

  return (
    <>
      <ProgressBar show={isLoading} />
      <CardError hide={!hasErrors} message={t(DEFAULT_ERROR)} />
      <form onSubmit={submitForm}>
        <TextBlock>
          <SectionLabel>{t('Origin')}</SectionLabel>
        </TextBlock>
        <FieldBlock>
          <TurfSelectField
            required
            label={t('Turf')}
            value={formData.office_id}
            onSelect={val => setField(val, 'office_id')}
          />
          <CanvasserSelectField
            label={t('Runner')}
            onSelect={val => setField(val, 'canvasser')}
            selectedCanvasser={selectedCanvasser}
            required
          />
        </FieldBlock>
        <FieldBlock>
          {!isEditForm && (
            <SelectField
              label={t('Type')}
              required
              onSelect={val => setField(val, 'delivery_method')}
              options={DELIVERY_TYPES}
              value={formData.delivery_method}
            />
          )}
        </FieldBlock>
        <TextBlock>
          <SectionLabel>{t('Destination')}</SectionLabel>
        </TextBlock>
        <FieldBlock>
          <LocationSelectField
            label={t('Delivery location')}
            onSelect={val => setField(val, 'turn_in_location')}
            location={formData.turn_in_location}
            locationTypes={[LOCATION_TYPES.delivery]}
            required
          />
          <DateField
            label={t('Delivery date')}
            value={formData.turn_in_date}
            onChange={val => setField(val, 'turn_in_date')}
            required
          />
        </FieldBlock>
        <TextBlock>
          <TextField
            id="notes"
            label={t('Notes')}
            value={formData.notes || ''}
            onChange={val => setField(val, 'notes')}
          />
        </TextBlock>
        <ButtonBlock>
          <Button.Accent type="submit" disabled={!isFormValid() || isLoading}>
            {t(isEditForm ? 'Save delivery' : 'Create delivery')}
          </Button.Accent>
        </ButtonBlock>
      </form>
    </>
  )
}

export default DeliveryForm
