import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useAutoTable } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { getGeneralError } from 'utils/formatting'
import { fetchImports } from 'requests/dataSources'
import tableConfig from './tableConfig'

const ImportsTable = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { renderTable } = useAutoTable()

  const getImportsRequest = useRequest(params =>
    fetchImports({
      current_page: params?.page,
      per: params?.pageSize,
      sort_attr: params?.sortColumn,
      sort_dir: params?.sortOrder,
      filters: {
        rules: params?.filterRules,
        conjunction: params?.filterConjunction,
      },
      fields: ['id', 'filename', 'created_at', 'status', 'error_rows_count'],
    })
  )

  const errorMsg = getGeneralError(getImportsRequest.errors)

  const columns = [
    {
      actions: [
        {
          ...createRowAction(t('Manage issues'), rowData => {
            history.push(
              `/data_sources/imports/${rowData.id}/duplicate_prevention`
            )
          }),
          show: rowData => rowData.error_rows_count > 0,
        },
      ],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return renderTable({
    data: getImportsRequest.response?.imports || [],
    loading: getImportsRequest.isLoading,
    error: errorMsg,
    totalRecords: getImportsRequest.response?.meta?.total_count || 0,
    columns,
    fetchAction: getImportsRequest.makeRequest,
  })
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    defaultSortColumn={tableConfig.defaultSortColumn}
    defaultSortOrder={tableConfig.defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
  >
    <ImportsTable />
  </AutoTableProvider>
)
