import { useEffect } from 'react'
import {
  CanvasserSelectField,
  TurfSelectField as TurfResourceSelect,
} from 'components'
import {
  TextField,
  DateField,
  SelectField,
  Checkbox,
  Switch,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import {
  getSortBy,
  getFlagNameFilter,
  getFlagIdFilter,
  getTurfFilter,
  getStartDateFilter,
  getEndDateFilter,
  getTechnicalFlagFilter,
  getCanvasserFlagFilter,
  getFlagTriggersAsOptions,
  getNewCommentsFilter,
  getActiveTab,
  getCanvasserFilter,
} from 'store/qcFlags/reducer'
import {
  setSortBy,
  setFlagNameFilter,
  setFlagIdFilter,
  setTurfFilter,
  setCanvasserFilter,
  setStartDateFilter,
  setEndDateFilter,
  setTechnicalFlagFilter,
  setCanvasserFlagFilter,
  setNewCommentsFilter,
} from 'store/qcFlags/actions'
import moment from 'moment'
import { useTurfs } from 'contexts/index'

const TechnicalFlagSwitch = ({ setTechnicalFlagFilter, technicalFlag }) => {
  const { t } = useTranslation()

  return (
    <Switch
      id="technical-flags"
      name="technical-flags"
      label={t('Technical Flags')}
      onChange={() => {
        setTechnicalFlagFilter(!technicalFlag)
      }}
      value={technicalFlag}
    />
  )
}

const CanvasserFlagSwitch = ({ setCanvasserFlagFilter, canvasserFlag }) => {
  const { t } = useTranslation()

  return (
    <Switch
      id="canvasser-flags"
      name="canvasser-flags"
      label={t('Canvasser flags')}
      onChange={() => {
        setCanvasserFlagFilter(!canvasserFlag)
      }}
      value={canvasserFlag}
    />
  )
}

const TurfSelect = ({ turf, setTurfFilter }) => {
  const { t } = useTranslation()

  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  if (turfRefreshIsLoading) return null

  return (
    <TurfResourceSelect
      label={t('Turf')}
      onSelect={setTurfFilter}
      value={turf}
      isExpanded
    />
  )
}

const BASE_FLAG_OPTION = { key: 'Show all', value: 'all' }

const FlagTypeSelect = ({ flagReason, setFlagReason, flagTriggerOptions }) => {
  const { t } = useTranslation()

  return (
    <SelectField
      id="flag-type-search-select"
      label={t('Flag type')}
      value={flagReason}
      options={[BASE_FLAG_OPTION, ...flagTriggerOptions].map(opt => ({
        label: opt.key,
        value: opt.value,
      }))}
      onSelect={setFlagReason}
    />
  )
}

const CanvasserSelect = ({ canvasserFilter, setCanvasserFilter }) => {
  const turfId = useSelector(getTurfFilter)

  return (
    <CanvasserSelectField
      clearable
      filters={
        turfId
          ? [
              {
                column: 'turf_id',
                operator: 'is',
                param: turfId,
              },
            ]
          : undefined
      }
      selectedCanvasser={canvasserFilter ? { id: canvasserFilter } : undefined}
      onSelect={({ id }) => setCanvasserFilter(id)}
    />
  )
}

const FlagIdInput = ({ id, setFlagIdFilter }) => (
  <TextField
    id="flag-id"
    label="Flag ID"
    value={id}
    onChange={val => setFlagIdFilter(val.replace(/\D/g, ''))}
  />
)

const SORT_BY_OPTIONS = [
  {
    key: 'None',
    value: 'none',
  },
  {
    key: 'Flag ID',
    value: 'id',
  },
  {
    key: 'Date flagged',
    value: 'created_at',
  },
  { key: 'Date commented', value: 'last_comment' },
]

const SortByFlag = ({ sortBy, setSortBy }) => {
  const { t } = useTranslation()

  return (
    <SelectField
      id="sort-by-search-select"
      label={t('Sort by')}
      value={sortBy}
      options={SORT_BY_OPTIONS.map(opt => ({
        label: opt.key,
        value: opt.value,
      }))}
      onSelect={setSortBy}
    />
  )
}

const StartDatePicker = ({ startDate, setStartDateFilter, activeTab }) => {
  const { t } = useTranslation()

  return (
    <DateField
      label={t('Start date')}
      value={
        activeTab === 3
          ? startDate || moment().startOf('week').toISOString()
          : startDate
      }
      onChange={setStartDateFilter}
    />
  )
}

const EndDatePicker = ({ endDate, setEndDateFilter, activeTab }) => {
  const { t } = useTranslation()

  return (
    <DateField
      label={t('End date')}
      value={
        activeTab === 3
          ? endDate || moment().startOf('week').toISOString()
          : endDate
      }
      onChange={setEndDateFilter}
    />
  )
}

export const NewCommentFilter = ({
  isChecked = true,
  setNewCommentsFilter,
}) => {
  const { t } = useTranslation()

  return (
    <Checkbox
      id="new-comment-checkbox"
      name="new-comment-checkbox"
      label={t('Only display flags with new comments')}
      onChange={() => {
        setNewCommentsFilter(!isChecked)
      }}
      checked={isChecked}
    />
  )
}

export const TechnicalFlagSwitchControl = connect(
  state => ({
    technicalFlag: getTechnicalFlagFilter(state),
  }),
  { setTechnicalFlagFilter }
)(TechnicalFlagSwitch)

export const CanvasserFlagSwitchControl = connect(
  state => ({
    canvasserFlag: getCanvasserFlagFilter(state),
  }),
  { setCanvasserFlagFilter }
)(CanvasserFlagSwitch)

export const GroupSelectControl = connect(
  state => ({
    turf: getTurfFilter(state),
  }),
  { setTurfFilter }
)(TurfSelect)

export const FlagTypeSelectControl = connect(
  state => ({
    flagReason: getFlagNameFilter(state),
    flagTriggerOptions: getFlagTriggersAsOptions(state),
  }),
  { setFlagReason: setFlagNameFilter }
)(FlagTypeSelect)

export const CanvasserSelectControl = connect(
  state => ({
    canvasserFilter: getCanvasserFilter(state),
  }),
  { setCanvasserFilter }
)(CanvasserSelect)

export const FlagIdInputControl = connect(
  state => ({
    id: getFlagIdFilter(state),
  }),
  { setFlagIdFilter }
)(FlagIdInput)

export const SortByFlagControl = connect(
  state => ({
    sortBy: getSortBy(state),
  }),
  { setSortBy }
)(SortByFlag)

export const StartDatePickerControl = connect(
  state => ({
    startDate: getStartDateFilter(state),
    activeTab: getActiveTab(state),
  }),
  { setStartDateFilter }
)(StartDatePicker)

export const EndDatePickerControl = connect(
  state => ({
    endDate: getEndDateFilter(state),
    activeTab: getActiveTab(state),
  }),
  { setEndDateFilter }
)(EndDatePicker)

export const NewCommentFilterControl = connect(
  state => ({
    isChecked: getNewCommentsFilter(state),
  }),
  { setNewCommentsFilter }
)(NewCommentFilter)
