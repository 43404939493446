import { TextField, NumberField } from '@politechdev/blocks-design-system'
import humanizeString from 'humanize-string'

const CustomFields = ({ fields, onChange, currentData, disabled }) =>
  fields.map(field =>
    field.type === 'integer' ? (
      <NumberField
        label={field.label || humanizeString(field.name)}
        value={currentData[field.name]}
        onChange={val => onChange(field.name, val)}
        required={field.required}
        disabled={disabled}
      />
    ) : (
      <TextField
        label={field.label || humanizeString(field.name)}
        value={currentData[field.name]}
        onChange={val => onChange(field.name, val)}
        required={field.required}
        disabled={disabled}
      />
    )
  )

export default CustomFields
