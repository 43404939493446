import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  DetailTable,
  FormattedData,
  TextBlock,
  UserSelectField,
} from 'components'
import {
  ButtonBlock,
  Button,
  Font,
  DateField,
  Icon,
  TimePicker,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import { cancelMeeting as cancelMeetingRequest } from 'requests/people'
import { useRequest } from 'hooks/useRequest'
import { formatDisplayName } from 'utils/formatting'
import { includes } from 'lodash'
import moment from 'moment'
import { postMeeting } from 'requests/events'
import { useCurrent } from 'contexts/index'
import styles from './PersonMeetingForm.module.scss'
import {
  addMeetingUtil,
  buildSaveMeetingsRequests,
  cancelMeetingUtil,
  doUnfilledFieldsExist,
  removeMeetingUtil,
  updateMeetingFieldUtil,
} from './utils'

const PersonMeetingForm = ({ existingMeetings, hideForm, personId }) => {
  const { t } = useTranslation()
  const existingMeetingIds = existingMeetings.map(meeting => meeting.id)
  const isExistingMeeting = meeting => includes(existingMeetingIds, meeting.id)

  const {
    currentUser: { time_zone: timezone },
  } = useCurrent()
  const [shownMeetings, setShownMeetings] = useState(existingMeetings)
  const hasUnfilledFields = doUnfilledFieldsExist(
    shownMeetings,
    isExistingMeeting
  )

  const cancelMeeting = indexToUpdate =>
    cancelMeetingUtil(indexToUpdate, shownMeetings, setShownMeetings)

  const { makeRequest: saveMeetingsRequest } = useRequest(
    () =>
      buildSaveMeetingsRequests({
        shownMeetings,
        isExistingMeeting,
        existingMeetings,
        cancelMeetingRequest,
        timezone,
        personId,
        postMeeting,
      }),
    {
      onSuccess: () => hideForm(),
    }
  )

  const addMeeting = () => addMeetingUtil(setShownMeetings, shownMeetings)

  const removeMeeting = index =>
    removeMeetingUtil(setShownMeetings, shownMeetings, index)

  const updateMeetingField = (val, key, index) =>
    updateMeetingFieldUtil({ val, key, index, setShownMeetings, shownMeetings })

  return (
    <div>
      <DetailTable
        columnHeaders={[t('Meeting name'), t('Date'), t('Time'), t('Status')]}
      >
        {shownMeetings.map((meeting, index) => (
          <DetailTable.Row key={meeting.id}>
            <DetailTable.Cell>
              {isExistingMeeting(meeting) ? (
                <Link to={`/build/meetings/${meeting.id}`}>
                  {t('Meeting with')} {formatDisplayName(meeting.user)}
                </Link>
              ) : (
                <UserSelectField
                  label={t('Host')}
                  id={`event-host-${index}`}
                  onSelect={user => updateMeetingField(user, 'user', index)}
                  user={meeting.user}
                />
              )}
            </DetailTable.Cell>
            <DetailTable.Cell>
              {isExistingMeeting(meeting) ? (
                <FormattedData type="date" value={meeting.start_time} />
              ) : (
                <DateField
                  label={t('Meeting day')}
                  id={`event-day-${index}`}
                  value={
                    meeting.eventDay && moment(meeting.eventDay).toISOString()
                  }
                  clearable
                  onClear={() => updateMeetingField(null, 'eventDay', index)}
                  onChange={day => {
                    updateMeetingField(
                      moment(day).format('YYYY-MM-DD'),
                      'eventDay',
                      index
                    )
                  }}
                />
              )}
            </DetailTable.Cell>
            <DetailTable.Cell>
              {isExistingMeeting(meeting) ? (
                <>
                  <FormattedData type="time" value={meeting.start_time} />
                  {' — '}
                  <FormattedData type="time" value={meeting.end_time} />
                </>
              ) : (
                <>
                  <FieldBlock className={styles['time-picker']}>
                    <TimePicker
                      label={t('Start time')}
                      id={`event-start-time-${index}`}
                      onChange={val =>
                        updateMeetingField(
                          moment(val).format('HH:mm:ss'),
                          'start_time',
                          index
                        )
                      }
                      hour={moment(meeting.start_time, 'HH:mm:ss').hour()}
                      minute={moment(meeting.start_time, 'HH:mm:ss').minute()}
                      errors={
                        meeting.start_time >= meeting.end_time &&
                        t('Start time must before end time.')
                      }
                    />
                  </FieldBlock>
                  <FieldBlock className={styles['time-picker']}>
                    <TimePicker
                      label={t('End time')}
                      id={`event-end-time-${index}`}
                      onChange={val =>
                        updateMeetingField(
                          moment(val).format('HH:mm:ss'),
                          'end_time',
                          index
                        )
                      }
                      hour={moment(meeting.end_time, 'HH:mm:ss').hour()}
                      minute={moment(meeting.end_time, 'HH:mm:ss').minute()}
                    />
                  </FieldBlock>
                </>
              )}
            </DetailTable.Cell>
            <DetailTable.Cell>
              {isExistingMeeting(meeting) && meeting.cancelled === true && (
                <TextBlock>
                  <Font.Copy>
                    <p className="uppercase text--warn">Event cancelled</p>
                  </Font.Copy>
                </TextBlock>
              )}
              {isExistingMeeting(meeting) && meeting.cancelled === false && (
                <ButtonBlock>
                  <Button.Danger onClick={() => cancelMeeting(index)}>
                    {t('Cancel meeting')}
                  </Button.Danger>
                </ButtonBlock>
              )}
              {!isExistingMeeting(meeting) && (
                <ButtonBlock justify="center">
                  <Button
                    aria-label="Remove meeting"
                    onClick={() => removeMeeting(index)}
                  >
                    <Icon.Times />
                  </Button>
                </ButtonBlock>
              )}
            </DetailTable.Cell>
          </DetailTable.Row>
        ))}
      </DetailTable>
      <ButtonBlock>
        <Button
          aria-label="Add meeting"
          onClick={addMeeting}
          disabled={hasUnfilledFields}
        >
          <Icon.Plus />
        </Button>
      </ButtonBlock>
      <ButtonBlock>
        <Button.Accent
          aria-label="Save"
          onClick={saveMeetingsRequest}
          disabled={hasUnfilledFields}
        >
          {t('Save')}
        </Button.Accent>
        <Button.Secondary aria-label="Cancel" onClick={hideForm}>
          {t('Cancel')}
        </Button.Secondary>
      </ButtonBlock>
    </div>
  )
}

export default PersonMeetingForm
