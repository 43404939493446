import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useEvent, useReactRouter, useRequest } from 'hooks'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import {
  assignPetitionPacket,
  deleteShift,
  fetchShifts,
  unassignPetitionPacket,
} from 'requests/shifts'
import { deletePacket } from 'requests/scans'
import { IN_QC, READY_FOR_QC } from 'constants/qualityControl'
import { advanceShiftStatus } from 'requests/qualityControl'
import { formatAutoTableParams } from 'utils/formatting'
import { merge } from 'lodash'
import tableConfig from './tableConfig'
import { createActionColumns } from './tableActionConfig'

const ShiftTable = ({ shiftType }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const currentContext = useCurrent()
  const { currentTurfPerformsExternalQC, doesCurrentUserHavePermission } =
    currentContext
  const canDeletePacket = doesCurrentUserHavePermission({
    resource: 'voter_registration_scan_batch',
    ability: 'remove',
  })

  const { renderTable, refreshPage } = useAutoTable()
  const [isPreventDeleteModalOpen, setPreventDeleteModalOpen] = useState(false)
  const [deleteShiftParams, setDeleteShiftParams] = useState(null)
  const [isUnassignModalOpen, setUnassignModalOpen] = useState(null)
  const [shifts, setShifts] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  useEffect(() => {
    refreshPage()
  }, [shiftType])

  const {
    makeRequest: shiftsRequest,
    isLoading,
    errors: errorsFetching,
  } = useRequest(
    params => {
      const formattedParams = formatAutoTableParams(params)

      return fetchShifts(
        merge(formattedParams, {
          filters: {
            rules: currentTurfPerformsExternalQC
              ? [
                  ...params.filters.rules,
                  { column: 'qc_external', operator: 'is', param: true },
                ]
              : params.filters.rules,
          },
          fields: params.fields,
        })
      )
    },
    {
      onSuccess: ({ shifts, meta }) => {
        setShifts(shifts)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const {
    makeRequest: deleteShiftRequest,
    isLoading: isDeleting,
    hasErrors: hasErrorsDeletingShift,
  } = useRequest(deleteShift)

  const {
    makeRequest: deletePacketRequest,
    hasErrors: hasErrorsDeletingPacket,
  } = useRequest(deletePacket)

  const {
    makeRequest: assignPetitionPacketRequest,
    isLoading: assignPetitionPacketIsLoading,
    errors: assignPetitionPacketErrors,
  } = useRequest(assignPetitionPacket)

  const {
    makeRequest: unassignPetitionRequest,
    isLoading: unassignPetitionIsLoading,
    errors: unassignPetitionErrors,
  } = useRequest(unassignPetitionPacket, { onSuccess: refreshPage })

  const completeShift = useRequest(shiftId =>
    advanceShiftStatus(shiftId, IN_QC)
  )
  const startCurrentShiftQC = useEvent(shiftId => {
    const shift = shifts.find(({ id }) => id === shiftId)
    if (shift.status === READY_FOR_QC) {
      completeShift.makeRequest(shiftId)
    }
  })

  const handleUnassign = useEvent(async () => {
    await unassignPetitionRequest(isUnassignModalOpen.id)
    setUnassignModalOpen(null)
  })

  const handleDelete = useEvent(async () => {
    const resp = await deletePacketRequest(deleteShiftParams.packet_id)

    if (!resp.error) {
      await deleteShiftRequest(deleteShiftParams.id)
    }

    setDeleteShiftParams(null)

    refreshPage()
  })

  const startQC = useEvent((shiftId, packetId) =>
    Promise.allSettled([
      assignPetitionPacketRequest(packetId),
      startCurrentShiftQC(shiftId),
    ])
  )

  const fetchAction = useEvent(
    ({ filterRules = [], filterConjunction, ...params } = {}) =>
      shiftsRequest({
        ...params,
        fields: [
          'id',
          'shift_start',
          'status',
          'soft_count_cards_complete_collected',
          'soft_count_cards_incomplete_collected',
          {
            packet: [
              'id',
              'original_filename',
              'created_at',
              'qc_deadline',
              'delivered',
            ],
          },
          { turf: ['name', 'voter_registration_config'] },
          { location: 'name' },
          { canvasser: ['full_name', 'id'] },
          { campaign: 'name' },
          {
            petition_packet: [
              'id',
              'county',
              'created_at',
              'filename',
              { assignee: ['id', 'full_name'] },
              { signatures: ['id'] },
            ],
          },
          {
            visual_review_responses: ['description'],
          },
        ],
        filters: {
          rules: [
            ...(filterRules.length ? filterRules : []),
            ...tableConfig[shiftType].tableFilters,
          ],
          conjunction: filterConjunction,
        },
      })
  )

  const errorMsg =
    Object.values(errorsFetching).join(', ') ||
    ((hasErrorsDeletingShift || hasErrorsDeletingPacket) &&
      'Could not delete shift') ||
    Object.values(assignPetitionPacketErrors).join(', ') ||
    Object.values(unassignPetitionErrors).join(', ')

  const tableDataLoading =
    isLoading ||
    isDeleting ||
    assignPetitionPacketIsLoading ||
    unassignPetitionIsLoading

  const columns = [
    {
      actions: [
        ...createActionColumns({
          shiftType,
          t,
          grabBag: {
            match,
            history,
            setPreventDeleteModalOpen,
            setDeleteShiftParams,
            setUnassignModalOpen,
            deleteShiftRequest,
            startQC,
            refreshPage,
          },
          currentContext,
        }),
      ],
      listInline: true,
    },
    ...tableConfig[shiftType].columns,
  ]

  return (
    <>
      <Modal
        id="unable-to-delete-shift-modal"
        title={t('Unable to delete shift')}
        isOpen={isPreventDeleteModalOpen}
      >
        <Modal.Body>
          <TextBlock>
            {t(
              'This shift cannot be deleted because the packet has unresolved flags. All flags on the packet must be resolved before this shift can be deleted.'
            )}
          </TextBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button onClick={() => setPreventDeleteModalOpen(false)}>
              {t('Exit')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      <Modal
        id="confirm-delete-shift-modal"
        title={
          canDeletePacket
            ? t('Delete packet scans along with shift?')
            : t('Unable to delete shift')
        }
        isOpen={!!deleteShiftParams}
      >
        <Modal.Body>
          <TextBlock>
            {canDeletePacket
              ? t(
                  'A packet has been uploaded to the shift you are about to delete. This action will also delete the shift’s packet and it’s corresponding scans. Are you sure you want to continue?'
                )
              : t(
                  'This action cannot be completed because a packet has been uploaded to the shift you are trying to delete. This action would also delete the shift’s packet and it’s corresponding scans, but you do not have permission to delete packets. Please reach out to the Blocks support team.'
                )}
          </TextBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button onClick={() => setDeleteShiftParams(null)}>
              {canDeletePacket ? t('Cancel') : t('Done')}
            </Button>
            {canDeletePacket && (
              <Button.Accent onClick={handleDelete}>
                {t('Delete shift and packet')}
              </Button.Accent>
            )}
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      <Modal
        id="unassign-modal"
        title={t('Unassign packet?')}
        isOpen={!!isUnassignModalOpen}
      >
        <Modal.Body>
          <TextBlock>
            {t(
              'This will release the packet assigned to {{assignee_full_name}} and allow it to be assigned to someone else. Any progress up to this point will be stored and the next person to start QC on this packet will be able to continue where it was left off. The last person to be assigned to the packet will be considered the reviewer for this packet in reports, but we will keep a record of the last person to modify a response on individual entries.',
              { assignee_full_name: isUnassignModalOpen?.name }
            )}
          </TextBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button onClick={() => setUnassignModalOpen(null)}>
              {t('Cancel')}
            </Button>
            <Button.Accent onClick={handleUnassign}>
              {t('Unassign')}
            </Button.Accent>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
      {renderTable({
        data: shifts,
        loading: tableDataLoading,
        error: errorMsg || '',
        totalRecords,
        columns,
        fetchAction,
      })}
    </>
  )
}

const TypeAwareShiftTable = ({ shiftType }) => {
  const { currentTurfPerformsExternalQC } = useCurrent()

  return (
    <AutoTableProvider
      initialTableId={`${shiftType}-${tableConfig[shiftType].label}`}
      defaultSortColumn={tableConfig[shiftType].defaultSortColumn}
      defaultSortOrder={tableConfig[shiftType].defaultSortOrder}
      enableQueryParams
      enablePagination
      enableFilters
      enableSort
      showDownloadButton
      showFilterEditor
      showQuickSearch
      showTableLink
      defaultFilters={
        currentTurfPerformsExternalQC
          ? []
          : tableConfig[shiftType].defaultFilters
      }
    >
      <ShiftTable shiftType={shiftType} />
    </AutoTableProvider>
  )
}

export default TypeAwareShiftTable
