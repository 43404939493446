import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  TextBlock,
  Modal,
  Font,
  List,
  ListItem,
} from '@politechdev/blocks-design-system'
import { useForm } from 'contexts'
import { useEvent, useRequest } from 'hooks'
import { fetchPeopleDuplicates, postPerson } from 'requests/people'
import { postEventAttendee } from 'requests/events'
import { maskPhone } from 'utils/inputMasks'
import { Link } from 'react-router-dom'
import { buildRequest } from './utils'
import styles from './AddGuestModal.module.scss'
import AddGuestModalSelected from './AddGuestModalSelected'
import AddGuestModalControls from './AddGuestModalControls'
import AddGuestModalForm from './AddGuestModalForm'
import { EventContext } from '../EventContext/EventContext'

const AddGuestModal = ({ onComplete }) => {
  const { t } = useTranslation()

  const { currentEvent, attendees } = useContext(EventContext)

  const [guests, setGuests] = useState([])
  const addGuest = guest => {
    const existing = guests.find(g => g.id === guest.id)
    if (!existing) {
      setGuests([...guests, guest])
    }
  }

  const removeGuest = index => {
    setGuests(guests.filter((guest, i) => i !== index))
  }

  const { formData, setField, setFormData } = useForm()

  const [isWalkIn, setWalkIn] = useState(false)
  const [inviter, setInviter] = useState(null)

  const [isFormVisible, setFormVisible] = useState(false)
  const toggleForm = () => {
    setFormVisible(!isFormVisible)
    setFormData({})
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [duplicateFound, setDuplicateFound] = useState(false)
  const [potentialDuplicates, setPotentialDuplicates] = useState([])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setDuplicateFound(false)
    setModalOpen(false)
    setGuests([])
    setInviter(null)
    setWalkIn(false)
    setFormVisible(false)
    setFormData({})
  }

  const {
    makeRequest: createPersonRequest,
    isLoading: createPersonLoading,
    hasErrors: createPersonError,
  } = useRequest(postPerson, {
    onSuccess: ({ person }) => {
      addGuest(person)
      toggleForm()
    },
  })

  const {
    makeRequest: addAttendeesRequest,
    isLoading: addAttendeesLoading,
    hasErrors: addAttendeesError,
  } = useRequest(
    async () => {
      const requests = guests.map(guest =>
        postEventAttendee(currentEvent.id, {
          person_id: guest.id,
          status: isWalkIn ? 'walk_in' : undefined,
          inviter_id: inviter ? inviter.id : undefined,
        })
      )

      return Promise.all(requests)
    },
    {
      onSuccess: () => {
        onComplete && onComplete()
        closeModal()
      },
    }
  )

  const {
    makeRequest: dupeCheckReq,
    isLoading: dupeCheckIsLoading,
    hasErrors: dupeCheckError,
  } = useRequest(fetchPeopleDuplicates, {
    onSuccess: ({ people }) => {
      const noDupe = people.length === 0
      if (noDupe) {
        return createPersonRequest(buildRequest(formData), {
          fields: [
            'id',
            'name',
            'primary_email_address',
            'primary_phone_number',
          ],
        })
      }

      setPotentialDuplicates(people)
      return setDuplicateFound(true)
    },
  })

  const isFormValid = () => formData.first_name || formData.last_name

  const submitForm = e => {
    e.preventDefault()
    dupeCheckReq({
      fields: ['id', 'name', 'primary_email_address', 'primary_phone_number'],
      attrs: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        primary_phone_number: formData.phone,
        primary_email_address: formData.email,
      },
    })
  }

  const isLoading =
    createPersonLoading || addAttendeesLoading || dupeCheckIsLoading

  const addAttendees = useEvent(addAttendeesRequest)

  const ignoreAndCreate = useEvent(() =>
    createPersonRequest(buildRequest(formData), {
      fields: ['id', 'name', 'primary_email_address', 'primary_phone_number'],
    })
  )

  return (
    <>
      <Button.Accent onClick={openModal}>{t('Add guests')}</Button.Accent>
      <Modal
        id="add-invitees-modal"
        title={t('Add guests')}
        isOpen={modalOpen}
        className={styles.modal}
        showLoading={
          createPersonLoading || addAttendeesLoading || dupeCheckIsLoading
        }
      >
        <Modal.Body>
          {duplicateFound && (
            <>
              <TextBlock>
                <Font.Copy Element="p">
                  {t(
                    'Potential duplicate records were found. Are you sure this person is not already stored in Blocks?'
                  )}
                </Font.Copy>
              </TextBlock>
              <List
                itemData={potentialDuplicates}
                render={person => (
                  <ListItem>
                    <ContentBlock>
                      <Font.Copy>
                        <Link to={`/organize/people/${person.id}`}>
                          {person.name}
                        </Link>
                      </Font.Copy>
                      <Font.Copy variant="hint">
                        {t('Email')}:{' '}
                        {person.primary_email_address || t('None')},{' '}
                        {t('Phone')}:{' '}
                        {person.primary_phone_number
                          ? maskPhone(person.primary_phone_number)
                          : t('None')}
                      </Font.Copy>
                    </ContentBlock>
                    <ContentBlock>
                      {[
                        ...guests.map(({ id }) => id),
                        ...attendees.map(({ person }) => person.id),
                      ].includes(person.id)
                        ? t('Guest added')
                        : null}
                    </ContentBlock>
                  </ListItem>
                )}
              />
            </>
          )}
          {!duplicateFound && (
            <ContentBlock>
              {isFormVisible ? (
                <AddGuestModalForm
                  submitForm={submitForm}
                  formData={formData}
                  setField={setField}
                  toggleForm={toggleForm}
                  modalIsLoading={
                    createPersonLoading ||
                    addAttendeesLoading ||
                    dupeCheckIsLoading
                  }
                  isFormValid={isFormValid}
                  createPersonError={createPersonError || dupeCheckError}
                  setDuplicateFound={setDuplicateFound}
                />
              ) : (
                <>
                  <AddGuestModalControls
                    toggleForm={toggleForm}
                    addGuest={addGuest}
                    inviter={inviter}
                    setInviter={setInviter}
                    alreadySelectedGuests={guests.map(({ id }) => id)}
                    alreadyAttendingGuests={attendees.map(
                      ({ person }) => person.id
                    )}
                  />
                  <AddGuestModalSelected
                    guests={guests}
                    removeGuest={removeGuest}
                    isWalkIn={isWalkIn}
                    setWalkIn={setWalkIn}
                    addAttendeesError={addAttendeesError}
                  />
                </>
              )}
            </ContentBlock>
          )}
        </Modal.Body>
        <Modal.Actions>
          {(!isFormVisible && !duplicateFound && (
            <ButtonBlock justify="right">
              <Button.Secondary onClick={closeModal}>
                {t('Cancel')}
              </Button.Secondary>
              <Button.Accent
                disabled={guests.length === 0 || isLoading}
                onClick={addAttendees}
              >
                {t('Submit')}
              </Button.Accent>
            </ButtonBlock>
          )) ||
            (duplicateFound && (
              <ButtonBlock>
                <Button.Accent
                  onClick={() => {
                    setDuplicateFound(false)
                    toggleForm()
                    setFormData({})
                  }}
                >
                  {t('Search for existing record')}
                </Button.Accent>
                <Button.Secondary
                  onClick={() => {
                    setDuplicateFound(false)
                    ignoreAndCreate()
                  }}
                >
                  {t('Ignore warning and create')}
                </Button.Secondary>
              </ButtonBlock>
            ))}
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AddGuestModal
