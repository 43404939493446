import { useDrop, useDrag } from 'react-dnd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContextMenu } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { useScroll, useContextMenu } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { moveDocument } from 'requests/documents'
import { useContext } from 'react'
import { useModalTrigger } from '../ModalProvider/ModalProvider'
import { useFolderClipboard } from '../ClipboardProvider/ClipboardProvider'
import { useSearch } from '../SearchProvider/SearchProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import ResourceIcon from '../ResourceIcon/ResourceIcon'
import { INTERACTABLE_TYPES, MODAL_TYPES } from '../constants'
import { isItemDocument, isItemFolder } from '../utils'
import DocumentContext from '../DocumentContext/DocumentContext'

const SearchResultFolder = ({ folder }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const { setDocuments, currentFolder, moveFolder } =
    useContext(DocumentContext)

  const { makeRequest: moveDocumentRequest } = useRequest(
    folderProps => moveDocument(folderProps, currentFolder.id),
    {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    }
  )

  const { canModify, canDelete } = usePermissions()
  const { endScroll } = useScroll()
  const { makeSearch } = useSearch()

  const [, dragRef] = useDrag({
    item: {
      id: folder.id,
      lft: folder.lft,
      rgt: folder.rgt,
      parent_id: folder.parent_id,
      type: INTERACTABLE_TYPES.SEARCH_FOLDER,
      onDrop: makeSearch,
    },
    end: endScroll,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => canModify,
  })

  const [{ isOver, isDescendant }, dropRef] = useDrop({
    accept: [
      INTERACTABLE_TYPES.SIDEBAR_FOLDER,
      INTERACTABLE_TYPES.SEARCH_FOLDER,
      INTERACTABLE_TYPES.SEARCH_DOCUMENT,
    ],
    drop: async item => {
      endScroll()

      if (isItemDocument(item)) {
        await moveDocumentRequest({
          documentId: item.id,
          folderId: folder.id,
        })
      }

      if (isItemFolder(item)) {
        await moveFolder(item.id, folder.id)
      }

      makeSearch()
    },
    collect: monitor => {
      const item = monitor.getItem()
      const isOver = monitor.isOver()
      if (!item) {
        return {
          isOver,
        }
      }

      if (isItemDocument(item)) {
        return {
          isOver,
        }
      }

      return {
        isOver,
        isDescendant: item.lft <= folder.lft && item.rgt >= folder.rgt,
      }
    },
    canDrop: item => {
      if (!canModify) return false
      if (isItemDocument(item)) return true

      return !(item.lft <= folder.lft && item.rgt >= folder.rgt)
    },
  })

  const { showMenu, hideMenu, isMenuActive } = useContextMenu({
    id: [INTERACTABLE_TYPES.SEARCH_FOLDER, folder.id],
  })

  const openModal = useModalTrigger()

  const { canPaste, isCutting, pasteItem, cutItem } = useFolderClipboard({
    folder,
    onPaste: makeSearch,
  })

  const handleClick = event => {
    const comboKey =
      event.metaKey || event.altKey || event.ctrlKey || event.shiftKey

    if (!comboKey) {
      event.preventDefault()
    }
  }

  const handleDoubleClick = () => {
    history.push(`/share/documents/${folder.id}`)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      history.push(`/share/documents/${folder.id}`)
    }
  }

  return (
    <div>
      <Link
        to={`/share/documents/${folder.id}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onKeyDown={handleKeyDown}
        onContextMenu={showMenu}
        ref={dropRef}
      >
        <ResourceIcon
          name={folder.name}
          icon={<Icon.Folder alt="" />}
          isDisabled={isDescendant}
          isActive={isOver && !isDescendant}
          isFocused={isMenuActive}
          isClipboard={isCutting}
          ref={dragRef}
        />
      </Link>
      <ContextMenu id={[INTERACTABLE_TYPES.SEARCH_FOLDER, folder.id]}>
        <ContextMenu.Item
          label={t('Paste')}
          icon={<Icon.Paste alt="" />}
          onClick={pasteItem}
          isDisabled={!canPaste || !canModify}
        />
        <ContextMenu.Item
          label={t('Cut')}
          icon={<Icon.Cut alt="" />}
          onClick={cutItem}
          isDisabled={!canModify}
        />
        <ContextMenu.Divider />
        <ContextMenu.Item
          label={t('Show in folder')}
          icon={<Icon.FolderOpen alt="" />}
          onClick={() => {
            hideMenu()
            history.push(`/share/documents/${folder.id}`)
          }}
        />
        <ContextMenu.Item
          label={t('Rename')}
          icon={<Icon.Pen alt="" />}
          isDisabled={!canModify}
          onClick={() => {
            openModal(MODAL_TYPES.RENAME_FOLDER, {
              folder,
              onClose: makeSearch,
            })
          }}
        />
        <ContextMenu.Item
          label={t('Delete')}
          icon={<Icon.TrashAlt alt="" />}
          isDisabled={!canDelete}
          onClick={() => {
            openModal(MODAL_TYPES.DELETE_FOLDER, {
              folder,
              onClose: makeSearch,
            })
          }}
        />
      </ContextMenu>
    </div>
  )
}

export default SearchResultFolder
