class RequestException extends Error {
  constructor(message, response) {
    super(`RequestException: ${message} ${response.status}`)
    this._response = response.clone()
    this.status = this._response.status
    this.statusText = this._response.statusText
    this.name = 'RequestException'
  }

  async readJSON() {
    let json
    let text
    try {
      json = await this._response.clone().json()
    } catch (e) {
      if (e instanceof SyntaxError) {
        text = await this._response.clone().text()
      } else {
        throw e
      }
    } finally {
      this.json = json || null
      this.text = text || null
    }
  }
}

export default RequestException
