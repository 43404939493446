import Appsignal from '@appsignal/javascript'
import { plugin as pathDecorator } from '@appsignal/plugin-path-decorator'

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_TOKEN,
  ignoreErrors: [],
  revision: process.env.VERSION,
})

appsignal.use(pathDecorator())

export { appsignal }
