import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  HeaderBlock,
  Section,
  LoadBar,
  CardError,
  FormattedData,
  Toast,
  View,
  ViewContainer,
  Sheet,
} from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  Modal,
  TextBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import {
  fetchSingleExport,
  deleteScheduledExport,
  postScheduledExportSendEmail,
} from 'requests/exports'
import tableDisplayNames from '../tableNamesConfig'

const ScheduledExportsSingle = () => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  const [exportToastText, setExportToastText] = useState('')
  const [currentExport, setCurrentExport] = useState()
  const {
    makeRequest: exportReq,
    isLoading: isExportReqLoading,
    hasErrors: exportReqError,
  } = useRequest(
    id =>
      fetchSingleExport(id, {
        indexed: true,
        fields: [
          'frequency',
          'table_name',
          'paused',
          'created_at',
          'last_processed_at',
          'columns',
          'recipients',
          'attachment_name',
          { turfs: ['name'] },
        ],
        associations: ['turfs'],
      }),
    {
      onSuccess: ({ scheduled_export }) => {
        setCurrentExport(scheduled_export)
      },
    }
  )
  const {
    makeRequest: deleteReq,
    isLoading: isDeleteReqLoading,
    hasErrors: deleteReqError,
  } = useRequest(deleteScheduledExport, {
    onSuccess: () => {
      history.push('/data_sources/scheduled_exports')
    },
  })
  const {
    makeRequest: sendReq,
    isLoading: isSendReqLoading,
    hasErrors: sendReqError,
  } = useRequest(postScheduledExportSendEmail, {
    onSuccess: () => {
      setExportToastText(t('Export sent'))
      setTimeout(() => {
        exportReq(match.params.id)
        setExportToastText('')
      }, 2000)
    },
  })

  useEffect(() => {
    exportReq(match.params.id)
  }, [])

  return (
    <View>
      <LoadBar
        show={isExportReqLoading || isDeleteReqLoading || isSendReqLoading}
      />
      <CardError
        hide={!exportReqError}
        message={t('There was an error loading your export.')}
      />
      <CardError
        hide={!deleteReqError}
        message={t('There was an error deleting your export.')}
      />
      <CardError
        hide={!sendReqError}
        message={t('There was an error sending your export.')}
      />
      {!!currentExport && (
        <ViewContainer>
          <Sheet>
            <HeaderBlock title={currentExport.attachment_name}>
              <ButtonBlock justify="right">
                <Link to={`${match.url}/edit`}>
                  <Button aria-label={t('Edit')} alt={t('Edit')}>
                    <Icon.Pencil />
                  </Button>
                </Link>
                <Button
                  onClick={() => setShowDeleteWarning(true)}
                  aria-label={t('Delete')}
                  alt={t('Delete')}
                >
                  <Icon.TrashAlt />
                </Button>
              </ButtonBlock>
              <Modal
                id="delete-schedule-warning-modal"
                isOpen={showDeleteWarning}
                onHide={() => {
                  setShowDeleteWarning(false)
                }}
                title={t('Delete Export Schedule?')}
              >
                <Modal.Body>
                  <TextBlock>
                    <p>
                      {t(
                        'Deleting this schedule means that the export files will no longer be sent to the recipients of this export schedule. This does not delete any Blocks data from your database.'
                      )}
                    </p>
                  </TextBlock>
                </Modal.Body>
                <Modal.Actions>
                  <ButtonBlock justify="right">
                    <Button.Secondary
                      onClick={() => {
                        setShowDeleteWarning(false)
                      }}
                    >
                      {t('Cancel')}
                    </Button.Secondary>
                    <Button.Danger onClick={() => deleteReq(match.params.id)}>
                      {t('Delete')}
                    </Button.Danger>
                  </ButtonBlock>
                </Modal.Actions>
              </Modal>
            </HeaderBlock>
            <div className="exports__single">
              <section>
                <Section tight label={t('Schedule')}>
                  <TextBlock>
                    <p>
                      {currentExport.paused
                        ? t('Paused')
                        : `${t('Exports sent')} ${currentExport.frequency}, ${t(
                            'since'
                          )} ${new Date(
                            currentExport.created_at
                          ).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}`}
                    </p>
                  </TextBlock>
                </Section>
                <Section tight label={t('Sent to')}>
                  <TextBlock>
                    <p>{currentExport.recipients.join(', ')}</p>
                  </TextBlock>
                </Section>
                <Section tight label={t('Last sent')}>
                  <TextBlock>
                    <FormattedData
                      type="date"
                      value={currentExport.last_processed_at}
                      fallback={t('Never')}
                    />
                  </TextBlock>
                </Section>
              </section>
              <section className="exports__single__data">
                <Section tight label={t('Table')}>
                  <TextBlock>
                    <p>{tableDisplayNames[currentExport.table_name]}</p>
                  </TextBlock>
                </Section>
                <Section tight label={t('Turf')}>
                  <TextBlock>
                    <p>
                      {currentExport.turfs.length > 1
                        ? t('All turfs')
                        : currentExport.turfs[0] && currentExport.turfs[0].name}
                    </p>
                  </TextBlock>
                </Section>
                <Section
                  label={t('Columns')}
                  className="exports__single__data__columns"
                >
                  <ul>
                    {currentExport.columns.map(column => (
                      <li key={column}>{column}</li>
                    ))}
                  </ul>
                </Section>
              </section>
            </div>
            <ContentBlock>
              <ButtonBlock justify="right">
                <Button.Accent onClick={() => sendReq(match.params.id)}>
                  {t('Export')}
                </Button.Accent>
                <Toast
                  visible={exportToastText.length}
                  text={exportToastText}
                  success
                />
              </ButtonBlock>
            </ContentBlock>
          </Sheet>
        </ViewContainer>
      )}
    </View>
  )
}

export default ScheduledExportsSingle
