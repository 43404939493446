import { useContext } from 'react'
import ReactDOM from 'react-dom'
import { Toast } from 'components'
import { ToastContext } from 'contexts/ToastProvider/ToastProvider'

const AppToast = () => {
  const { visible, text, success, error } = useContext(ToastContext)
  const toastPortal = document.querySelector('#toast-portal')

  if (!toastPortal) return null

  return ReactDOM.createPortal(
    <Toast text={text} visible={visible} success={success} error={error} />,
    toastPortal
  )
}

export default AppToast
