import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Button,
  TextBlock,
  Modal,
  ButtonBlock,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useReactRouter, useRequest } from 'hooks'
import {
  destroyDocument,
  fetchDocumentsThroughFolder,
} from 'requests/documents'
import { useContext } from 'react'
import { useModal } from '../ModalProvider/ModalProvider'
import { MODAL_TYPES } from '../constants'
import styles from './DocumentDeleteModal.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const DocumentDeleteModal = () => {
  const { t } = useTranslation()
  const [{ isOpen, closeModal }, { document, onClose }] = useModal(
    MODAL_TYPES.DELETE_DOCUMENT
  )
  const { setDocuments } = useContext(DocumentContext)
  const { match } = useReactRouter()

  const { makeRequest: documentsRequest, isLoading: documentsLoading } =
    useRequest(fetchDocumentsThroughFolder, {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    })

  const {
    makeRequest: deleteRequest,
    isLoading: deleteLoading,
    hasErrors: deleteHasErrors,
  } = useRequest(destroyDocument, {
    onSuccess: async () => {
      await documentsRequest(match.params.id)
      onClose && onClose()
      closeModal()
    },
  })

  const isLoading = documentsLoading || deleteLoading

  if (!document) return null

  return (
    <Modal
      id={MODAL_TYPES.DELETE_DOCUMENT}
      title={t(`Delete document`)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!deleteHasErrors}
            message={t("We're unable to delete your document")}
            hideSupportLink
          />
          <TextBlock className={styles.text}>
            {t('Are you sure you want to delete')}{' '}
            <strong>{document.name}</strong>
          </TextBlock>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Danger onClick={() => deleteRequest(document.id)}>
            {t('Delete')}
          </Button.Danger>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default DocumentDeleteModal
