import PropTypes from 'prop-types'
import IntercomChatButton from 'components/IntercomChatButton/IntercomChatButton'
import SupportLink from './SupportLink'
import styles from './CardError.module.scss'

const removeEndingPeriod = val => {
  if (val && val.slice(-1) === '.') {
    return val.slice(0, -1)
  }
  return val
}

const CardError = ({
  hide,
  hideSupportLink,
  hideSupportText,
  message,
  className,
}) => {
  const msg = removeEndingPeriod(message)
  if (hide === true) return null
  return (
    <div className={`${styles.card__error} ${className || ''}`}>
      <p className={styles['card__error__text-container']}>
        <span className={styles.card__error__label}>Error:</span> {msg}.{' '}
        <SupportLink hide={hideSupportLink || hideSupportText} />
      </p>
      {!hideSupportLink && (
        <IntercomChatButton className="card__error__button" />
      )}
    </div>
  )
}

CardError.propTypes = {
  hide: PropTypes.bool,
  message: PropTypes.string,
  hideSupportLink: PropTypes.bool,
}

CardError.defaultProps = {
  hide: true,
  message: 'An internal error occurred.',
  hideSupportLink: false,
}

export default CardError
