import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useRequest } from 'hooks/useRequest'
import {
  removeEventShiftVolunteer,
  updateEventShiftVolunteer,
} from 'requests/people'
import { Button, ButtonBlock, Icon } from '@politechdev/blocks-design-system'
import {
  EventSelectField,
  FieldBlock,
  ResponsibilitySelectField,
} from 'components'

const PersonShiftForm = ({ shifts, setFormVisible }) => {
  const { t } = useTranslation()
  const [shownShifts, setShownShifts] = useState(shifts)
  const requestParams = {
    filters: {
      rules: [
        {
          column: 'start_time',
          operator: 'after',
          param: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
    },
  }
  const updateEvent = (updatedEvent, indexToUpdate) => {
    const newShifts = shownShifts.map((shift, index) => {
      const { event } = shift
      if (indexToUpdate !== index) {
        return { ...shift, event }
      }
      return { ...shift, event: updatedEvent }
    })
    setShownShifts(newShifts)
  }
  const updateResponsibility = (updatedResponsibility, indexToUpdate) => {
    const newShifts = shownShifts.map((shift, index) => {
      const { responsibility } = shift
      if (indexToUpdate !== index) {
        return { ...shift, responsibility }
      }
      return { ...shift, responsibility: updatedResponsibility }
    })
    setShownShifts(newShifts)
  }
  const removeShift = id => {
    const newShifts = shownShifts.filter(shift => shift.id !== id)
    setShownShifts(newShifts)
  }
  const { makeRequest: saveShiftsRequest } = useRequest(
    async () => {
      let requests = []
      requests = requests.concat(
        shownShifts
          .filter(shownShift =>
            shifts.find(
              shift =>
                shift.id === shownShift.id &&
                shift.responsibility !== shownShift.responsibility
            )
          )
          .map(shift =>
            updateEventShiftVolunteer(
              shift.event.id,
              shift.id,
              shift.volunteerId,
              shift.responsibility
            )
          )
      )
      requests = requests.concat(
        shifts
          .filter(
            shift =>
              shownShifts.find(shownShift => shift.id === shownShift.id) ===
              undefined
          )
          .map(shift =>
            removeEventShiftVolunteer(
              shift.event.id,
              shift.id,
              shift.volunteerId
            )
          )
      )
      return Promise.all(requests)
    },
    {
      onSuccess: () => setFormVisible(),
    }
  )
  const saveChanges = () => {
    saveShiftsRequest()
  }
  return (
    <div>
      {shownShifts.map(({ event, responsibility, id }, index) => (
        <FieldBlock>
          <EventSelectField
            event={event}
            onSelect={val => updateEvent(val, index)}
            requestParams={requestParams}
            disabled
          />
          <ResponsibilitySelectField
            label={t('Role')}
            responsibility={responsibility}
            onSelect={val => updateResponsibility(val, index)}
          />
          <ButtonBlock>
            <Button.Secondary onClick={() => removeShift(id)}>
              <Icon.Times />
            </Button.Secondary>
          </ButtonBlock>
        </FieldBlock>
      ))}
      <ButtonBlock>
        <Button.Accent aria-label="Save" onClick={() => saveChanges()}>
          {t('Save')}
        </Button.Accent>
        <Button.Secondary aria-label="Cancel" onClick={() => setFormVisible()}>
          {t('Cancel')}
        </Button.Secondary>
      </ButtonBlock>
    </div>
  )
}

export default PersonShiftForm
