import { useTranslation } from 'react-i18next'
import { Section } from 'components'
import {
  Button,
  ButtonBlock,
  TextBlock,
  Font,
  Modal,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'

const ConfirmVDRModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  canvasser,
  county,
}) => {
  const { t } = useTranslation()

  const onApply = () => {
    onConfirm()
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      id="warn-cancel-data-entry"
      title={t('Continue without VDR?')}
      isOpen={isOpen}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {[
                t(`You have selected `),
                <b>{`${county}`}</b>,
                t(
                  ` as the county for residential address, but there is no associated VDR number for canvasser `
                ),
                <b>{`${canvasser?.full_name}`}</b>,
                t(
                  ` assigned to this shift. Please edit the residential address, add the corresponding VDR number to `
                ),
                <Link
                  to={`/collect/voter_registration/canvassers/${canvasser?.id}/edit`}
                  target="_blank"
                >
                  {t('canvasser’s profile')}
                </Link>,
                t(` or continue without a VDR number.`),
              ]}
            </Font.Copy>
          </TextBlock>
        </Section>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Go back')}</Button.Secondary>
          <Button.Accent onClick={onApply}>{t('Continue')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default ConfirmVDRModal
