import { useRequest } from 'hooks'
import { useState } from 'react'
import {
  createPhoneVerificationCall,
  endPhoneVerificationCall,
  updatePhoneVerificationCall,
} from 'requests/phoneVerificationCalls'

export const usePhoneVerificationCall = () => {
  const [currentCallId, setCurrentCallId] = useState(null)
  const [lastActiveCallRecordId, setLastActiveCallRecordId] = useState(null)

  const [contactedStatus, setContactedStatus] = useState('')
  const [verificationResponses, setVerificationResponses] = useState({})

  const [hasEditedChanges, setHasEditedChanges] = useState(false)

  const createCallRecord = useRequest(createPhoneVerificationCall, {
    onSuccess: response => {
      const call = response['quality_control/phone_verification_call']
      setLastActiveCallRecordId(call.id)
      setCurrentCallId(call.id)
    },
  })

  const endCallRecord = useRequest(
    callId => endPhoneVerificationCall(callId || currentCallId),
    {
      onSuccess: () => setCurrentCallId(null),
    }
  )

  const submitPhoneVerification = useRequest(updatePhoneVerificationCall, {
    onSuccess: () => {
      setLastActiveCallRecordId(null)
    },
  })

  return {
    lastActiveCallRecordId,
    createCallRecord,
    endCallRecord,
    submitPhoneVerification,
    contactedStatus,
    setContactedStatus,
    verificationResponses,
    setVerificationResponses,
    hasEditedChanges,
    setHasEditedChanges,
    currentCallId,
  }
}
