import {
  Font,
  Icon,
  TextBlock,
  Tag,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Step,
  LoadBar,
  CardError,
  Section,
  DetailItem,
  SectionLabel,
} from 'components'
import { useHistory } from 'react-router'
import { useForm } from 'contexts'
import { useRequest } from 'hooks'
import { dateFormat } from 'utils/constants'
import { fetchListFolder, postList } from 'requests/lists'
import { useEffect } from 'react'
import { FILTER_TYPES_MAP, FILTER_GROUPS } from '../constants'
import styles from './ListCreateReview.module.scss'
import { getParamValues } from './utils'

const FilterGroupDetails = ({ group }) => {
  const { t } = useTranslation()

  const { formData } = useForm()
  const { chained, ...unchained } = formData[group.id] || {}

  const formatParams = filter => {
    if (filter.id === 'ages') {
      return `${filter.params.min.value} - ${filter.params.max.value}`
    }

    if (filter.id === 'phone_banking_tag') {
      const labels = filter.meta.labels.map(([, label]) => label)

      return (
        <div>
          {labels.map(label => (
            <ContentBlock>
              <Tag>{label}</Tag>
            </ContentBlock>
          ))}
        </div>
      )
    }

    if (filter.id === 'phone_banking_response') {
      const param = filter.params?.rules
        ? filter.params.rules.find(
            rule => rule.column === 'phone_banking_response'
          )?.param
        : null

      if (param) {
        const summaryInfo = [
          param.participated ? 'Participated' : 'Did not participated',
          param.participation_start_date
            ? `${moment(param.participation_start_date).format(
                dateFormat
              )} - ${moment(param.participation_end_date).format(dateFormat)}`
            : 'Any date',
        ]

        return (
          <span>
            <SectionLabel>
              {`Phone Bank ${param.phone_bank_id}` || 'Any phone bank'}
            </SectionLabel>
            {summaryInfo.filter(Boolean).join(' | ')}
            {param.non_participation_reasons?.map(reason => (
              <p>{reason.label}</p>
            ))}
          </span>
        )
      }
      return null
    }

    const matchAll = group.id === 'requiredFilters'

    const paramValues = getParamValues(filter)

    if (!paramValues.length) return null

    return (
      <div className={styles.options}>
        <TextBlock>
          {paramValues
            .map((param, index) => <span key={index}>{param.value}</span>)
            .reduce((prev, curr, index) => [
              prev,
              <em key={`em-${index}`}>{matchAll ? t('and') : t('or')}</em>,
              curr,
            ])}
        </TextBlock>
      </div>
    )
  }

  const chainedFilters = (chained || [])
    .map((chainedGroup, chainedGroupIndex) => {
      const chainedGroupValues = Object.values(chainedGroup)
      if (!chainedGroupValues.length) return null

      const chainedValues = chainedGroupValues
        .map(filter => {
          const filterType = FILTER_TYPES_MAP[filter.id]
          const filterParams = formatParams(filter)

          return (
            <span key={filter.id}>
              <strong>{filterType.name}</strong> {filterParams}
            </span>
          )
        })
        .reduce((prev, curr, index) => [
          prev,
          <em key={index}>{t('and')}</em>,
          curr,
        ])

      return (
        <DetailItem
          className={styles['review-item']}
          key={chainedGroupIndex}
          label={`${t('Chained combo')} #${chainedGroupIndex + 1}`}
        >
          <div className={styles.chain}>{chainedValues}</div>
        </DetailItem>
      )
    })
    .filter(Boolean)

  const unchainedFilters = Object.values(unchained)
    .map(filter => {
      const filterType = FILTER_TYPES_MAP[filter.id]
      const filterParams = formatParams(filter)

      return (
        <DetailItem
          className={styles['review-item']}
          key={filter.id}
          label={t(filterType.name)}
        >
          {filterParams}
        </DetailItem>
      )
    })
    .filter(Boolean)

  if (!chainedFilters.length && !unchainedFilters.length) return null

  return (
    <Section label={t(group.title)}>
      {chainedFilters}
      {unchainedFilters}
    </Section>
  )
}

const ListCreateReview = ({ ...props }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { formData } = useForm()

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(postList, {
    onSuccess: ({ list }) => {
      history.push(`/organize/lists/${list.id}`)
    },
  })

  const {
    makeRequest: getFolder,
    response,
    isRequestComplete,
  } = useRequest(fetchListFolder)

  const submitList = () => {
    makeRequest(
      {
        name: formData.name,
        list_folder_id: formData.folder,
        search_params: {
          requiredFilters: formData.requiredFilters,
          conditionalFilters: formData.conditionalFilters,
          excludedFilters: formData.excludedFilters,
        },
      },
      {
        associations: ['folder'],
        fields: [
          'id',
          'name',
          'created_at',
          'refreshed_at',
          'repopulation_status',
          { folder: ['name'] },
        ],
      }
    )
  }

  useEffect(() => {
    if (formData.folder) {
      getFolder(formData.folder, { fields: ['name'] })
    }
  }, [formData.folder])

  const folder = isRequestComplete ? response.list_folder : null

  return (
    <Step
      label={t('Review list')}
      nextButtonLabel={t('Create')}
      onNext={submitList}
      {...props}
    >
      <LoadBar show={isLoading} />
      <CardError hide={!hasErrors} message={Object.values(errors).join(', ')} />
      <TextBlock>
        <Font.Display>{formData.name}</Font.Display>
      </TextBlock>
      {folder && (
        <Section label={t('Details')}>
          <DetailItem label={t('Folder')}>
            <div className={styles.folder}>
              <Icon.Folder />
              <span>{folder.name}</span>
            </div>
          </DetailItem>
        </Section>
      )}
      <div className={styles.details}>
        {FILTER_GROUPS.map(group => (
          <FilterGroupDetails key={group.id} group={group} />
        ))}
      </div>
    </Step>
  )
}

export default ListCreateReview
