import { useEffect, useState } from 'react'
import {
  TextBlock,
  ButtonBlock,
  Button,
  FieldBlock,
  DateField,
  SelectField,
  Font,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { fetchUsers } from 'requests/users'
import { fetchContactLog, postContactLog } from 'requests/people'
import ActivityLogTable from './ActivityLogTable'
import { ACTIVITY_TYPE_OPTIONS } from './constants'

const getInitialState = () => ({
  readOnly: true,
  contactLog: [],
  newContactLog: {
    contacted_at: new Date(),
  },
})

const ActivityLog = ({ personId }) => {
  const [state, _setState] = useState(getInitialState())
  const setState = newState => {
    if (typeof newState === 'function') {
      _setState(prevState => ({ ...prevState, ...newState(prevState) }))
    } else {
      _setState(prevState => ({ ...prevState, ...newState }))
    }
  }

  const { t } = useTranslation()

  const { makeRequest: contactLogRequest } = useRequest(fetchContactLog, {
    onSuccess: ({ contacts }) => {
      setState({ contactLog: contacts })
    },
  })
  const { makeRequest: createContactLogRequest } = useRequest(
    () => postContactLog(personId, state.newContactLog),
    {
      onSuccess: () => {
        setState(getInitialState())
        contactLogRequest(personId, {
          fields: ['contacted_at', 'contact_type', { user: 'name' }, 'meta'],
        })
      },
    }
  )

  const { makeRequest: usersRequest, response: usersResponse } =
    useRequest(fetchUsers)

  const userOptions = usersResponse
    ? usersResponse.users.map(user => ({ label: user.name, value: user.id }))
    : []

  useEffect(() => {
    contactLogRequest(personId, {
      fields: ['contacted_at', 'contact_type', { user: 'name' }, 'meta'],
    })
  }, [])

  useEffect(() => {
    usersRequest({ pageSize: Number.MAX_SAFE_INTEGER })
  }, [])

  return (
    <div>
      <TextBlock>
        <Font.Copy variant="highlight">{t('Activity log')}</Font.Copy>
      </TextBlock>
      {state.readOnly ? (
        <div>
          <ActivityLogTable log={state.contactLog} />
          <ButtonBlock>
            <Button
              onClick={() => {
                setState({ readOnly: false })
              }}
            >
              {t('Log activity')}
            </Button>
          </ButtonBlock>
        </div>
      ) : (
        <div>
          <TextBlock>
            <Font.Label>{t('Log a new activity')}</Font.Label>
          </TextBlock>
          <form>
            <FieldBlock>
              <SelectField
                id="activity-type"
                label={t('Activity type')}
                options={ACTIVITY_TYPE_OPTIONS}
                value={state.newContactLog.contact_type}
                onSelect={contactType => {
                  setState(prevState => ({
                    newContactLog: {
                      ...prevState.newContactLog,
                      contact_type: contactType,
                    },
                  }))
                }}
              />
            </FieldBlock>
            <FieldBlock>
              <DateField
                id="contact-date"
                label={t('Contact date')}
                value={new Date(state.newContactLog.contacted_at).toISOString()}
                onChange={dateString => {
                  setState(prevState => ({
                    newContactLog: {
                      ...prevState.newContactLog,
                      contacted_at: dateString,
                    },
                  }))
                }}
              />
            </FieldBlock>
            <FieldBlock>
              <SelectField
                id="with"
                label={t('With')}
                options={userOptions}
                value={state.newContactLog.user_id}
                onSelect={user_id => {
                  setState(prevState => ({
                    newContactLog: {
                      ...prevState.newContactLog,
                      user_id,
                    },
                  }))
                }}
              />
            </FieldBlock>
            <ButtonBlock>
              <Button.Accent onClick={createContactLogRequest}>
                {t('Save')}
              </Button.Accent>
              <Button.Secondary onClick={() => setState(getInitialState())}>
                {t('Cancel')}
              </Button.Secondary>
            </ButtonBlock>
          </form>
        </div>
      )}
    </div>
  )
}

export default ActivityLog
