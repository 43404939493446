import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultTo, get, isNumber } from 'lodash'
import {
  View,
  Checkbox,
  ViewContainer,
  CardError,
  HeaderBlock,
  CustomFields,
} from 'components'
import {
  Grid,
  TextField,
  NumberField,
  Section,
  FieldBlock,
  ButtonBlock,
  Button,
} from '@politechdev/blocks-design-system'
import { useRoutePathParams, useRequest, useReactRouter } from 'hooks'
import { useForm } from 'contexts'
import { logErrorResponse } from 'utils/logging'
import { setPacketInfo } from 'requests/qualityControl'
import { fetchShift } from 'requests/shifts'
import ShiftPdfViewer from './ShiftPdfViewer/ShiftPdfViewer'
import {
  DataEntryUploadStorage as UploadStorage,
  DataEntryFormStorage as FormStorage,
  COVER_SHEET_INDEX,
} from './storageUtils'
import { SHIFT_TYPE } from './constants'

const ShiftPacketInformation = () => {
  const { t } = useTranslation()
  const [{ id: shiftId, shiftType }] = useRoutePathParams()

  const { history } = useReactRouter()
  const { formData, setField, setFormData } = useForm()
  const [packetPageCount, setPacketPageCount] = useState(0)

  const localStoragePacketPageCount =
    UploadStorage.getCountRecordsInStorage(shiftId)

  const [emptyBatch, setEmptyBatch] = useState(false)

  const [packetUrl, setPacketUrl] = useState()
  const [packetFilename, setPacketFilename] = useState()

  const [usesLegacyEntry, setUsesLegacyEntry] = useState()
  const [tenantUsesManualEntry, setTenantUsesManualEntry] = useState()

  useEffect(() => {
    setEmptyBatch(
      (!tenantUsesManualEntry && packetPageCount < 2) ||
        (tenantUsesManualEntry && localStoragePacketPageCount < 2)
    )
  }, [tenantUsesManualEntry, packetPageCount, localStoragePacketPageCount])

  useEffect(() => {
    if (tenantUsesManualEntry) {
      setPacketUrl(
        UploadStorage.getRecordFromStorage(shiftId, COVER_SHEET_INDEX).url
      )
    }
  }, [tenantUsesManualEntry])

  const {
    makeRequest: requestShift,
    isLoading: shiftLoading,
    hasErrors: shiftError,
    isRequestComplete: shiftFetched,
    response: shiftResponse,
  } = useRequest(fetchShift, {
    onSuccess: ({
      shift: {
        packet,
        turf,
        soft_count_cards_total_collected,
        soft_count_cards_complete_collected,
        soft_count_cards_incomplete_collected,
        soft_count_cards_with_phone_collected,
        soft_count_pre_registration_cards_collected,
        notes,
      },
    }) => {
      setUsesLegacyEntry(turf.voter_registration_config?.uses_legacy_entry)
      setTenantUsesManualEntry(
        turf.voter_registration_config?.uses_texas_data_entry ||
          (!turf.voter_registration_config?.uses_legacy_entry &&
            shiftType === SHIFT_TYPE.REGISTRATION)
      )

      if (packet) {
        const {
          file_url,
          file_locator: {
            metadata: { page_count: count, filename },
          },
        } = packet
        setPacketUrl(file_url)
        setPacketFilename(filename)
        setPacketPageCount(count)
      }

      const customFieldConfig = turf.options.custom_field_configs?.find(
        c => c.table === 'shifts'
      )

      const customFieldsDefaults = customFieldConfig
        ? customFieldConfig.fields.reduce((defaults, field) => {
            if (field.type === 'integer') {
              defaults[field.name] = 0
            }

            return defaults
          }, {})
        : {}

      setFormData(formData => ({
        ...formData,
        soft_count_cards_total_collected,
        soft_count_cards_complete_collected,
        soft_count_cards_incomplete_collected,
        soft_count_cards_with_phone_collected,
        soft_count_pre_registration_cards_collected,
        notes,
        custom_field_data: customFieldsDefaults,
      }))
    },
  })

  const currentShift = defaultTo(shiftResponse?.shift, {})

  const {
    makeRequest: formRequest,
    isLoading: submitLoading,
    hasErrors: submitError,
  } = useRequest(setPacketInfo, {
    onSuccess: () => history.push(`/collect/${shiftType}/shifts`),
    onError: (error, args) =>
      logErrorResponse(error, args, 'Packet information error'),
  })

  const submitForm = e => {
    e.preventDefault()
    if (tenantUsesManualEntry) {
      let Storage = UploadStorage
      if (!usesLegacyEntry) {
        Storage = FormStorage
      }
      Storage.addPacketInfoToStorage(shiftId, formData)
      history.push(`/collect/voter_registration/shifts/${shiftId}/data_entry/1`)
    } else {
      formRequest(shiftId, formData)
    }
  }

  useEffect(() => {
    requestShift(shiftId, {
      fields: [
        'id',
        'soft_count_cards_total_collected',
        'soft_count_cards_complete_collected',
        'soft_count_cards_incomplete_collected',
        'soft_count_cards_with_phone_collected',
        'soft_count_pre_registration_cards_collected',
        'notes',
        'custom_field_data',
        { packet: ['file_locator', 'file_url'] },
        { turf: ['voter_registration_config', 'options'] },
      ],
    })
  }, [shiftId])

  const shiftLoaded =
    shiftFetched &&
    !shiftError &&
    (currentShift.id === +shiftId || currentShift.slug === shiftId)

  const customFieldsConfig =
    currentShift.turf?.options?.custom_field_configs?.find(
      c => c.table === 'shifts'
    )

  const setCustomField = (field, val) =>
    setField(
      { ...formData.custom_field_data, [field]: val },
      'custom_field_data'
    )

  const areCountsConflicting =
    isNumber(formData.soft_count_cards_total_collected) &&
    isNumber(formData.soft_count_cards_complete_collected) &&
    isNumber(formData.soft_count_cards_incomplete_collected) &&
    formData.soft_count_cards_total_collected !==
      formData.soft_count_cards_complete_collected +
        formData.soft_count_cards_incomplete_collected

  const arePhoneFormsMoreThanTotalForms =
    isNumber(formData.soft_count_cards_total_collected) &&
    isNumber(formData.soft_count_cards_with_phone_collected) &&
    formData.soft_count_cards_with_phone_collected >
      formData.soft_count_cards_total_collected

  const isStaticFormValid =
    isNumber(formData.soft_count_cards_with_phone_collected) &&
    isNumber(formData.soft_count_pre_registration_cards_collected) &&
    !areCountsConflicting &&
    !arePhoneFormsMoreThanTotalForms

  const isCustomFormValid =
    !customFieldsConfig ||
    !(customFieldsConfig?.fields || []).some(field => {
      const value = get(formData.custom_field_data, field.name)
      const isInvalid = !value && value !== 0
      return field.required && isInvalid
    })

  const isFormValid = isStaticFormValid && isCustomFormValid

  return (
    <View>
      <HeaderBlock title={t('Packet information')} />
      <ViewContainer loading={shiftLoading || submitLoading}>
        <CardError
          hide={!shiftError}
          message={t("We're unable to retrieve this shift")}
        />
        <CardError
          hide={!submitError}
          message={t("We're unable to save packet information")}
        />
        {shiftLoaded && (
          <Grid>
            <Section>
              <form onSubmit={submitForm}>
                <FieldBlock>
                  <Checkbox
                    id="empty_batch_toggle"
                    label={t('Canvasser collected at least 1 form')}
                    checked={!emptyBatch}
                    onChange={() => setEmptyBatch(!emptyBatch)}
                  />
                </FieldBlock>
                <FieldBlock>
                  <NumberField
                    id="cards_collected"
                    disabled={emptyBatch}
                    required
                    min={0}
                    label={t('Registration forms collected')}
                    value={formData.soft_count_cards_total_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_total_collected')
                    }
                    error={areCountsConflicting}
                    errorMessage={t(
                      'Does not equal the complete and incomplete registration form collected fields'
                    )}
                  />
                  <NumberField
                    id="cards_complete"
                    disabled={emptyBatch}
                    required
                    label={t('Registration forms complete')}
                    min={0}
                    value={formData.soft_count_cards_complete_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_complete_collected')
                    }
                  />
                  <NumberField
                    id="cards_incomplete"
                    disabled={emptyBatch}
                    required
                    label={t('Registration forms incomplete')}
                    min={0}
                    value={formData.soft_count_cards_incomplete_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_incomplete_collected')
                    }
                  />
                  <NumberField
                    id="cards_with_phone"
                    disabled={emptyBatch}
                    required
                    label={t('Registration forms with phone')}
                    min={0}
                    error={arePhoneFormsMoreThanTotalForms}
                    errorMessage={t(
                      'Cannot have more registration forms with phone than total collected registration forms'
                    )}
                    value={formData.soft_count_cards_with_phone_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_with_phone_collected')
                    }
                  />
                  <NumberField
                    id="pre_reg_cards"
                    disabled={emptyBatch}
                    required
                    label={t('Pre-reg forms')}
                    min={0}
                    value={formData.soft_count_pre_registration_cards_collected}
                    onChange={val =>
                      setField(
                        +val,
                        'soft_count_pre_registration_cards_collected'
                      )
                    }
                  />
                </FieldBlock>
                {!!customFieldsConfig && (
                  <FieldBlock>
                    <CustomFields
                      fields={customFieldsConfig.fields}
                      currentData={formData.custom_field_data || {}}
                      onChange={setCustomField}
                      disabled={emptyBatch}
                    />
                  </FieldBlock>
                )}
                <FieldBlock variant="large">
                  <TextField
                    id="notes"
                    disabled={emptyBatch}
                    label={t('Notes')}
                    value={formData.notes || ''}
                    onChange={val => setField(val, 'notes')}
                    type="text"
                  />
                </FieldBlock>
                <ButtonBlock justify="left">
                  <Button.Accent
                    disabled={submitLoading || (!isFormValid && !emptyBatch)}
                    type="submit"
                  >
                    {t('Save')}
                  </Button.Accent>
                </ButtonBlock>
              </form>
            </Section>
            <Section>
              <ShiftPdfViewer url={packetUrl} filename={packetFilename} />
            </Section>
          </Grid>
        )}
      </ViewContainer>
    </View>
  )
}

export default ShiftPacketInformation
