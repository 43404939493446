import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, CardError } from 'components'
import {
  Icon,
  Font,
  Button,
  ButtonBlock,
  TextBlock,
  FieldBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import moment from 'moment'
import { useRequest } from 'hooks'
import { useHistory } from 'react-router-dom'
import { putPhoneBank } from 'requests/phoneBanks'
import styles from './PhoneBankArchiveButton.module.scss'
import { PhoneBankContext } from '../PhoneBankContext/PhoneBankContext'

const PhoneBankArchiveButton = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { phoneBank: currentPhoneBank } = useContext(PhoneBankContext)

  const [errorMessage, setErrorMessage] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  useEffect(() => {
    setStartDate(currentPhoneBank.start_date)
    setEndDate(currentPhoneBank.end_date)
  }, [currentPhoneBank])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const archivePhoneBankHandlers = {
    onSuccess: () => {
      closeModal()
      history.push('/organize/phone_banks')
    },
    onError: () => {
      setErrorMessage(
        t('An error occurred while trying to restore this phone bank')
      )
    },
  }

  const { makeRequest } = useRequest(putPhoneBank, archivePhoneBankHandlers)

  const phoneBankEnded = moment(endDate).isBefore(moment(), 'day')

  const archivePhoneBank = () =>
    makeRequest(currentPhoneBank.id, {
      archived: true,
      start_date: startDate,
      end_date: endDate,
    })

  const restorePhoneBank = () => {
    if (phoneBankEnded) return openModal()
    return makeRequest(currentPhoneBank.id, {
      archived: false,
      start_date: startDate,
      end_date: endDate,
    })
  }

  return (
    <>
      {currentPhoneBank.archived ? (
        <Button onClick={restorePhoneBank} aria-label={t('Restore')}>
          <Icon.BoxOpen alt={t('Restore')} />
        </Button>
      ) : (
        <Button onClick={archivePhoneBank} aria-label={t('Archive')}>
          <Icon.Archive alt={t('Archive')} />
        </Button>
      )}
      <Modal
        id="phone-bank-archive"
        title={t('Restore Phone Bank')}
        isOpen={modalOpen}
        className={styles.modal}
      >
        <Modal.Body>
          <CardError
            hide={!errorMessage}
            message={errorMessage}
            hideSupportLink
          />
          <TextBlock>
            <Font.Copy>
              {t(
                'In order to restore this phone bank, a new phone bank date must be set that ends after today.'
              )}
            </Font.Copy>
          </TextBlock>
          <FieldBlock>
            <DatePicker
              label={t('Start date')}
              value={startDate || ''}
              onChange={val => setStartDate(val[0])}
            />
            <DatePicker
              label={t('End date')}
              value={endDate || ''}
              onChange={val => setEndDate(val[0])}
            />
          </FieldBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button
              disabled={phoneBankEnded}
              onClick={() => {
                restorePhoneBank()
              }}
            >
              {t('Restore')}
            </Button>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default PhoneBankArchiveButton
