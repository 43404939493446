import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  LocationSelectField,
  CanvasserSelectField,
  CardError,
  CustomFields,
} from 'components'
import {
  Button,
  FieldBlock,
  ButtonBlock,
  TextField,
  NumberField,
  DateField,
  TimePicker,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { useRequest, useReactRouter } from 'hooks'
import { LOCATION_TYPES } from 'constants/locations'
import { roundISOStringToNearest15Minutes } from 'utils/dateTime'
import { formatErrorMessage } from 'utils/formatting'
import { postShift, putShift } from 'requests/shifts'
import {
  buildRequest,
  buildForm,
  initializeForm,
  validateForm,
  areFormsCollectedValid,
  areFormsWithPhoneValid,
  getDateErrors,
} from './utils'
import { SHIFT_TYPE } from '../constants'

const ShiftForm = ({ shift, shiftType }) => {
  const isEditForm = !!shift
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [showErrors, setShowErrors] = useState(false)
  const { formData, setField, setFormData } = useForm()

  const { qcEnabled } = useCurrent()

  const formAction = isEditForm
    ? formData => putShift(shift.id, formData)
    : (formData, params) => postShift(formData, params)

  const { makeRequest, isLoading, hasErrors, errors } = useRequest(formAction, {
    onSuccess: ({ shift: incomingShift }) => {
      const shiftId = isEditForm ? shift.id : incomingShift.id
      const isShiftInFuture = moment(formData.shiftDate).isAfter(moment())
      if (isEditForm || isShiftInFuture) {
        history.push(`/collect/${shiftType}/shifts`)
      } else if (qcEnabled) {
        history.push(`/collect/${shiftType}/shifts/${shiftId}/scans`)
      } else {
        history.push(`/collect/${shiftType}/shifts/${shiftId}/data_entry`)
      }
    },
  })

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    if (shift) {
      setFormData(buildForm(shift))
    } else {
      setFormData(initializeForm)
    }
  }, [shift])

  const hasPacket = shift && (!!shift.packet || !!shift.petition_packet)

  const useSoftCounts = shiftType === SHIFT_TYPE.REGISTRATION && hasPacket

  const customFieldsConfig = shift?.turf?.options?.custom_field_configs?.find(
    c => c.table === 'shifts'
  )

  const setCustomField = (field, val) =>
    setField(
      { ...formData.custom_field_data, [field]: val },
      'custom_field_data'
    )

  const formIsValid = validateForm(
    formData,
    useSoftCounts,
    hasPacket,
    customFieldsConfig
  )

  const { dateErrors, hasDateErrors } = useMemo(
    () => getDateErrors({ formData, formIsValid, t }),
    [formData]
  )

  return (
    <div>
      <ProgressBar show={isLoading} />
      <CardError hide={!hasErrors} message={errorMsg} />
      <form>
        <FieldBlock>
          <LocationSelectField
            onSelect={val => setField(val, 'location')}
            locationTypes={[LOCATION_TYPES.canvassing]}
            location={formData.location}
            error={!!errors.location_id}
            errorMessage={errors.location_id}
            required
            clearable
          />
        </FieldBlock>
        <FieldBlock>
          <CanvasserSelectField
            onSelect={val => setField(val, 'canvasser')}
            selectedCanvasser={formData.canvasser}
            disableNonCollectingTurfs
            required
            clearable
          />
        </FieldBlock>
        <FieldBlock>
          <DateField
            label="Shift Date"
            value={formData.shiftDate}
            onChange={val => {
              setField(val, 'shiftDate')
            }}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TimePicker
            label={t('Shift start')}
            id="shift-start-time"
            onSelect={val => setField(val, 'shiftStartTime')}
            hour={moment(shift?.shift_start).hour()}
            minute={
              shift?.shift_start
                ? roundISOStringToNearest15Minutes(shift.shift_start)
                : 0
            }
            error={showErrors && dateErrors.shiftStart}
            errorMessage={dateErrors.shiftStart.join('\n')}
          />
        </FieldBlock>
        <FieldBlock>
          <TimePicker
            label={t('Shift end')}
            id="shift-end-time"
            onSelect={val => setField(val, 'shiftEndTime')}
            hour={
              shift?.shift_end
                ? moment(shift.shift_end).hour()
                : moment().hour() + 2
            }
            minute={
              shift?.shift_end
                ? roundISOStringToNearest15Minutes(shift.shift_end)
                : 0
            }
            error={showErrors && dateErrors.shiftEnd}
            errorMessage={dateErrors.shiftEnd.join('\n')}
          />
        </FieldBlock>
        <FieldBlock>
          <TimePicker
            label={t('Field start')}
            id="field-start-time"
            onSelect={val => setField(val, 'fieldStartTime')}
            hour={moment(shift?.field_start).hour()}
            minute={
              shift?.field_start
                ? roundISOStringToNearest15Minutes(shift.field_start)
                : 0
            }
            error={showErrors && dateErrors.fieldStart}
            errorMessage={dateErrors.fieldStart.join('\n')}
          />
        </FieldBlock>
        <FieldBlock>
          <TimePicker
            label={t('Field end')}
            id="field-end-time"
            onSelect={val => setField(val, 'fieldEndTime')}
            hour={
              shift?.field_end
                ? moment(shift.field_end).hour()
                : moment().hour() + 2
            }
            minute={
              shift?.field_end
                ? roundISOStringToNearest15Minutes(shift.field_end)
                : 0
            }
          />
        </FieldBlock>
        {useSoftCounts && qcEnabled && (
          <>
            <FieldBlock>
              <NumberField
                id="total-collected"
                label={t('Registration forms collected')}
                min="0"
                value={formData.totalCollected}
                onChange={val => setField(val, 'totalCollected')}
                error={!areFormsCollectedValid(formData)}
                errorMessage={t(
                  'Does not equal the complete and incomplete registration form collected fields'
                )}
                required
              />
            </FieldBlock>
            <FieldBlock>
              <NumberField
                id="complete-collected"
                label={t('Registration forms complete')}
                min="0"
                value={formData.completeCollected}
                onChange={val => setField(val, 'completeCollected')}
                required
              />
            </FieldBlock>
            <FieldBlock>
              <NumberField
                id="incomplete-collected"
                label={t('Registration forms incomplete')}
                min="0"
                value={formData.incompleteCollected}
                onChange={val => setField(val, 'incompleteCollected')}
                required
              />
            </FieldBlock>
            <FieldBlock>
              <NumberField
                id="phone-collected"
                label={t('Registration forms with phone')}
                min="0"
                error={!areFormsWithPhoneValid(formData)}
                errorMessage={t(
                  'Cannot have more registration forms with phone than total collected registration forms'
                )}
                value={formData.phoneCollected}
                onChange={val => setField(val, 'phoneCollected')}
                required
              />
            </FieldBlock>
            <FieldBlock>
              <NumberField
                id="pre-reg-collected"
                label={t('Pre-reg forms')}
                min="0"
                value={formData.preRegCollected}
                onChange={val => setField(val, 'preRegCollected')}
                required
              />
            </FieldBlock>
            {!!customFieldsConfig && (
              <FieldBlock>
                <CustomFields
                  fields={customFieldsConfig.fields}
                  currentData={formData.custom_field_data || {}}
                  onChange={setCustomField}
                />
              </FieldBlock>
            )}
            <FieldBlock>
              <TextField
                id="notes"
                label={t('Notes')}
                rows={2}
                value={formData.notes || ''}
                onChange={val => setField(val, 'notes')}
              />
            </FieldBlock>
          </>
        )}
        <ButtonBlock>
          <Button.Accent
            disabled={
              !formIsValid || isLoading || (showErrors && hasDateErrors)
            }
            onClick={() => {
              hasDateErrors
                ? setShowErrors(true)
                : makeRequest(buildRequest(formData, shiftType), {
                    fields: ['id'],
                  })
            }}
          >
            {t(isEditForm ? 'Save Shift' : 'Create Shift')}
          </Button.Accent>
        </ButtonBlock>
      </form>
    </div>
  )
}

export default ShiftForm
