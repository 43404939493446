export const buildRequest = formData => ({
  first_name: formData.first_name,
  last_name: formData.last_name,
  primary_email_address: formData.email,
  primary_phone_number: formData.phone,
  receives_sms: formData.receives_sms,
  residential_address: {
    line_one: formData.line_one,
    line_two: formData.line_two,
    city: formData.city,
    state: formData.state,
    zipcode: formData.zipcode,
  },
  organization_memberships: formData.organization
    ? [{ organization_id: formData.organization.id }]
    : [],
  primary_language: formData.primary_language,
})
