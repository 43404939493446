import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LoadBar } from 'components'
import { useRouteQueryParams } from 'hooks'
import { useTranslation } from 'react-i18next'
import {
  getCurrentPacket,
  getCurrentScan,
  getSortedScanIds,
  getVisualUnreviewedScanIds,
  getVisualUnapprovedScanIds,
  getPhoneUnreviewedScanIds,
} from 'store/qcScans/reducer'
import { changeScanPage } from 'store/qcScans/utils'
import { getCurrentFlagTriggeredScanIds } from 'store/qcFlags/reducer'
import { useCurrent } from 'contexts/index'
import PacketDetails from './PacketDetails'
import PacketPaginationControls from './PacketPaginationControls'
import { ScanPdfViewer } from './PdfViewer'
import ScanViewerHeader from './ScanViewHeader/ScanViewerHeader'
import QcReviewPanel from './visualQc/QcReviewPanel/QcReviewPanel'
import PhoneVerificationPanel from './phoneVerification/PhoneVerificationPanel/PhoneVerificationPanel'

const PacketViewer = ({
  packetId,
  title,
  showDetail = true,
  showCountyLookup = true,
  showPaginationStatus,
  currentPacket,
  currentScan = { unset: true },
  sortedScanIds,
  eligibleScanIds,
}) => {
  const [{ scanId: stringScanId }, setQueryParams] = useRouteQueryParams()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canPerformVisualQc = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'visual_qc',
  })
  const canPerformPhoneVerification = doesCurrentUserHavePermission({
    resource: 'shift',
    ability: 'phone_verification',
  })
  const canReviewFlags = doesCurrentUserHavePermission({
    resource: 'quality_control/flag',
    ability: 'review',
  })
  const [shouldSkip, toggleSkip] = useState(false)
  const scanId = +stringScanId
  const { scans_count: scansCount } = currentPacket

  let selectedScan = currentScan

  if (currentScan.unset) {
    selectedScan = scanId
      ? currentPacket.voter_registration_scans.find(({ id }) => id === scanId)
      : currentPacket.voter_registration_scans[0]
  }

  const currentScanIndex = sortedScanIds.indexOf(scanId)

  const isPacketLoaded = +packetId === currentPacket.id

  useEffect(() => {
    const loadInitialScan = () => {
      if (!isPacketLoaded) return
      if (scanId && currentScanIndex > -1) return

      if (eligibleScanIds.length) {
        setQueryParams({ scanId: eligibleScanIds[0] }, 'replace')
      } else if (sortedScanIds.length) {
        setQueryParams({ scanId: sortedScanIds[0] }, 'replace')
      }
    }
    loadInitialScan()
  }, [isPacketLoaded, scanId, eligibleScanIds, scansCount, currentScanIndex])

  const args = {
    currentScanIndex,
    shouldSkip,
    sortedScanIds,
    filteredScanIds: eligibleScanIds,
    setQueryParams,
  }

  const { t } = useTranslation()

  const goToNextScan = () => changeScanPage(1, args)
  const previousScan = () => changeScanPage(-1, args)

  if (!sortedScanIds.length) return null

  return (
    <section>
      {title && (
        <div className="inbox-viewer__sidebar__header">
          <h2>{title}</h2>
        </div>
      )}
      {!isPacketLoaded ? (
        <LoadBar show />
      ) : (
        <>
          <ScanViewerHeader
            scansCount={scansCount}
            currentScanIndex={currentScanIndex}
            scanNumber={selectedScan.scan_number}
          />
          <ScanPdfViewer
            scanUrl={selectedScan.file_url}
            removedAt={selectedScan.file_locator?.metadata?.redacted_at}
            isInteractable
          />
          {showDetail && <PacketDetails />}
          {(canPerformVisualQc || canReviewFlags) && (
            <QcReviewPanel
              showCountyLookup={showCountyLookup}
              currentScan={selectedScan}
              initExpanded
              goToNext={goToNextScan}
            />
          )}
          {(canPerformPhoneVerification || canReviewFlags) && (
            <PhoneVerificationPanel />
          )}
          <PacketPaginationControls
            currentScanIndex={currentScanIndex}
            scansCount={scansCount}
            goToNext={goToNextScan}
            goToPrevious={previousScan}
            shouldSkip={shouldSkip}
            toggleSkip={toggleSkip}
            skipLabel={t('Skip reviewed scans')}
            showSkipCheckbox={
              canPerformVisualQc ||
              canPerformPhoneVerification ||
              canReviewFlags
            }
            showStatusIcons={showPaginationStatus}
          />
        </>
      )}
    </section>
  )
}

export const VisualReviewPacketViewer = connect(state => ({
  showPaginationStatus: true,
  currentPacket: getCurrentPacket(state),
  currentScan: getCurrentScan(state),
  sortedScanIds: getSortedScanIds(state),
  eligibleScanIds: getVisualUnreviewedScanIds(state),
}))(PacketViewer)

export const PhoneReviewPacketViewer = connect(state => ({
  currentPacket: getCurrentPacket(state),
  currentScan: getCurrentScan(state),
  sortedScanIds: getSortedScanIds(state),
  eligibleScanIds: getPhoneUnreviewedScanIds(state),
}))(PacketViewer)

export const FlagPacketViewer = connect(state => ({
  currentPacket: getCurrentPacket(state),
  currentScan: getCurrentScan(state),
  sortedScanIds: getSortedScanIds(state),
  eligibleScanIds: getCurrentFlagTriggeredScanIds(state),
}))(PacketViewer)

export const VisualApprovePacketViewer = connect(state => ({
  currentPacket: getCurrentPacket(state),
  currentScan: getCurrentScan(state),
  sortedScanIds: getSortedScanIds(state),
  eligibleScanIds: getVisualUnapprovedScanIds(state),
}))(PacketViewer)
