class TwilioInstance {
  #accountSid

  #apiKey

  #apiSecret

  constructor() {
    if (!process.env.TWILIO_ACCOUNT_SID) {
      // eslint-disable-next-line no-console
      console.error('No twilio account configured')
      return
    }
    this.#accountSid = process.env.TWILIO_ACCOUNT_SID
    this.#apiKey = process.env.TWILIO_CLIENT_SID
    this.#apiSecret = process.env.TWILIO_CLIENT_SECRET
  }

  getRecordingUrl(recordingSid) {
    return `https://api.twilio.com/2010-04-01/Accounts/${this.#accountSid}/Recordings/${recordingSid}`
  }

  getBasicAuth() {
    return `Basic ${btoa(`${this.#apiKey}:${this.#apiSecret}`)}`
  }

  async fetchRecording(recordingSid) {
    return fetch(this.getRecordingUrl(recordingSid), {
      method: 'GET',
      headers: {
        'Content-Type': 'audio/wav',
        Authorization: this.getBasicAuth(),
      },
    })
  }
}

const twilio = new TwilioInstance()

export default twilio
