import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useReactRouter, useReduxAction, useRequest } from 'hooks'
import { useTranslation } from 'react-i18next'
import {
  getFullCallHistory,
  getCurrentPacketTurfId,
} from 'store/qcScans/reducer'
import { fetchPhoneVerificationQuestions } from 'store/qcScans/actions'
import ScanReviewSection from 'qualityControl/scanReview/ScanReviewSection/ScanReviewSection'
import CallHistoryList from '../callSession/CallHistoryList/CallHistoryList'
import CallMenu from '../CallMenu/CallMenu'
import styles from './PhoneVerificationPanel.module.scss'

const PhoneVerificationPanel = () => {
  const { t } = useTranslation()

  const {
    location: { pathname },
  } = useReactRouter()

  const isInVisualQc = () => pathname.split('/').pop() === 'visual_qc'
  const isInPhoneVerification = () =>
    pathname.split('/').pop() === 'phone_verification'

  const reduxFetchQuestions = useReduxAction(fetchPhoneVerificationQuestions)

  const callAttempts = useSelector(getFullCallHistory)
  const turfId = useSelector(getCurrentPacketTurfId)

  if (isInVisualQc()) return null

  const { makeRequest, isRequestComplete } = useRequest(reduxFetchQuestions)

  useEffect(() => {
    if (turfId) {
      makeRequest(turfId)
    }
  }, [turfId])

  return (
    <>
      <div className={styles.title}>
        <h3>{t('Phone Verification')}</h3>
      </div>
      {callAttempts.length && isRequestComplete ? (
        <ScanReviewSection
          approved={callAttempts[callAttempts.length - 1].result === 'Verified'}
          initExpanded={isInPhoneVerification()}
        >
          <CallHistoryList attempts={callAttempts} />
          <CallMenu />
        </ScanReviewSection>
      ) : (
        <CallMenu />
      )}
    </>
  )
}

export default PhoneVerificationPanel
