import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError } from 'components'
import {
  Icon,
  TextBlock,
  Button,
  ButtonBlock,
  ContentBlock,
  Modal,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { useHistory } from 'react-router-dom'
import { deletePhoneBank } from 'requests/phoneBanks'
import styles from './PhoneBankDeleteModal.module.scss'
import { PhoneBankContext } from '../PhoneBankContext/PhoneBankContext'

const PhoneBankDeleteModal = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [modalOpen, setModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { phoneBank: currentPhoneBank } = useContext(PhoneBankContext)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const deletePhoneBankHandlers = {
    onSuccess: () => {
      closeModal()
      history.push('/organize/phone_banks')
    },
    onError: () => {
      setErrorMessage(
        t('An error occurred while trying to delete this phone bank')
      )
    },
  }

  const { makeRequest } = useRequest(deletePhoneBank, deletePhoneBankHandlers)

  return (
    <>
      <Button onClick={openModal} aria-label={t('delete')}>
        <Icon.TrashAlt alt={t('delete')} />
      </Button>
      <Modal
        id="phone-bank-delete"
        title={t('Delete phone bank')}
        isOpen={modalOpen}
        className={styles.modal}
      >
        <Modal.Body>
          <ContentBlock>
            <CardError
              hide={!errorMessage}
              message={errorMessage}
              hideSupportLink
            />
            <TextBlock className={styles.text}>
              {t('Are you sure you want to delete')}{' '}
              <strong>{currentPhoneBank.name}</strong>
            </TextBlock>
          </ContentBlock>
        </Modal.Body>
        <Modal.Actions>
          <ButtonBlock justify="right">
            <Button.Secondary onClick={closeModal}>
              {t('Cancel')}
            </Button.Secondary>
            <Button.Danger
              onClick={() => {
                makeRequest(currentPhoneBank.id)
              }}
            >
              {t('Delete')}
            </Button.Danger>
          </ButtonBlock>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default PhoneBankDeleteModal
