/* eslint-disable arrow-body-style */
import { useEffect, useState } from 'react'
import { View, ViewContainer, HeaderBlock, Paginator, Sticky } from 'components'
import {
  Button,
  ButtonBlock,
  Font,
  TextBlock,
  SelectField,
} from '@politechdev/blocks-design-system'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import useLocalForm from 'hooks/useLocalForm'
import { useRequest } from 'hooks/useRequest'
import { useRouteQueryParams } from 'hooks/router'
import { fetchPackets, fetchShift, postDigitalBatch } from 'requests/shifts'
import { fetchCanvasser } from 'requests/canvassers'
import { getTotalPagesFromFilename } from 'shifts/utils'
import { DataEntryUploadStorage } from 'shifts/storageUtils'
import RegistrationForm from './RegistrationForm'
import PledgeCardForm from './PledgeCardForm'
import SubmitOrContinue from './SubmitOrContinue'
import {
  filenameCleanupRegex,
  formatForSubmission,
  getInitialFormData,
  getOptionArrayOfLength,
  scrollToTop,
} from './utils'
import CancelDataEntryModal from './CancelDataEntryModal'
import IneligibleForEntry from './IneligibleForEntry'
import styles from './ShiftDataEntry.module.scss'
import ConfirmVDRModal from './ConfirmVDRModal'
import { PledgeCardPreview } from './PledgeCardPreview'

const TexasPaginatorDisplay = ({ totalPages, pageId, goToPage }) => (
  <TextBlock>
    <span>
      <Trans>Page</Trans>
    </span>
    <SelectField
      className={styles['paginator-select']}
      options={getOptionArrayOfLength(totalPages)}
      value={pageId}
      onSelect={goToPage}
    />
    <span>
      <Trans>of</Trans> {totalPages}
    </span>
  </TextBlock>
)

const TexasDataEntry = () => {
  const { t } = useTranslation()
  const [queryParams] = useRouteQueryParams()
  const isRegistrationOnly = queryParams.isRegistrationOnly === 'true'
  const { shiftId: shiftIdString, pageId: pageIdString } = useParams()
  const history = useHistory()

  const [previewPledgeCard, setPreviewPledgeCard] = useState(true)
  const [scanCount, setScanCount] = useState(-1)
  const [pageCount, setPageCount] = useState(-1)
  const [shiftStatus, setShiftStatus] = useState('ready_for_scans')
  const [canvasser, setCanvasser] = useState()
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isVDRModalOpen, setIsVDRModalOpen] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [packetExists, setPacketExists] = useState(false)
  const [isNewForm, setIsNewForm] = useState(true)
  const [isScansExpired, setIsScansExpired] = useState()

  const pageId = parseInt(pageIdString)
  const shiftId = parseInt(shiftIdString)

  const {
    formData,
    setField,
    getField,
    setFieldError,
    fieldErrors,
    setFormData,
    areFieldsValid,
  } = useLocalForm(getInitialFormData(isRegistrationOnly))

  const handleSuccesfulUpload = async () => {
    history.push(`/collect/voter_registration/shifts/${shiftId}/packet`)
  }

  const { makeRequest: submitDataEntryReq, isLoading: submitDataIsLoading } =
    useRequest(postDigitalBatch, {
      onSuccess: handleSuccesfulUpload,
    })

  const { makeRequest: fetchShiftReq, isLoading: isShiftCheckLoading } =
    useRequest(fetchShift, {
      onSuccess: async ({ shift }) => {
        setShiftStatus(shift.status)
        const { canvasser } = await fetchCanvasser(shift.canvasser.id, {
          fields: ['vdrs', 'full_name', 'id'],
        })
        setCanvasser(canvasser)
      },
      onError: () => {
        setShiftStatus(false)
      },
    })

  const { makeRequest: checkDuplicateReq, isLoading: isDupeCheckLoading } =
    useRequest(fetchPackets, {
      onSuccess: ({ packets }) => {
        if (packets.length > 0) {
          setPacketExists(true)
        }
      },
    })

  const { makeRequest: getFormRequest, isLoading: isFormDataLoading } =
    useRequest(DataEntryUploadStorage.getRecordFromStorage, {
      onSuccess: async scan => {
        if (scan?.formData) {
          setFormData(scan.formData)
          setIsNewForm(false)
        } else {
          setFormData(getInitialFormData(isRegistrationOnly))
          setIsNewForm(true)
        }
        if (scan?.url) {
          const res = await fetch(scan.url)
          setIsScansExpired(!res.ok)
        }
        setSubmitAttempted(false)
        setPreviewPledgeCard(true)
      },
    })

  useEffect(() => {
    const shiftIsInStorage = DataEntryUploadStorage.isShiftInStorage(shiftId)
    if (!shiftIsInStorage) {
      history.push(`/collect/voter_registration/shifts`)
    }

    const coverScan = DataEntryUploadStorage.getRecordFromStorage(shiftId, 0)
    const { filename } = coverScan.metadata
    const totalScans = getTotalPagesFromFilename(filename)
    const packetName = filename.replace(filenameCleanupRegex, '.pdf')

    setScanCount(totalScans)
    setPageCount(DataEntryUploadStorage.getCountRecordsInStorage(shiftId))

    checkDuplicateReq({
      filters: {
        rules: [
          {
            column: 'original_filename',
            operator: 'is',
            param: packetName,
          },
        ],
      },
    })
    fetchShiftReq(shiftId, {
      fields: ['status', { canvasser: ['id'] }],
    })
  }, [shiftId])

  useEffect(() => {
    getFormRequest(shiftId, pageId)
  }, [pageId, shiftId])

  const goToPage = page => {
    setFormData(null)
    scrollToTop()
    const isFormWithoutScan = page > scanCount - 1
    const isFormInStorage = page <= pageCount
    history.push(
      `/collect/voter_registration/shifts/${shiftId}/data_entry/${page}?isRegistrationOnly=${
        isFormWithoutScan && isFormInStorage
      }`
    )
  }

  const storeScanAndGotoPage = async page => {
    const currentScan =
      DataEntryUploadStorage.getRecordFromStorage(shiftId, pageId) || {}
    currentScan.formData = formData

    await DataEntryUploadStorage.addRecordToStorage(
      shiftId,
      pageId,
      currentScan
    )
    if (pageId > pageCount) {
      setPageCount(pageId)
    }
    setFormData(null)

    goToPage(page)
  }

  const validateAndContinue = async page => {
    const hasRequiredCounty =
      !formData.meta.hasRegistrationForm || formData.voting_address_county
    if (!hasRequiredCounty) {
      setFieldError('voting_address_county', 'required')
    }

    if (!hasRequiredCounty || !areFieldsValid) {
      setSubmitAttempted(true)
      return
    }
    const { canvasser: vdrCanvasser } = await fetchCanvasser(canvasser.id, {
      fields: ['vdrs', 'full_name', 'id'],
    })

    const isValidCountyForCanvasser =
      !formData.meta.hasRegistrationForm ||
      vdrCanvasser.vdrs.some(
        vdr => vdr.county === getField('voting_address_county')
      )

    if (!isValidCountyForCanvasser) {
      setField(true, 'no_vdr_match')
      setIsVDRModalOpen(page)
    } else {
      storeScanAndGotoPage(page)
    }
  }

  const continueToRegistrationOnlyEntry = () => {
    setFormData(getInitialFormData(true))
    scrollToTop()
    history.push(
      `/collect/voter_registration/shifts/${shiftId}/data_entry/${pageId}?isRegistrationOnly=true`
    )
  }

  const handleSubmitUpload = () => {
    const storedRecord = DataEntryUploadStorage.getFromStorage(shiftId)
    const formattedUpload = formatForSubmission(storedRecord.data)
    submitDataEntryReq(shiftId, formattedUpload)
  }

  const handleLeaveDataEntry = async () => {
    history.push(`/collect/voter_registration/shifts`)
  }

  const isChecksLoading = isShiftCheckLoading || isDupeCheckLoading

  if (shiftStatus !== 'ready_for_scans' || packetExists || isScansExpired) {
    return (
      <IneligibleForEntry
        shiftStatus={shiftStatus}
        packetExists={packetExists}
        isScansExpired={isScansExpired}
      />
    )
  }

  if (pageId >= scanCount && !isRegistrationOnly) {
    return (
      <SubmitOrContinue
        onContinue={continueToRegistrationOnlyEntry}
        onReview={() => goToPage(1)}
        onSubmit={handleSubmitUpload}
        isLoading={submitDataIsLoading}
      />
    )
  }

  if (!isRegistrationOnly && previewPledgeCard) {
    return (
      <PledgeCardPreview
        closePreview={() => setPreviewPledgeCard(false)}
        handleCancel={handleLeaveDataEntry}
      />
    )
  }

  const totalPages = Math.max(pageId, pageCount)

  return (
    <View>
      <HeaderBlock title={t('Data entry')} className={styles.header} />
      <Sticky className={styles.sticky}>
        <Paginator
          currentPage={pageId}
          totalPages={totalPages}
          onNext={() => goToPage(pageId + 1)}
          onPrevious={() => goToPage(pageId - 1)}
          CustomDisplay={
            <TexasPaginatorDisplay
              totalPages={totalPages}
              pageId={pageId}
              goToPage={goToPage}
            />
          }
        />
        {!areFieldsValid && submitAttempted && (
          <div className={styles['button-hint']}>
            <Font.Copy
              Element="p"
              variant="hint"
              className={styles['error-text']}
            >
              {t('Please check for invalid fields.')}
            </Font.Copy>
          </div>
        )}
        <ButtonBlock justify="right">
          <Button.Accent
            onClick={() => validateAndContinue(pageId + 1)}
            disabled={!areFieldsValid && submitAttempted}
          >
            {isNewForm ? t('Save') : t('Update')}
          </Button.Accent>
          <Button.Secondary onClick={() => setIsCancelModalOpen(true)}>
            {t('Cancel')}
          </Button.Secondary>
        </ButtonBlock>
      </Sticky>
      <ViewContainer loading={isChecksLoading || isFormDataLoading}>
        {!isChecksLoading && !isFormDataLoading && formData && (
          <>
            <RegistrationForm
              setField={setField}
              getField={getField}
              formData={formData}
              fieldErrors={fieldErrors}
              setFieldError={setFieldError}
              submitAttempted={submitAttempted}
            />
            {getField('meta.hasPledgeCard') ? (
              <PledgeCardForm
                setField={setField}
                getField={getField}
                formData={formData}
                fieldErrors={fieldErrors}
                setFieldError={setFieldError}
                submitAttempted={submitAttempted}
              />
            ) : null}
          </>
        )}
        <ConfirmVDRModal
          isOpen={isVDRModalOpen}
          setIsOpen={setIsVDRModalOpen}
          onConfirm={() => storeScanAndGotoPage(isVDRModalOpen)}
          canvasser={canvasser}
          county={getField('voting_address_county')}
        />
        <CancelDataEntryModal
          isOpen={isCancelModalOpen}
          setIsOpen={setIsCancelModalOpen}
          onConfirm={handleLeaveDataEntry}
        />
      </ViewContainer>
    </View>
  )
}

export default TexasDataEntry
