import React, { useContext, useMemo } from 'react'
import {
  DataTable,
  TableColumn,
  TableRow,
  TableHeader,
  TableBody,
} from 'react-md'
import {
  FieldBlock,
  DateField,
  SelectField,
  Font,
  ContentBlock,
  Sheet,
  Section,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { CanvasserContext } from 'canvassers/CanvasserContext/CanvasserContext'
import { formatDecimalCell } from 'utils/reporting'
import { metrics, buildLineChartData, buildScatterChartData } from './plotUtils'
import styles from './CanvasserReport.module.scss'
import CanvasserLinePlot from './CanvasserLinePlot/CanvasserLinePlot'
import CanvasserScatterPlot from './CanvasserScatterPlot/CanvasserScatterPlot'
import { buildTableData } from '../statUtils'

const CanvasserReport = () => {
  const { t } = useTranslation()
  const {
    currentCanvasser,
    reportRange,
    debouncedReportRange,
    setReportRange,
    canvasserStats,
    otherCanvasserStats,
    avgStats,
    canvasserStatsByDate,
    avgStatsByDate,
    metric,
    setMetric,
    canvasserReportsLoading,
  } = useContext(CanvasserContext)

  const options = metrics.map(metric => ({ ...metric, label: t(metric.label) }))

  const foundMetric = metrics.find(
    potentialMetric => potentialMetric.value === metric
  )
  const plotType = foundMetric?.type
  const metricIsPercent = foundMetric?.percentage
  const plotName = foundMetric?.label

  const tableData = useMemo(
    () => buildTableData(canvasserStats, avgStats),
    [canvasserStats, avgStats]
  )

  const chartMetric = useMemo(
    () => formatDecimalCell({ cellData: canvasserStats[foundMetric?.value] }),
    [foundMetric?.value, canvasserStats]
  )

  const ReportPlot = () => {
    let scatterData = {}
    switch (plotType) {
      case 'line':
        return (
          <CanvasserLinePlot
            xAxisKey="date"
            canvasserName={currentCanvasser.full_name}
            data={buildLineChartData(
              debouncedReportRange,
              canvasserStatsByDate,
              avgStatsByDate,
              foundMetric.value
            )}
          />
        )
      case 'scatter':
        scatterData = buildScatterChartData(
          canvasserStats,
          otherCanvasserStats,
          avgStats,
          foundMetric.value,
          foundMetric.xKey
        )
        return (
          <CanvasserScatterPlot
            xAxisKey={foundMetric.xKey}
            xAxisName={t(foundMetric.xName)}
            yAxisKey={foundMetric.value}
            canvasserName={currentCanvasser.full_name}
            otherData={scatterData.otherData}
            canvasserPoint={scatterData.canvasserPoint}
            xAvg={scatterData.xAvg}
            yAvg={scatterData.yAvg}
          />
        )
      default:
        return ''
    }
  }

  return (
    <Section>
      <ProgressBar show={canvasserReportsLoading} />
      <Sheet>
        <FieldBlock variant="medium">
          <DateField
            type="range"
            startDate={reportRange.startDate}
            endDate={reportRange.endDate}
            onChange={(startDate, endDate) => {
              setReportRange({
                startDate,
                endDate,
              })
            }}
          />
        </FieldBlock>
        <div className={styles['chart-section-container']}>
          <div className={styles['metric-container']}>
            <FieldBlock>
              <SelectField
                label={t('Metric')}
                value={metric}
                onSelect={setMetric}
                options={options}
              />
            </FieldBlock>
            <ContentBlock className={styles['metric-display']}>
              <Font.Display>
                {chartMetric}
                {metricIsPercent ? '%' : ''}
              </Font.Display>
            </ContentBlock>
          </div>
          <div className={styles['chart-container']}>
            <ContentBlock>
              <Font.Label>{plotName}</Font.Label>
            </ContentBlock>
            <ReportPlot />
          </div>
        </div>
        <DataTable plain baseId="canvasser-report-table">
          <TableHeader>
            <TableRow>
              <TableColumn />
              <TableColumn>{currentCanvasser.full_name}</TableColumn>
              <TableColumn>{t('Turf')}</TableColumn>
              <TableColumn>{t('Difference')}</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableData.map(({ metric, canvasser, average, type }) => {
              const canvasserData = formatDecimalCell({ cellData: canvasser })
              const averageData = formatDecimalCell({ cellData: average })
              const diff = formatDecimalCell({ cellData: canvasser - average })
              const diffPrefix = diff !== '-' && diff > 0 ? '+' : ''
              const postfix = type === 'percent' ? '%' : ''
              return (
                <TableRow key={metric}>
                  <TableColumn>{t(metric)}</TableColumn>
                  <TableColumn>
                    {canvasserData}
                    {canvasserData !== '-' && postfix}
                  </TableColumn>
                  <TableColumn>
                    {averageData}
                    {averageData !== '-' && postfix}
                  </TableColumn>
                  <TableColumn>
                    {diffPrefix}
                    {diff}
                    {diff !== '-' && postfix}
                  </TableColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </DataTable>
      </Sheet>
    </Section>
  )
}

export default CanvasserReport
