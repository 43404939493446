import {
  READY,
  ACTIVE,
  REVIEW,
  COMPLETE,
  CONNECTING,
} from 'constants/phoneBankSessions'
import { redirectToInbox } from 'qualityControl/inbox/utils'
import {
  useTimer,
  useRouteQueryParams,
  useReactRouter,
  useReduxAction,
} from 'hooks'
import { CallPanelControls, CallPanelHeader } from 'components/CallPanel'
import { useCallSessionContext } from 'phone_banks/components/CallSessionContext/CallSessionContext'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import { clearCurrent } from 'store/qcScans/actions'
import PhoneNumberInput from '../../PhoneNumberInput/PhoneNumberInput'
import SessionFooterCallButtonPortal from '../../SessionFooterCallButtonPortal/SessionFooterCallButtonPortal'
import styles from './ExternalCallPanel.module.scss'

const VALID_PHONE_LENGTH = 10

const ExternalCallPanel = ({
  createCallRecord,
  endCallRecord,
  isPortalAvailable,
  isEditing,
  phoneNumber,
  setPhoneNumber,
  currentCallId,
}) => {
  const { callStep, setCallStep } = useCallSessionContext()
  const showTimer = [ACTIVE, REVIEW, COMPLETE].includes(callStep)
  const { history } = useReactRouter()
  const [queryParams] = useRouteQueryParams()
  const scanId = +queryParams.scanId

  const reduxClearCurrent = useReduxAction(clearCurrent)

  const { inboxUser: user } = useInbox()
  const { inspectedUser } = useQualityControl()

  const staffId = user.id
  const isPhoneNumberValid = phoneNumber.length === VALID_PHONE_LENGTH

  const { restartTimer, stopTimer, resetTimer, secondsElapsed } = useTimer()

  const resetCall = () => {
    setCallStep(READY)
    resetTimer()
  }

  const endCall = () => {
    setCallStep(REVIEW)
    endCallRecord.makeRequest()
    stopTimer()
  }

  const handleStartCall = async () => {
    setCallStep(ACTIVE)
    createCallRecord.makeRequest(
      {
        voter_registration_scan_id: scanId,
        number: phoneNumber,
        external: true,
      },
      {
        fields: ['id'],
      }
    )
    restartTimer()
  }

  const endSession = () => {
    reduxClearCurrent()
    redirectToInbox(staffId, history, inspectedUser.id)
  }

  const getIsControlDisabled = () => {
    if (callStep === READY) {
      return !isPhoneNumberValid || isEditing || endCallRecord.isLoading
    }

    if (callStep === CONNECTING || callStep === ACTIVE) {
      return !currentCallId || endCallRecord.isLoading
    }

    return false
  }

  return (
    <div className={styles.callPanel}>
      <CallPanelHeader
        callStep={callStep}
        showTimer={showTimer}
        secondsElapsed={secondsElapsed}
      />
      {callStep === COMPLETE && !phoneNumber.length ? (
        'No phone number'
      ) : (
        <PhoneNumberInput
          disabled={callStep !== READY}
          phoneNumber={phoneNumber}
          hasError={!isPhoneNumberValid}
          onChange={setPhoneNumber}
          onEnterKeyPress={handleStartCall}
        />
      )}
      <SessionFooterCallButtonPortal isPortalAvailable={isPortalAvailable}>
        <CallPanelControls
          callStep={callStep}
          disabled={getIsControlDisabled()}
          startCall={handleStartCall}
          endCall={endCall}
          endSession={endSession}
          resetCall={resetCall}
        />
      </SessionFooterCallButtonPortal>
    </div>
  )
}

export default ExternalCallPanel
