import { connect } from 'react-redux'
import { MenuButton } from 'react-md'
import { useTranslation } from 'react-i18next'
import { useReactRouter, useRoutePathParams } from 'hooks'
import { getCurrentPacket } from 'store/qcScans/reducer'
import { READY_FOR_QC, IN_QC } from 'constants/qualityControl'
import { ButtonBlock } from 'components'
import { useCurrent } from 'contexts/index'
import { useQualityControl } from 'qualityControl/QualityControlContext'
import styles from './CallMenu.module.scss'

const CallMenu = ({ currentPacket }) => {
  const { t } = useTranslation()
  const {
    history: { push },
    location: { search },
  } = useReactRouter()
  const [{ type, flagId, originalType, packetId }] = useRoutePathParams()
  const { doesCurrentUserHavePermission } = useCurrent()
  const { inspectedUser } = useQualityControl()
  const canCreateNewCalls = doesCurrentUserHavePermission({
    resource: 'quality_control/phone_verification_call',
    ability: 'modify',
  })
  const goToSession = mode => {
    const baseRoute = `/quality_control/inbox/${currentPacket.id}/phone_verification_session/${mode}`
    let queryParameters = ''
    if (search) {
      queryParameters = `${search}`
    }
    if (type && flagId && originalType && packetId) {
      const sourceId = JSON.stringify([type, flagId, originalType, packetId])
      queryParameters += queryParameters
        ? `&source=flags&sourceId=${sourceId}`
        : `?source=flags&sourceId=${sourceId}`
    }
    const fullRoute = `${baseRoute}${queryParameters}`
    if (inspectedUser.id) {
      push(`${fullRoute}&inspectedUserId=${inspectedUser.id}`)
    } else {
      push(fullRoute)
    }
  }

  const callSessionDisabled =
    currentPacket.shift.status === READY_FOR_QC ||
    currentPacket.shift.status === IN_QC

  return (
    <ButtonBlock justify="center">
      <div className={styles.hintContainer}>
        <MenuButton
          raised
          secondary
          uppercase
          className="uppercase"
          menuItems={[
            {
              primaryText: t('Call in the browser'),
              onClick: () => goToSession('voip'),
            },
            {
              primaryText: t('Call using your own phone'),
              onClick: () => goToSession('external'),
            },
          ]}
          disabled={callSessionDisabled || !canCreateNewCalls}
        >
          {t('Begin Call Session')}
        </MenuButton>
        {callSessionDisabled && (
          <div className={styles.hint}>
            {t(
              'Visual QC must be completed on this packet before beginning phone verification'
            )}
          </div>
        )}
      </div>
    </ButtonBlock>
  )
}

export default connect(state => ({
  currentPacket: getCurrentPacket(state),
}))(CallMenu)
