import { useEffect, useState } from 'react'
import {
  ChipBlock,
  CampaignSelectField,
  OrganizationSelectField,
} from 'components'
import {
  Button,
  FieldBlock,
  Icon,
  SelectField,
  Grid,
  Section,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { interestLevelOptions } from 'constants/people'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import {
  destroyOrganizationMembership,
  fetchPerson,
  fetchPositionOptions,
  postOrganizationMembership,
  putPerson,
} from 'requests/people'
import useEvent from 'hooks/useEvent'
import { useCurrent } from 'contexts/index'
import PersonActivismOrganizer from '../PersonActivismOrganizer/PersonActivismOrganizer'
import PersonActivismTeams from '../PersonActivismTeams/PersonActivismTeams'
import { personFields } from './utils'
import PersonPosition from './PersonPosition'

const PersonActivism = ({ personId }) => {
  const [memberOrganizations, setMemberOrganizations] = useState([])
  const [organizationMembershipIds, setOrganizationMembershipIds] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [positionOptions, setPositionOptions] = useState([])

  const {
    tenantOptions: { uses_interest_level: usesInterestLevel },
    currentTurfActivismIssues: issues,
    currentTurfActivismSkills: skills,
  } = useCurrent()

  const {
    makeRequest: makePositionOptionsReq,
    isLoading: positionOptionsLoading,
  } = useRequest(fetchPositionOptions, {
    onSuccess: ({ positions }) => {
      setPositionOptions(
        positions.map(position => ({ label: position, value: position }))
      )
    },
  })

  const [person, setPerson] = useState(null)

  const handlePersonFetchSuccess = ({ person }) => {
    setPerson(person)
    setCampaigns(person.campaigns)
    setMemberOrganizations(
      person.organization_memberships.map(membership => membership.organization)
    )
    setOrganizationMembershipIds(
      person.organization_memberships.map(membership => ({
        id: membership.id,
        organization: membership.organization_id,
      }))
    )
  }

  const {
    makeRequest: personReq,
    isLoading: isPersonLoading,
    isRequestComplete: isPersonRequestComplete,
  } = useRequest(id => fetchPerson(id, personFields), {
    onSuccess: handlePersonFetchSuccess,
  })

  const refreshPerson = useEvent(async () => {
    const res = await fetchPerson(personId, personFields)
    handlePersonFetchSuccess(res)
  })

  useEffect(() => {
    personReq(personId, personFields)
  }, [personId])

  const { makeRequest: updatePersonReq, isLoading: isUpdatePersonLoading } =
    useRequest(putPerson, {
      onSuccess: refreshPerson,
    })

  const { makeRequest: updateCampaigns } = useRequest(
    campaigns =>
      putPerson(person.id, {
        campaign_ids: campaigns.map(campaign => campaign.id),
      }),
    {
      onSuccess: refreshPerson,
    }
  )

  const { t } = useTranslation()

  const { makeRequest: createMembership, isLoading: isCreatingMembership } =
    useRequest(postOrganizationMembership, {
      onSuccess: res => {
        const organizationMembership = res['people/organization_membership']
        setOrganizationMembershipIds(oldMembershipIds => [
          ...oldMembershipIds,
          {
            id: organizationMembership.id,
            organization: organizationMembership.organization_id,
          },
        ])
      },
    })

  const { makeRequest: deleteMembership, isLoading: isDeletingMembership } =
    useRequest(destroyOrganizationMembership)

  const handleSelectOrganization = organizations => {
    const selectedOrgIds = organizations.map(org => org.id)
    const personOrgIds = organizationMembershipIds.map(
      membership => membership.organization
    )

    if (selectedOrgIds.length < personOrgIds.length) {
      deleteMembership(
        person.id,
        organizationMembershipIds.find(
          m => !selectedOrgIds.includes(m.organization)
        ).id
      )
        .then(setMemberOrganizations(organizations))
        .then(
          setOrganizationMembershipIds(oldOrganizationMemberIds =>
            oldOrganizationMemberIds.filter(member =>
              selectedOrgIds.includes(member.organization)
            )
          )
        )
    } else {
      const orgIdToCreate = selectedOrgIds.find(
        selectedOrgId => !personOrgIds.includes(selectedOrgId)
      )
      createMembership(
        person.id,
        {
          organization_id: orgIdToCreate,
        },
        {
          fields: ['id', 'organization_id'],
        }
      ).then(setMemberOrganizations(organizations))
    }
  }

  useEffect(() => {
    makePositionOptionsReq()
  }, [])

  if (!isPersonRequestComplete || !person) return <ProgressBar show />

  return (
    <Grid>
      <Section label={t('Membership')}>
        <PersonActivismOrganizer person={person} />
        <PersonActivismTeams person={person} />
      </Section>
      <Section label={t('Organizations')}>
        <FieldBlock>
          <OrganizationSelectField
            label={t('Organizations')}
            loading={
              isCreatingMembership || isDeletingMembership || isPersonLoading
            }
            organizations={memberOrganizations}
            isMulti
            onSelect={handleSelectOrganization}
          />
        </FieldBlock>
      </Section>
      {usesInterestLevel ? (
        <Section label={t('Interest Level')}>
          <FieldBlock>
            <SelectField
              id="interest-level"
              label={t('Interest Level')}
              value={person.interest_level || ''}
              options={interestLevelOptions}
              loading={isUpdatePersonLoading}
              onSelect={interest_level =>
                updatePersonReq(person.id, {
                  interest_level,
                })
              }
            />
            {person.interest_level && (
              <Button
                onClick={() =>
                  updatePersonReq(person.id, {
                    interest_level: null,
                  })
                }
              >
                <Icon.Times />
              </Button>
            )}
          </FieldBlock>
        </Section>
      ) : null}
      <Section label={t('Campaign affiliations')}>
        <FieldBlock>
          <CampaignSelectField
            label={t('Campaign affiliations')}
            campaigns={campaigns}
            isMulti
            onSelect={updateCampaigns}
          />
        </FieldBlock>
      </Section>
      {issues ? (
        <ChipBlock
          label={t('Activism')}
          chipType="issue"
          identifier={person.id}
          choices={issues.filter(issue => !person.issues.includes(issue))}
          selectedChips={person.issues}
          removeChip={issue =>
            updatePersonReq(person.id, {
              issues: person.issues.filter(i => i !== issue),
            })
          }
          addChip={issue =>
            updatePersonReq(person.id, {
              issues: person.issues.concat(issue),
            })
          }
        />
      ) : null}
      {skills ? (
        <ChipBlock
          label={t('Skills')}
          chipType="skill"
          identifier={person.id}
          choices={skills.filter(skill => !person.skills.includes(skill))}
          selectedChips={person.skills}
          removeChip={skill =>
            updatePersonReq(person.id, {
              skills: person.skills.filter(s => s !== skill),
            })
          }
          addChip={skill =>
            updatePersonReq(person.id, {
              skills: person.skills.concat(skill),
            })
          }
        />
      ) : null}
      <PersonPosition
        person={person}
        positionOptions={positionOptions}
        updatePersonReq={updatePersonReq}
        isLoading={isUpdatePersonLoading || positionOptionsLoading}
      />
    </Grid>
  )
}

export default PersonActivism
