import { useState, useEffect, useRef } from 'react'
import { Device } from 'twilio-client'
import { noop } from 'lodash/util'
import { fetchJSON } from 'utils/req'

const fetchTwilioToken = () =>
  fetchJSON(`/api/v1/twilio/token`, 'POST', null, {
    useJwt: true,
  })

const useTwilioCall = ({
  setupOptions,
  onConnect = noop,
  onDisconnect = noop,
  onError = noop,
}) => {
  const dialerRef = useRef(null)
  const [setupError, setSetupError] = useState(null)

  const fetchToken = async () => {
    const response = await fetchTwilioToken()
    return response.token
  }

  const destroyDialer = () => {
    dialerRef.current && dialerRef.current.destroy()
  }

  useEffect(() => destroyDialer, [])

  const setupDialer = async () => {
    const token = await fetchToken()

    return new Promise(resolve => {
      if (dialerRef.current) destroyDialer()
      dialerRef.current = new Device()
      const dialer = dialerRef.current

      try {
        dialer.setup(token, setupOptions)
        dialer.on('connect', onConnect)
        dialer.on('disconnect', onDisconnect)
        dialer.on('error', onError)
      } catch (err) {
        const error = {
          type: 'SET_UP',
          message: 'The dialer could not complete setup.',
        }
        onError(error)
        setSetupError(error)
        resolve(false)
      }

      dialer.on('ready', () => {
        setSetupError(null)
        resolve(true)
      })
    })
  }

  const startCall = async params => {
    const isReady = await setupDialer()
    if (!isReady) return null

    return dialerRef.current.connect(params)
  }

  const endCall = () => {
    dialerRef.current.disconnectAll()
    destroyDialer()
  }

  return { setupError, startCall, endCall }
}

export default useTwilioCall
