import { useRequest } from 'hooks/useRequest'
import { defaultTo, groupBy } from 'lodash'
import { createContext, useMemo, useState } from 'react'
import { PERMISSION_OPTIONS } from 'constants/roles'
import {
  fetchRoles,
  postRole,
  putRole,
  deleteRole as deleteRoleRequest,
} from 'requests/roles'

export const RoleContext = createContext()

const buildSortedRoles = (roles, parents = [], roleIds = []) =>
  roles
    .filter(role =>
      parents.length > 0
        ? parents.includes(role.parent_id)
        : !roleIds.includes(role.parent_id)
    )
    .map(role => [role, buildSortedRoles(roles, [role.id])])
    .flat(2)

const RoleContextProvider = ({ children }) => {
  const [roles, setRoles] = useState([])
  const [currentRoleId, setCurrentRoleId] = useState()
  const [isRoleModified, setRoleModified] = useState(false)
  const roleIds = roles.map(role => role.id)
  const topLevelRoles = roles.filter(role => !roleIds.includes(role.parent_id))
  const topLevelRoleIds = topLevelRoles.map(role => role.id)
  const sortedRoles = buildSortedRoles(roles, [], roleIds)
  const currentRole = useMemo(() =>
    defaultTo(
      defaultTo(
        roles.find(role => role.id === currentRoleId),
        {
          permissions: [],
          users: [],
        }
      ),
      [currentRoleId, roles]
    )
  )
  const currentRoleParent = useMemo(
    () =>
      defaultTo(
        roles.find(role => role.id === currentRole.parent_id),
        {
          permissions: [],
          users: [],
        }
      ),
    [currentRoleId, roles]
  )
  const currentRoleUsers = useMemo(
    () =>
      defaultTo(
        currentRole.users.filter(user => !user.disabled_at),
        []
      ),
    [currentRole.users]
  )
  const currentRolePermissions = useMemo(
    () =>
      defaultTo(
        currentRole.permissions.flatMap(({ abilities, resource }) =>
          abilities.map(ability => `${resource}.${ability}`)
        ),
        []
      ),
    [currentRole.permissions]
  )

  const { makeRequest: getRoles, isLoading: rolesAreLoading } = useRequest(
    () =>
      fetchRoles({
        fields: [
          'id',
          'name',
          'parent_id',
          'depth',
          'permissions',
          'description',
          'admin',
          'needs_training',
          { users: ['id', 'name', 'disabled_at'] },
          { children: ['id'] },
        ],
      }),
    {
      onSuccess: ({ roles }) => setRoles(roles),
    }
  )

  const updateRole = async data => {
    await putRole(currentRoleId, data)
    await getRoles()
    return { id: currentRoleId }
  }

  const createRole = async data => {
    const newRole = await postRole(data)
    await getRoles()
    return newRole.role
  }

  const deleteRole = async () => {
    await deleteRoleRequest(currentRoleId)
    await getRoles()
    return {}
  }

  const updateRoleAbilities = (toggles, needs_training) => {
    const permissions = Object.entries(groupBy(toggles, t => t.permission)).map(
      ([permission, abilities]) => ({
        resource: permission,
        abilities: abilities
          .map(a => (a.checked ? a.value : null))
          .filter(Boolean),
      })
    )
    return updateRole({
      permissions,
      needs_training,
      parent_id: currentRole.parent_id,
    })
  }

  const getTenantPermissions = tenantBlocks =>
    PERMISSION_OPTIONS.filter(
      permission =>
        permission.all ||
        defaultTo(permission.blocks, []).some(block =>
          tenantBlocks.map(block => block.name).includes(block)
        )
    )

  return (
    <RoleContext.Provider
      value={{
        roles,
        setRoles,
        currentRoleId,
        setCurrentRoleId,
        isRoleModified,
        setRoleModified,
        topLevelRoleIds,
        sortedRoles,
        currentRole,
        currentRoleParent,
        currentRoleUsers,
        currentRolePermissions,
        getRoles,
        rolesAreLoading,
        updateRole,
        createRole,
        deleteRole,
        updateRoleAbilities,
        getTenantPermissions,
      }}
    >
      {children}
    </RoleContext.Provider>
  )
}

export default RoleContextProvider
