import { appsignal } from 'appsignal'

export const logError = error => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error)
  } else {
    appsignal.sendError(error)
  }
}

const sendOrLogSpan = span => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(span.serialize())
  } else {
    appsignal.send(span)
  }
}

export const logErrorResponse = (
  useRequestError,
  useRequestArgs,
  errorMessage
) => {
  const span = appsignal
    .createSpan()
    .setParams({ response: useRequestError.json, arguments: useRequestArgs })

  const responseError = new Error(errorMessage)
  span.setError(responseError)

  sendOrLogSpan(span)
}
