import {
  Modal,
  Button,
  ButtonBlock,
  ContentBlock,
  Font,
  Icon,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import {
  useReactRouter,
  useReduxAction,
  useRequest,
  useRouteQueryParams,
} from 'hooks'
import { useInbox } from 'qualityControl/inbox/InboxContext'
import { COMPLETED, COMPLETED_TAB } from 'constants/qualityControl'
import { advanceShiftStatus } from 'requests/qualityControl'
import { useParams } from 'react-router'
import nanoid from 'nanoid'
import { clearCurrent } from 'store/qcScans/actions'

const CompleteQCModal = ({
  show,
  isSubmittable,
  isEveryScanCalled,
  currentPacket,
  closeIsEveryScanCalled,
  setIsDeliveryDeferred,
}) => {
  const { t } = useTranslation()

  const { history } = useReactRouter()
  const { mode } = useParams()
  const [{ inspectedUserId }] = useRouteQueryParams()
  const { setLastActiveInboxTab } = useInbox()

  const reduxClearCurrent = useReduxAction(clearCurrent)

  const { makeRequest: completePacket } = useRequest(
    packet => advanceShiftStatus(packet.shift_id, COMPLETED),
    {
      onSuccess: () => {
        setLastActiveInboxTab(COMPLETED_TAB)
        inspectedUserId
          ? history.push(
              `/quality_control/manage/assign/${inspectedUserId}/inbox`
            )
          : history.push(`/quality_control/inbox`)
      },
    }
  )

  const handleCloseModal = () => {
    closeIsEveryScanCalled()
    isSubmittable && setIsDeliveryDeferred(true)
  }

  const getTitle = () => {
    if (isSubmittable) {
      return t('Complete QC')
    }
    return t('All callable scans attempted')
  }

  const getBodyCopy = () => {
    if (isSubmittable) {
      return t('This packet meets the phone verification criteria.')
    }
    return t(
      `All the callable scans in this packet have been attempted but still haven’t met the verification threshold. \
    Would you like to end the session and come back later or start the next round of calls right away?`
    )
  }

  const continueCalling = () => {
    setIsDeliveryDeferred(true)
  }

  const restartPacket = () =>
    history.push(
      `/quality_control/inbox/${currentPacket.id}/phone_verification_session/${mode}?sessionId=${nanoid(4)}`
    )

  const endSession = () => {
    reduxClearCurrent()
    history.push(
      `/quality_control/inbox/${currentPacket.id}/phone_verification`
    )
  }

  return (
    <Modal id="complete-qc-modal" title={getTitle()} isOpen={show}>
      <Modal.Body>
        <ContentBlock>
          <Font.Copy>{getBodyCopy()}</Font.Copy>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          {isSubmittable && !isEveryScanCalled && (
            <>
              <Button.Secondary onClick={continueCalling}>
                <span>{t('Keep calling')}</span>
                <Icon.RotateRight />
              </Button.Secondary>
              <Button.Accent onClick={() => completePacket(currentPacket)}>
                <span>{t('Submit phone verification')}</span>
                <Icon.PaperPlane />
              </Button.Accent>
            </>
          )}
          {!isSubmittable && isEveryScanCalled && (
            <>
              <Button.Secondary onClick={handleCloseModal}>
                {t('Edit responses')}
              </Button.Secondary>
              <Button.Secondary onClick={restartPacket}>
                {t('Start next round')}
              </Button.Secondary>
              <Button.Accent onClick={endSession}>
                {t('End session')}
              </Button.Accent>
            </>
          )}
          {isSubmittable && isEveryScanCalled && (
            <>
              <Button.Secondary onClick={handleCloseModal}>
                {t('Edit responses')}
              </Button.Secondary>
              <Button.Accent onClick={() => completePacket(currentPacket)}>
                <span>{t('Submit phone verification')}</span>
                <Icon.PaperPlane />
              </Button.Accent>
            </>
          )}
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default CompleteQCModal
