import moment from 'moment'
import { DetailItem } from 'components'
import { dateTimeFormat } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import { formatDisplayName, formatPhoneNumber } from 'utils/formatting'

const CallHistoryItem = ({
  callTime,
  caller,
  result,
  notes,
  call,
  failedCalls = [],
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <>
      <DetailItem label={t('Result')}>
        <strong>{result}</strong>
      </DetailItem>
      <DetailItem label={t('Call time')}>
        {moment
          .parseZone(call.created_at)
          .local()
          .format('MMM D, YYYY, h:mm A')}
      </DetailItem>
      <DetailItem label={t('Called by')}>
        {formatDisplayName(caller)}
      </DetailItem>
      <DetailItem label={t('Contacted')}>{`${
        props['contacted?'] ? t('Yes') : t('No')
      }`}</DetailItem>
      <DetailItem label={t('Notes')}>{notes}</DetailItem>
      <DetailItem label={t('Number Dialed')}>
        {formatPhoneNumber(call.number)}
      </DetailItem>
      {!!failedCalls.length && (
        <DetailItem label={t('Other Numbers Attempted')} />
      )}
      {failedCalls.map(failedCall => (
        <DetailItem
          label={moment(failedCall.created_at).format(dateTimeFormat)}
        >
          {formatPhoneNumber(failedCall.number)}
        </DetailItem>
      ))}
    </>
  )
}

export default CallHistoryItem
