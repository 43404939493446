import { useReducer, useState, useEffect } from 'react'
import {
  Section,
  DetailItem,
  ChipBlock,
  SectionLabel,
  CardError,
} from 'components'
import {
  SelectField,
  Button,
  ButtonBlock,
  Icon,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { putPerson } from 'requests/people'
import { useRequest } from 'hooks/useRequest'
import { useCurrent } from 'contexts/index'

const PersonLanguages = ({ person }) => {
  const { currentTurfActivismLanguages: languages } = useCurrent()
  const [isEditing, toggleIsEditing] = useReducer(
    isEditing => !isEditing,
    false
  )

  const { t } = useTranslation()

  const [primaryLanguage, setPrimaryLanguage] = useState()
  const [clearPrimaryLanguage, setClearPrimaryLanguage] = useState(false)
  const [otherLanguages, setOtherLanguages] = useState([])

  useEffect(() => {
    setPrimaryLanguage(person.primary_language)
    setOtherLanguages(person.languages)
  }, [person])

  const { makeRequest: updatePersonReq, errorMsg } = useRequest(putPerson, {
    onSuccess: response => {
      setPrimaryLanguage(response.person.primary_language)
      setOtherLanguages(response.person.languages || [])
    },
  })

  const updatePerson = async attrs => {
    updatePersonReq(person.id, attrs, {
      fields: ['primary_language', 'languages'],
    })
  }

  const save = async () => {
    await updatePerson({
      primary_language: clearPrimaryLanguage ? null : primaryLanguage,
      languages: otherLanguages,
    })
    toggleIsEditing()
  }

  const cancel = () => {
    updatePerson({
      primary_language: person.primary_language,
      languages: person.languages,
    })
    toggleIsEditing()
  }

  const fixedLanguageOptions =
    primaryLanguage && !languages.includes(primaryLanguage)
      ? languages.concat(primaryLanguage)
      : languages

  return (
    <section>
      <ButtonBlock>
        <SectionLabel primary>{t('Languages Spoken')}</SectionLabel>
        {isEditing ? (
          <ButtonBlock justify="right">
            <Button.Secondary onClick={cancel}>{t('Cancel')}</Button.Secondary>
            <Button onClick={save}>{t('Save')}</Button>
          </ButtonBlock>
        ) : (
          <Button.Secondary
            aria-label={t('Edit languages')}
            onClick={toggleIsEditing}
          >
            <Icon.Pencil />
          </Button.Secondary>
        )}
      </ButtonBlock>
      <CardError
        hide={!errorMsg}
        message={t(
          'An internal error occurred while trying to update your languages'
        )}
      />
      <Section
        empty={!primaryLanguage && !otherLanguages.length && !isEditing}
        emptyMessage={t('No languages set')}
      >
        <DetailItem
          label={t('Primary Language')}
          hide={!primaryLanguage && !isEditing}
        >
          {isEditing ? (
            <SelectField
              id="primary_language"
              placeholder={t('Select language')}
              value={primaryLanguage || ''}
              options={fixedLanguageOptions.map(language => ({
                value: language,
                label: language,
              }))}
              onSelect={val => {
                setPrimaryLanguage(val)
                setClearPrimaryLanguage(false)
              }}
              onClear={() => setClearPrimaryLanguage(true)}
              clearable
            />
          ) : (
            <span className="capitalize">{primaryLanguage}</span>
          )}
        </DetailItem>
        {isEditing ? (
          <ChipBlock
            label={t('Other languages spoken')}
            chipType="language"
            identifier={person.id}
            noOptionsMessage={t('All languages selected')}
            choices={languages.filter(
              language =>
                !otherLanguages.includes(language) &&
                language !== primaryLanguage
            )}
            selectedChips={otherLanguages}
            removeChip={language => {
              setOtherLanguages(oldLanguages =>
                oldLanguages.filter(oldLanguage => oldLanguage !== language)
              )
            }}
            addChip={language =>
              setOtherLanguages(oldLanguages => oldLanguages.concat([language]))
            }
          />
        ) : (
          <DetailItem
            label={t('Other Languages')}
            hide={!otherLanguages.length && !isEditing}
          >
            <span className="capitalize">{otherLanguages.join(', ')}</span>
          </DetailItem>
        )}
      </Section>
    </section>
  )
}
export default PersonLanguages
