import { TextField } from 'react-md'
import { useTranslation } from 'react-i18next'
import { maskPhone, unmaskPhone } from 'utils/inputMasks'

const VALID_PHONE_LENGTH = 10

const PhoneNumberInput = ({
  phoneNumber,
  onChange,
  hasError,
  onEnterKeyPress,
  disabled,
}) => {
  const { t } = useTranslation()
  const errorText = phoneNumber
    ? t('Number must contain 10 digits')
    : t(
        'No phone number detected. Enter number as displayed written in the scan'
      )

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !hasError) {
      onEnterKeyPress()
    }
  }

  return (
    <TextField
      type="tel"
      id={phoneNumber}
      label={t('Phone number')}
      value={maskPhone(phoneNumber)}
      error={hasError}
      errorText={errorText}
      onChange={val => {
        onChange(unmaskPhone(val).substring(0, VALID_PHONE_LENGTH))
      }}
      onKeyPress={handleKeyPress}
      disabled={disabled}
    />
  )
}

export default PhoneNumberInput
