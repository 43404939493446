/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import PropTypes from 'prop-types'
import { BoxLegendSvg } from '@nivo/legends'
import { cloneDeep } from 'lodash'
import { ChartColors as CHART_COLORS } from 'components/uiKit/Colors'

import EmptyMessage from '../charts/EmptyMessage'

const InteractiveBarLegend = ({ height, legends, width }) => (
  <>
    {legends.map(legend => (
      <BoxLegendSvg
        key={JSON.stringify(legend.data.map(({ id }) => id))}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ))}
  </>
)

const BarChart = ({
  data,
  keys,
  indexBy,
  groupMode,
  xAxisLabel,
  yAxisLabel,
  enableLabel,
  legendWidth,
  margin,
  hasToolbar,
  emptyMessage,
  interactiveLegend,
  layout,
  hideAxisBottom,
}) => {
  const [hiddenIds, setHiddenIds] = useState([])
  const toggleHiddenId = d => {
    if (hiddenIds && hiddenIds.includes(d.id)) {
      setHiddenIds(hiddenIds.filter(id => id !== d.id))
    } else {
      setHiddenIds([...hiddenIds, d.id])
    }
  }

  const chartData = useMemo(() => {
    if (!interactiveLegend) return data
    if (!hiddenIds.length) return data

    const clonedData = cloneDeep(data)

    hiddenIds.forEach(id => {
      clonedData.forEach(data => {
        delete data[id]
      })
    })

    return clonedData
  }, [interactiveLegend, hiddenIds, data])

  const chartColors = useMemo(
    () =>
      keys.reduce((acc, key, index) => {
        const isPresent = data.reduce(
          (present, set) => (set[key] && set[key] > 0) || present,
          false
        )
        if (isPresent) {
          return { ...acc, [key]: CHART_COLORS[index] }
        }
        return acc
      }, {}),
    [data, keys]
  )

  const getColor = bar => chartColors[bar.id]

  return data && data.length ? (
    <ResponsiveBar
      data={chartData}
      keys={keys}
      indexBy={indexBy}
      groupMode={groupMode}
      layout={layout}
      margin={
        hasToolbar
          ? {
              top: 20,
              right: 50,
              bottom: interactiveLegend ? 160 : 120,
              left: 70,
            }
          : margin
      }
      padding={0.2}
      innerPadding={1}
      colors={getColor}
      axisBottom={
        !hideAxisBottom
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: xAxisLabel || null,
              legendPosition: 'middle',
              legendOffset: 32,
            }
          : null
      }
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        legend: yAxisLabel || null,
        legendPosition: 'middle',
        legendOffset: -60,
        format: v => {
          if (v.length > 6) {
            return `${v.substring(0, 5)}...`
          }
          return v
        },
      }}
      layers={[
        'grid',
        'axes',
        'bars',
        'markers',
        interactiveLegend && InteractiveBarLegend,
      ]}
      legends={[
        {
          dataFrom: 'keys',
          data:
            interactiveLegend &&
            keys.map(id => {
              const isHidden = hiddenIds.find(hiddenId => hiddenId === id)
              return {
                color: isHidden ? 'grey' : chartColors[id],
                id,
                label: id,
              }
            }),
          anchor: 'bottom',
          direction: 'row',
          translateY: 60,
          itemsSpacing: 2,
          itemWidth: legendWidth,
          itemHeight: 20,
          itemOpacity: 0.85,
          symbolSize: 10,
          onClick: interactiveLegend ? toggleHiddenId : null,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate
      motionStiffness={90}
      motionDamping={15}
      enableLabel={enableLabel || false}
    />
  ) : (
    <EmptyMessage message={emptyMessage} />
  )
}

BarChart.propTypes = {
  data: PropTypes.array,
  keys: PropTypes.array,
  groupMode: PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  indexBy: PropTypes.string.isRequired,
  legendWidth: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  hasToolbar: PropTypes.bool,
}

BarChart.defaultProps = {
  data: [],
  keys: [],
  groupMode: 'grouped',
  xAxisLabel: null,
  yAxisLabel: null,
  hasToolbar: false,
  legendWidth: 100,
  margin: { top: 50, right: 50, bottom: 80, left: 70 },
}

export default BarChart
