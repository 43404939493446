import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { ButtonBlock, TextBlock, Font } from '@politechdev/blocks-design-system'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useRequest, useRoutePathParams } from 'hooks'
import { destroyOrganizationMembership, fetchPeople } from 'requests/people'
import { useState } from 'react'
import { formatErrorMessage } from 'utils/formatting'
import AddOrganizationMemberModal from '../AddOrganizationMemberModal/AddOrganizationMemberModal'
import tableConfig from './tableConfig'
import styles from './OrganizationMemberTable.module.scss'

const OrganizationMemberTable = () => {
  const { t } = useTranslation()
  const { renderTable, refreshPage } = useAutoTable()
  const [{ id: currentOrganizationId }] = useRoutePathParams()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canDeleteOrganizationMembership = doesCurrentUserHavePermission({
    resource: 'organization_membership',
    ability: 'remove',
  })

  const [members, setMembers] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const {
    makeRequest: deleteRequest,
    isLoading,
    errors,
  } = useRequest(destroyOrganizationMembership, {
    onSuccess: () => {
      refreshPage()
    },
  })
  const errorMsg = formatErrorMessage(errors)

  const { makeRequest: fetchAction } = useRequest(
    params =>
      fetchPeople({
        current_page: params?.page,
        per: params?.pageSize,
        sort_attr: params?.sortColumn || 'full_name',
        sort_dir: params?.sortOrder || 'asc',
        filters: {
          rules: [
            ...(params?.filterRules ? params.filterRules : []),
            {
              column: 'organizations',
              operator: 'is',
              param: currentOrganizationId,
            },
          ],
          conjunction: params?.filterConjunction,
        },
        fields: [
          'id',
          'name',
          'first_name',
          'last_name',
          'primary_phone_number',
          'primary_email_address',
          {
            residential_address: [
              'line_one',
              'line_two',
              'city',
              'state',
              'zipcode',
            ],
          },
          {
            contact_methods: ['contact_type'],
          },
          {
            organization_memberships: ['id', 'organization_id'],
          },
        ],
      }),
    {
      onSuccess: ({ people, meta }) => {
        setMembers(people)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const handleDelete = rowData => {
    const membership = (rowData?.organization_memberships || []).find(
      membership => membership.organization_id === +currentOrganizationId
    )

    if (membership) {
      deleteRequest(rowData.id, membership.id)
    }
  }

  const columns = [
    {
      actions: [
        createRowAction(
          t('Delete'),
          handleDelete,
          canDeleteOrganizationMembership
        ),
      ],
      listInline: true,
    },
    ...tableConfig.columns,
  ]

  return (
    <>
      <div className={styles.header}>
        <TextBlock>
          <Font.Label variant="section">{t('Members')}</Font.Label>
        </TextBlock>
        <ButtonBlock justify="right">
          <AddOrganizationMemberModal members={members} />
        </ButtonBlock>
      </div>
      {renderTable({
        data: members,
        loading: isLoading,
        error: errorMsg,
        totalRecords,
        columns,
        fetchAction,
      })}
    </>
  )
}

export default () => (
  <AutoTableProvider
    initialTableId={tableConfig.label}
    enablePagination
    enableSort
    enableFilters
    showFilterEditor
    showDownloadButton
    defaultFilters={tableConfig.defaultFilters}
  >
    <OrganizationMemberTable />
  </AutoTableProvider>
)
