import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'
import {
  CardError,
  OrganizationSelectField,
  CampaignSelectField,
  UserSelectField,
  PersonSelectField,
} from 'components'
import {
  SelectField,
  TextField,
  ProgressBar,
  Button,
  ButtonBlock,
  FieldBlock,
  Switch,
} from '@politechdev/blocks-design-system'
import { useCurrent, useForm } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import { fetchTeams, postTeam, putTeam } from 'requests/teams'
import { buildForm, buildRequest } from './utils'
import styles from './TeamForm.module.scss'

const TeamForm = ({ team }) => {
  const isEditForm = !!team
  const formAction = isEditForm ? data => putTeam(team.id, data) : postTeam
  const { t } = useTranslation()
  const { history, location } = useReactRouter()
  const { teamTypeOptions } = useCurrent()

  const { formData, setField, setFormData } = useForm()
  const { makeRequest, isLoading, hasErrors, isRequestComplete, response } =
    useRequest(formAction)

  const {
    makeRequest: fetchTeamsReq,
    isLoading: isFetchTeamsLoading,
    response: teamsResponse,
  } = useRequest(fetchTeams)

  const [debouncedfetchTeamsReq] = useDebouncedCallback(fetchTeamsReq, 500)

  const isTeamNameTaken = useMemo(
    () => teamsResponse && teamsResponse.meta.total_count > 0,
    [teamsResponse]
  )

  useEffect(() => {
    team ? setFormData(buildForm(team)) : setFormData({ active: true })
  }, [])

  useEffect(() => {
    const turf_id = formData.organizer?.turf?.id ?? formData.organizer?.turf_id
    if (turf_id && formData.name) {
      const rules = [
        {
          column: 'name',
          operator: 'is',
          param: formData.name,
        },
        {
          column: 'turf',
          operator: 'is',
          param: turf_id,
        },
      ]

      debouncedfetchTeamsReq({
        fields: ['name'],
        indexed: true,
        filters: {
          rules: team
            ? [
                ...rules,
                {
                  column: 'id',
                  operator: 'is',
                  param: team.id,
                  invert: true,
                },
              ]
            : rules,
        },
      })
    }
  }, [formData.organizer, formData.name])

  const isFormValid = () =>
    !isEmpty(formData.name) && !isEmpty(formData.organizer) && !isTeamNameTaken

  useEffect(() => {
    if (hasErrors || !isRequestComplete) return
    history.push(location.state?.returnPath || '/organize/teams')
  }, [isRequestComplete, hasErrors, response])

  const submitForm = e => {
    e.preventDefault()
    makeRequest(buildRequest(formData))
  }

  return (
    <>
      <ProgressBar show={isLoading} />
      <CardError
        hide={!hasErrors}
        message={t(
          `There was an error ${isEditForm ? 'updating' : 'creating'} this team`
        )}
      />
      <form onSubmit={submitForm}>
        <FieldBlock>
          <TextField
            label={t('Team name')}
            id="name"
            value={formData.name || ''}
            onChange={val => setField(val, 'name')}
            required
            error={isTeamNameTaken}
            errorMessage="Name has already been taken"
            loading={isFetchTeamsLoading}
          />
        </FieldBlock>
        <FieldBlock>
          <UserSelectField
            id="user"
            label={t('Organizer')}
            user={formData.organizer}
            onSelect={val => setField(val, 'organizer')}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <PersonSelectField
            id="leader"
            label={t('Leader')}
            person={formData.leader}
            onSelect={val => setField(val, 'leader')}
          />
        </FieldBlock>
        <FieldBlock>
          <OrganizationSelectField
            isMulti
            organizations={formData.organizations}
            onSelect={val => setField(val, 'organizations')}
          />
        </FieldBlock>
        <FieldBlock>
          <CampaignSelectField
            campaigns={formData.campaigns || []}
            isMulti
            filters={[
              {
                column: 'start_date',
                operator: 'before',
                param: moment().add(1, 'days').format('YYYY-MM-DD'),
              },
              {
                column: 'end_date',
                operator: 'after',
                param: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              },
            ]}
            onSelect={val => setField(val, 'campaigns')}
          />
        </FieldBlock>
        {!!teamTypeOptions.length && (
          <FieldBlock>
            <SelectField
              id="type"
              label={t('Type')}
              value={formData.type}
              options={teamTypeOptions}
              onSelect={val => setField(val, 'type')}
            />
          </FieldBlock>
        )}
        <FieldBlock>
          <Switch
            className={styles.switch}
            id="active"
            name="active"
            label={t('Active')}
            onChange={val => setField(val, 'active')}
            value={formData.active}
          />
        </FieldBlock>
        <ButtonBlock>
          <Button.Accent
            raised
            accent
            type="submit"
            disabled={!isFormValid() || isLoading}
          >
            {t(isEditForm ? 'Save Team' : 'Create Team')}
          </Button.Accent>
        </ButtonBlock>
      </form>
    </>
  )
}

export default TeamForm
