import { useState } from 'react'
import {
  FieldBlock,
  Button,
  SelectField,
  TextField,
  ButtonBlock,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { ZipCodeField } from 'components'
import {
  fetchAddressSuggestions,
  fetchAddressOptions,
} from 'components/AddressValidator/smartyStreetsApiHelpers'
import useEvent from 'hooks/useEvent'
import { useRequest } from 'hooks/useRequest'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce/lib'
import countiesByState from 'utils/counties.json'
import { US_STATES } from 'utils/constants'
import { get } from 'lodash'

const stateOptions = Object.keys(US_STATES).map(key => ({
  label: US_STATES[key],
  value: key,
}))

const smartyStreetsResponseToLabel = suggestion => {
  let whiteSpace = ''
  if (suggestion.secondary) {
    if (suggestion.entries > 1) {
      suggestion.secondary += ` (${suggestion.entries} entries)`
    }
    whiteSpace = ' '
  }
  return `${suggestion.street_line + whiteSpace + suggestion.secondary} ${
    suggestion.city
  }, ${suggestion.state} ${suggestion.zipcode}`
}

const PresenceWrapper = ({ show, children, onChange, value }) => {
  const { t } = useTranslation()
  if (!show) return children
  return (
    <div>
      {children}
      <Checkbox
        label={t('Missing/invalid in registration form')}
        onChange={onChange}
        checked={value}
      />
    </div>
  )
}

const AddressLookup = ({
  prefix,
  getField,
  setField,
  fieldErrors,
  hasCounty,
  showPresenceCheckbox,
}) => {
  const { t } = useTranslation()

  const [showFields, setShowFields] = useState(
    getField(`meta.${prefix}_has_value`)
  )
  const [suggestions, setSuggestions] = useState([])

  const toggleAddressMode = () => {
    if (showFields) {
      setField(`${prefix}_street`)('')
      setField(`${prefix}_street_two`)('')
      setField(`${prefix}_city`)('')
      setField(`${prefix}_state`)('TX')
      setField(`${prefix}_zipcode`)('')
      setField(`${prefix}_county`)('')
      setField(`meta.${prefix}_has_value`)(false)
    } else {
      setField(`meta.${prefix}_has_value`)(true)
    }
    setShowFields(current => !current)
  }

  const { makeRequest, isLoading } = useRequest(fetchAddressSuggestions, {
    onSuccess: newSuggestions => {
      setSuggestions(
        newSuggestions.map(suggestion => ({
          ...suggestion,
          label: smartyStreetsResponseToLabel(suggestion),
        }))
      )
    },
  })

  const [debouncedSearch] = useDebouncedCallback(makeRequest, 500, {
    maxWait: 700,
  })

  const handleStreetAddressChange = useEvent(value => {
    if (!value) return

    debouncedSearch({
      fields: {
        search: value,
        include_only_states: 'TX',
      },
    })
  })

  const getCounty = useRequest(
    ({ street_line, city, state, zipcode }) =>
      fetchAddressOptions({
        fields: {
          street: street_line,
          city,
          state,
          zipcode,
        },
      }),
    {
      onSuccess: response => {
        const match = response?.candidates[0]
        if (match) setField(`${prefix}_county`)(match.metadata.county_name)
      },
    }
  )

  const handleStreetAddressSelect = useEvent(async value => {
    if (!value) {
      setSuggestions([])
      return
    }
    setField(`${prefix}_street`)(value.street_line)
    setField(`${prefix}_street_two`)(value.secondary)
    setField(`${prefix}_city`)(value.city)
    setField(`${prefix}_state`)(value.state)
    setField(`${prefix}_zipcode`)(value.zipcode)
    setField(`meta.${prefix}_has_value`)(true)
    await getCounty.makeRequest(value)
    setShowFields(true)
  })

  return (
    <>
      {!showFields ? (
        <FieldBlock>
          <SelectField
            name="street-address"
            id={prefix ? `${prefix}-street-address` : 'street-address'}
            label={t('Search address')}
            options={suggestions.map(suggestion => ({
              label: suggestion.label,
              value: suggestion,
            }))}
            loading={isLoading || getCounty.isLoading}
            onInputChange={handleStreetAddressChange}
            onSelect={handleStreetAddressSelect}
            emptyMessage={t('No addresses found')}
            clearable
            icon="Search"
            error={!!get(fieldErrors, `${prefix}_county`)}
            errorMessage={t('County required')}
          />
          <Button.Secondary onClick={toggleAddressMode}>
            {t('Enter manually')}
          </Button.Secondary>
        </FieldBlock>
      ) : (
        <ButtonBlock>
          <Button.Secondary onClick={toggleAddressMode}>
            {t('Use address autocomplete')}
          </Button.Secondary>
        </ButtonBlock>
      )}
      {showFields && (
        <>
          <FieldBlock variant="large">
            <PresenceWrapper
              show={showPresenceCheckbox}
              onChange={setField(`${prefix}_street_missing`)}
              value={getField(`${prefix}_street_missing`)}
            >
              <TextField
                type="text"
                name="street"
                id="street"
                label={t('Address')}
                value={getField(`${prefix}_street`)}
                onChange={setField(`${prefix}_street`)}
              />
            </PresenceWrapper>
          </FieldBlock>
          <FieldBlock variant="large">
            <TextField
              type="text"
              name="street_two"
              id="street_two"
              label={t('Address line 2')}
              value={getField(`${prefix}_street_two`)}
              onChange={setField(`${prefix}_street_two`)}
            />
          </FieldBlock>
          <FieldBlock>
            <PresenceWrapper
              show={showPresenceCheckbox}
              onChange={setField(`${prefix}_city_missing`)}
              value={getField(`${prefix}_city_missing`)}
            >
              <TextField
                type="text"
                name="city"
                id="city"
                label={t('City')}
                value={getField(`${prefix}_city`)}
                onChange={setField(`${prefix}_city`)}
              />
            </PresenceWrapper>
            <PresenceWrapper
              show={showPresenceCheckbox}
              onChange={setField(`${prefix}_state_missing`)}
              value={getField(`${prefix}_state_missing`)}
            >
              <SelectField
                label={t('State')}
                value={getField(`${prefix}_state`)}
                onSelect={setField(`${prefix}_state`)}
                options={stateOptions}
              />
            </PresenceWrapper>
            <PresenceWrapper
              show={showPresenceCheckbox}
              onChange={setField(`${prefix}_zipcode_missing`)}
              value={getField(`${prefix}_zipcode_missing`)}
            >
              <ZipCodeField
                value={getField(`${prefix}_zipcode`)}
                onChange={setField(`${prefix}_zipcode`)}
              />
            </PresenceWrapper>
          </FieldBlock>
          {hasCounty ? (
            <FieldBlock>
              <PresenceWrapper
                show={showPresenceCheckbox}
                onChange={setField(`${prefix}_county_missing`)}
                value={getField(`${prefix}_county_missing`)}
              >
                <SelectField
                  label={t('County')}
                  options={countiesByState.TX.map(county => ({
                    value: county,
                    label: county,
                  }))}
                  value={getField(`${prefix}_county`)}
                  onSelect={setField(`${prefix}_county`)}
                  required
                  error={!!get(fieldErrors, `${prefix}_county`)}
                  errorText={get(fieldErrors, `${prefix}_county`)}
                />
              </PresenceWrapper>
            </FieldBlock>
          ) : null}
        </>
      )}
    </>
  )
}

export default AddressLookup
