import { useTranslation } from 'react-i18next'
import { Section, Grid } from '@politechdev/blocks-design-system'
import ActivityLog from './ActivityLog/ActivityLog'
import PhoneBankLog from './PhoneBankLog/PhoneBankLog'

const PersonContactLog = ({ personId }) => {
  const { t } = useTranslation()

  return (
    <Section label={t('Contact log')} collapsed>
      <Section>
        <PhoneBankLog personId={personId} />
      </Section>
      <Section>
        <ActivityLog personId={personId} />
      </Section>
      <Grid />
    </Section>
  )
}

export default PersonContactLog
