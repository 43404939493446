import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { GreaterThan, CheckCircle, TimesCircle } from 'components/uiKit/icons'
import styles from './Toast.module.scss'

const cx = classNames.bind(styles)

const Toast = ({ text, visible, error, success }) => {
  if (!visible || !text) return null

  return (
    <div
      className={cx('container', {
        'container--success': success,
        'container--error': error,
      })}
    >
      <div className={cx('icon')}>
        {!success && !error && <GreaterThan />}
        {success && <CheckCircle />}
        {error && <TimesCircle />}
      </div>
      <div className={cx('text')}>{text}</div>
    </div>
  )
}

Toast.propTypes = {
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
}

Toast.defaultProps = {
  visible: false,
  error: false,
  success: false,
}

export default Toast
