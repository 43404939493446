import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardError, TextField } from 'components'
import {
  Button,
  ButtonBlock,
  Modal,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import { useEvent, useReactRouter, useRequest } from 'hooks'
import { fetchDocumentsThroughFolder, putDocument } from 'requests/documents'
import { useModal } from '../ModalProvider/ModalProvider'
import { MODAL_TYPES } from '../constants'
import styles from './DocumentRenameModal.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const DocumentRenameModal = () => {
  const { t } = useTranslation()
  const [{ isOpen, closeModal }, { document, onClose }] = useModal(
    MODAL_TYPES.RENAME_DOCUMENT
  )
  const [name, setName] = useState('')
  const { setDocuments } = useContext(DocumentContext)
  const { match } = useReactRouter()

  const resetState = () => {
    setName('')
  }

  useEffect(() => {
    if (isOpen) {
      setName(document?.name || '')
    }
  }, [isOpen])

  const { makeRequest: documentsRequest, isLoading: documentsLoading } =
    useRequest(fetchDocumentsThroughFolder, {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    })

  const {
    makeRequest: updateRequest,
    isLoading: updateLoading,
    hasErrors: updateHasErrors,
  } = useRequest(putDocument, {
    onSuccess: async () => {
      await documentsRequest(match.params.id)
      onClose && onClose()
      closeModal()
      resetState()
    },
  })

  const isLoading = documentsLoading || updateLoading

  const rename = useEvent(() => updateRequest(document.id, { name }))

  return (
    <Modal
      id={MODAL_TYPES.RENAME_DOCUMENT}
      title={t(`Rename document`)}
      isOpen={isOpen}
      showLoading={isLoading}
    >
      <Modal.Body>
        <ContentBlock>
          <CardError
            hide={!updateHasErrors}
            message={t("We're unable to rename your document")}
            hideSupportLink
          />
          <div className={styles.field}>
            <TextField
              id="name"
              label={t('Document name')}
              value={name}
              onChange={setName}
              required
            />
          </div>
        </ContentBlock>
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={closeModal}>
            {t('Cancel')}
          </Button.Secondary>
          <Button.Accent onClick={rename} disabled={name === '' || isLoading}>
            {t('Rename')}
          </Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default DocumentRenameModal
