import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { EventSelectField, FormattedData, PersonSelectField } from 'components'
import {
  ButtonBlock,
  Button,
  Icon,
  DetailTable,
  SelectField,
} from '@politechdev/blocks-design-system'
import { updateEventAttendee, removeEventAttendee } from 'requests/people'
import { postEventAttendee } from 'requests/events'
import { useRequest } from 'hooks/useRequest'
import moment from 'moment'
import { formatDisplayName } from 'utils/formatting'

const PersonEventForm = ({
  personId,
  pastEvents = false,
  events,
  setFormVisible,
}) => {
  const { t } = useTranslation()
  const existingEvents = events.map(({ id }) => id)
  const requestParams = {
    filters: {
      rules: [
        {
          column: 'start_time',
          operator: pastEvents ? 'before' : 'after',
          param: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
    },
  }
  const [shownEvents, setShownEvents] = useState(events)
  const hasUnfilledFields = shownEvents.some(({ id }) => id === -1)
  const addEvent = () => {
    const newEvents = [...shownEvents, { id: -1, status: 'invited' }]
    setShownEvents(newEvents)
  }
  const updateEvent = (updatedEvent, indexToUpdate) => {
    const newEvents = shownEvents.map((event, index) => {
      if (indexToUpdate !== index) {
        return event
      }
      return updatedEvent
    })
    setShownEvents(newEvents)
  }
  const updateAttendance = (val, indexToUpdate) => {
    const newEvents = shownEvents.map((event, index) => {
      if (indexToUpdate !== index) {
        return event
      }
      return {
        ...event,
        status: val,
      }
    })
    setShownEvents(newEvents)
  }

  const updateInviter = (val, indexToUpdate) => {
    const newEvents = shownEvents.map((event, index) => {
      if (indexToUpdate !== index) {
        return event
      }
      return {
        ...event,
        inviter: val,
      }
    })
    setShownEvents(newEvents)
  }
  const removeEvent = id => {
    const newEvents = shownEvents.filter(event => event.id !== id)
    setShownEvents(newEvents)
  }
  const { makeRequest: saveEventsRequest } = useRequest(
    async () => {
      let requests = []

      requests = requests.concat(
        shownEvents
          .filter(
            shownEvent =>
              events.find(event => event.id === shownEvent.id) === undefined
          )
          .map(event =>
            postEventAttendee(event.id, {
              person_id: personId,
              status: event.status,
              inviter_id: event.inviter?.id,
            })
          )
      )
      requests = requests.concat(
        shownEvents
          .filter(
            shownEvent =>
              events.find(
                event =>
                  event.id === shownEvent.id &&
                  (event.status !== shownEvent.status ||
                    event.inviter?.id !== shownEvent.inviter?.id)
              ) !== undefined
          )
          .map(event =>
            updateEventAttendee(event.id, event.attendanceId, {
              person_id: personId,
              status: event.status.toLowerCase(),
              inviter_id: event.inviter?.id,
            })
          )
      )
      requests = requests.concat(
        events
          .filter(
            event =>
              shownEvents.find(shownEvent => event.id === shownEvent.id) ===
              undefined
          )
          .map(event => removeEventAttendee(event.id, event.attendanceId))
      )
      return Promise.all(requests)
    },
    {
      onSuccess: () => setFormVisible(false),
    }
  )
  const saveChanges = () => {
    saveEventsRequest()
  }
  return (
    <div>
      <DetailTable
        columnHeaders={['Event name', 'Date', 'Status', 'Invited by']}
      >
        {shownEvents.map((event, index) => {
          const attendanceValue = event.status
            ? event.status.toLowerCase()
            : 'invited'
          const excludedValues = shownEvents
            .filter(excludedEvent => excludedEvent.id !== event.id)
            .map(({ id }) => id)
          let eventContent
          const eventExists = existingEvents.includes(event.id)
          if (eventExists) {
            eventContent = event.name ? (
              <Link to={`/build/events/${event.id}`}>{event.name}</Link>
            ) : (
              <Link to={`/build/meetings/${event.id}`}>
                {t('Meeting with')} {formatDisplayName(event.user)}
              </Link>
            )
          } else if (event.id > 0) {
            eventContent = (
              <EventSelectField
                hideLabel
                event={event}
                onSelect={val => updateEvent(val, index)}
                requestParams={requestParams}
                excludedValues={excludedValues}
              />
            )
          } else {
            eventContent = (
              <EventSelectField
                hideLabel
                onSelect={val => updateEvent(val, index)}
                requestParams={requestParams}
                excludedValues={excludedValues}
              />
            )
          }
          return (
            <DetailTable.Row key={event.id}>
              <DetailTable.Cell>{eventContent}</DetailTable.Cell>
              <DetailTable.Cell>
                <FormattedData type="date" value={event.start_time} />
              </DetailTable.Cell>
              <DetailTable.Cell>
                <SelectField
                  id={`event_${event.id}_status`}
                  value={attendanceValue}
                  onSelect={val => updateAttendance(val, index)}
                  options={[
                    { label: t('Attended'), value: 'attended' },
                    { label: t('Invited'), value: 'invited' },
                  ]}
                />
              </DetailTable.Cell>
              <DetailTable.Cell>
                <PersonSelectField
                  id={`event_${event.id}_inviter`}
                  person={event.inviter}
                  onSelect={val => updateInviter(val, index)}
                  hideLabel
                  excludedPersonIds={[+personId]}
                />
              </DetailTable.Cell>
              <ButtonBlock>
                <Button.Secondary onClick={() => removeEvent(event.id, index)}>
                  <Icon.Times />
                </Button.Secondary>
              </ButtonBlock>
            </DetailTable.Row>
          )
        })}
      </DetailTable>
      <ButtonBlock>
        <Button
          aria-label="Add Event"
          onClick={addEvent}
          disabled={hasUnfilledFields}
        >
          <Icon.Plus />
        </Button>
      </ButtonBlock>
      <ButtonBlock>
        <Button.Accent
          aria-label="Save"
          onClick={() => saveChanges()}
          disabled={hasUnfilledFields}
        >
          {t('Save')}
        </Button.Accent>
        <Button.Secondary
          aria-label="Cancel"
          onClick={() => setFormVisible(false)}
        >
          {t('Cancel')}
        </Button.Secondary>
      </ButtonBlock>
    </div>
  )
}

export default PersonEventForm
