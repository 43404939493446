import React from 'react'
import { useDrop } from 'react-dnd'
import classNames from 'classnames/bind'
import { FILTER_TYPES } from '../constants'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import { FilterCard } from '../FilterCard/FilterCard'
import styles from './FilterDropZone.module.scss'

const cx = classNames.bind(styles)

const FilterDropZone = () => {
  const { group, filters, addFilter } = useListFilters()
  const [{ isOver }, dropRef] = useDrop({
    accept: FILTER_TYPES.map(filterType => `${filterType.id}-${group.id}`),
    drop: item =>
      addFilter({
        id: item.id,
      }),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  })

  return (
    <div
      ref={dropRef}
      className={cx('dropzone', {
        'dropzone--isOver': isOver,
        'dropzone--isEmpty': !filters.length,
      })}
    >
      {filters.map(filter => (
        <FilterCard
          key={`${filter.id}-${filter.chainId || ''}`}
          filter={filter}
        />
      ))}
      <div className={styles.placeholder}>
        <span>Drag filter boxes here</span>
      </div>
    </div>
  )
}

export default FilterDropZone
