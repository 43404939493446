/* eslint-disable no-use-before-define */

import { isEmpty } from 'lodash'
import moment from 'moment'
import { REDACTED_FIELDS } from 'constants/registrationForms'
import { DATE_FORMAT_YEAR_MONTH_DAY } from 'utils/constants'

const COVER_SHEET_INDEX = 0

export const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

export const getOptionArrayOfLength = length =>
  Array.from({ length }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }))

export const filenameCleanupRegex = /(_page_000)\w+.pdf/g

export const formatForSubmission = pages => ({
  cover_sheet_url: getCoverSheetUrl(pages[COVER_SHEET_INDEX]),
  forms: getFormattedPages(pages.slice(1)),
  shift: pages[COVER_SHEET_INDEX].formData,
  filename: pages[COVER_SHEET_INDEX].metadata.filename.replace(
    filenameCleanupRegex,
    '.pdf'
  ),
})

const getCoverSheetUrl = ({ url }) => url

export const getFormattedPages = pages =>
  pages.map(
    ({ formData: { meta, ...submittableData }, url: pledge_card_url }) => {
      const mailingAddressFields = meta.registration
        .mailingAddressSameAsResidential
        ? withKeysPrefixed(copyVotingAddressFields(submittableData), 'mailing')
        : {
            street_address_one: submittableData.address_street,
            street_address_two: submittableData.address_street_two,
            city: submittableData.address_city,
            state: submittableData.address_state,
            zipcode: submittableData.address_zipcode,
          }

      const registrationData = meta.hasRegistrationForm
        ? withUndefinedAsNull({
            hard_copy_collected: meta.hasRegistrationForm,
            last_name: submittableData.last_name,
            first_name: submittableData.first_name,
            middle_name: submittableData.middle_name,
            voting_street_address_one:
              submittableData.voting_address_street_missing
                ? undefined
                : submittableData.voting_address_street,
            voting_street_address_two:
              submittableData.voting_address_street_two_missing
                ? undefined
                : submittableData.voting_address_street_two,
            voting_city: submittableData.voting_address_city_missing
              ? undefined
              : submittableData.voting_address_city,
            voting_state: submittableData.voting_address_state_missing
              ? undefined
              : submittableData.voting_address_state,
            voting_zipcode: submittableData.voting_address_zipcode_missing
              ? undefined
              : submittableData.voting_address_zipcode,
            county: submittableData.voting_address_county_missing
              ? undefined
              : submittableData.voting_address_county,
            registration_type: submittableData.reason_for_registration,
            us_citizen: submittableData.us_citizen,
            gender: submittableData.gender,
            state_id: submittableData.has_drivers_license,
            social_security: submittableData.has_ssn,
            eligible_voting_age: submittableData.voting_age,
            signature: submittableData.has_applicant_signature,
            registration_date:
              submittableData.registration_date &&
              moment(submittableData.registration_date).format(
                DATE_FORMAT_YEAR_MONTH_DAY
              ),
            redacted_fields: buildRedactedFields(submittableData),
            extras: withUndefinedAsNull({
              poll_worker: submittableData.election_worker_interest,
              does_not_have_identification: submittableData.has_no_id,
              has_vdr_signature: submittableData.has_vdr_signature,
              vdr_signature_date:
                submittableData.signature_date &&
                moment(submittableData.signature_date).format(
                  DATE_FORMAT_YEAR_MONTH_DAY
                ),
              has_vdr_number: submittableData.has_vdr_number,
              signed_as_agent: submittableData.signed_as_agent,
              no_vdr_match: submittableData.no_vdr_match,
            }),
            ...mailingAddressFields,
          })
        : {
            hard_copy_collected: meta.hasRegistrationForm,
          }

      if (meta.hasRegistrationForm && !submittableData.has_date_of_birth) {
        registrationData.date_of_birth = null
      }
      if (meta.hasRegistrationForm && !submittableData.has_phone_number) {
        registrationData.phone_number = null
      }

      if (meta.hasPledgeCard) {
        const rawPledgeCard = submittableData.pledge_card || {}

        const addressFields = meta.pledgeCard.addressSameAsRegistration
          ? copyVotingAddressFields(submittableData)
          : {
              street_address_one: rawPledgeCard.address_street,
              street_address_two: rawPledgeCard.address_street_two,
              city: rawPledgeCard.address_city,
              state: rawPledgeCard.address_state,
              zipcode: rawPledgeCard.address_zipcode,
            }

        const nameFields = meta.pledgeCard.nameSameAsRegistration
          ? copyRegistrationNameFields(submittableData)
          : {
              last_name: rawPledgeCard.last_name,
              first_name: rawPledgeCard.first_name,
              middle_name: rawPledgeCard.middle_name,
            }

        const pledge_card = withUndefinedAsNull({
          phone_number: rawPledgeCard.phone_number,
          email_address: rawPledgeCard.email,
          date_of_birth:
            rawPledgeCard.date_of_birth &&
            moment(rawPledgeCard.date_of_birth).format(
              DATE_FORMAT_YEAR_MONTH_DAY
            ),
          i_care_about: rawPledgeCard.i_care_about,
          ...addressFields,
          ...nameFields,
        })

        if (!isEmpty(pledge_card)) {
          registrationData.pledge_card = pledge_card
          registrationData.pledge_card_url = pledge_card_url
        }
      }

      return registrationData
    }
  )

const copyRegistrationNameFields = data => ({
  last_name: data.last_name,
  first_name: data.first_name,
  middle_name: data.middle_name,
})

const copyVotingAddressFields = data => ({
  street_address_one: data.voting_address_street_missing
    ? undefined
    : data.voting_address_street,
  street_address_two: data.voting_address_street_two_missing
    ? undefined
    : data.voting_address_street_two,
  city: data.voting_address_city_missing ? undefined : data.voting_address_city,
  state: data.voting_address_state_missing
    ? undefined
    : data.voting_address_state,
  zipcode: data.voting_address_zipcode_missing
    ? undefined
    : data.voting_address_zipcode,
  county: data.voting_address_county_missing
    ? undefined
    : data.voting_address_county,
})

const withKeysPrefixed = (object, prefix) => {
  const entriesWithPrefix = Object.entries(object).map(([key, value]) => [
    `${prefix}_${key}`,
    value,
  ])
  return Object.fromEntries(entriesWithPrefix)
}

const buildRedactedFields = data =>
  [
    data.has_date_of_birth && REDACTED_FIELDS.DOB,
    data.has_phone_number && REDACTED_FIELDS.PHONE,
  ].filter(field => !!field)

const withUndefinedAsNull = formData =>
  Object.entries(formData).reduce((json, [key, value]) => {
    json[key] = value !== undefined ? value : null
    return json
  }, {})

export const getInitialFormData = isRegistrationOnly => ({
  meta: {
    hasPledgeCard: !isRegistrationOnly,
    hasRegistrationForm: !!isRegistrationOnly,
    registration: {
      mailingAddressSameAsResidential: false,
    },
    pledgeCard: {
      nameSameAsRegistration: false,
      addressSameAsRegistration: false,
    },
  },
  voting_address_state: 'TX',
  mailing_address_state: 'TX',
  has_applicant_signature: false,
  has_drivers_license: false,
  has_ssn: false,
  reason_for_registration: null,
  us_citizen: null,
  voting_age: null,
  has_vdr_signature: false,
  has_vdr_number: false,
  signed_as_agent: false,
  election_worker_interest: false,
  has_no_id: false,
})

const isNotUndefined = value => value !== undefined

const isNotEmpty = value => !isEmpty(value)

const registrationAddressFields = [
  ['voting_address_street', isNotEmpty],
  ['voting_address_street_two', isNotEmpty],
  ['voting_address_city', isNotEmpty],
  ['voting_address_zipcode', isNotEmpty],
  ['voting_address_county', isNotEmpty],
]

const registrationFormValidations = [
  ['first_name', isNotEmpty],
  ['middle_name', isNotEmpty],
  ['last_name', isNotEmpty],
  ['reason_for_registration', isNotEmpty],
  ['us_citizen', isNotUndefined],
  ['voting_age', isNotUndefined],
  ['election_worker_interest', isNotEmpty],
  ...registrationAddressFields,
  ['mailing_address_street', isNotEmpty],
  ['mailing_address_street_two', isNotEmpty],
  ['mailing_address_city', isNotEmpty],
  ['mailing_address_zipcode', isNotEmpty],
  ['mailing_address_county', isNotEmpty],
  ['gender', isNotEmpty],
  ['has_drivers_license', isNotEmpty],
  ['has_ssn', isNotEmpty],
  ['has_no_id', isNotEmpty],
  ['has_applicant_signature', isNotEmpty],
  ['registration_date', isNotEmpty],
  ['has_vdr_signature', isNotEmpty],
  ['signature_date', isNotEmpty],
  ['has_vdr_number', isNotEmpty],
]

const isAtleastOneValidRegistrationField = formData =>
  registrationFormValidations.some(([field, isValid]) =>
    isValid(formData[field])
  )

export const isRegistrationFormValid = formData => {
  if (!formData.meta.hasRegistrationForm) return true
  return isAtleastOneValidRegistrationField(formData)
}

const isValidCopiedAddress = formData => {
  if (formData.meta.pledgeCard.addressSameAsRegistration) {
    return registrationAddressFields.some(([field, isValid]) =>
      isValid(formData[field])
    )
  }
  return false
}

const isValidCopiedName = formData => {
  if (formData.meta.pledgeCard.nameSameAsRegistration) {
    return ['first_name', 'middle_name', 'last_name'].some(
      field => !isEmpty(formData[field])
    )
  }
  return false
}

const pledgeCardValidations = [
  ['first_name', isNotEmpty],
  ['middle_name', isNotEmpty],
  ['last_name', isNotEmpty],
  ['phone_number', isNotEmpty],
  ['email', isNotEmpty],
  ['date_of_birth', isNotEmpty],
  ['address_street', isNotEmpty],
  ['address_street_two', isNotEmpty],
  ['address_city', isNotEmpty],
  ['address_state', isNotEmpty],
  ['address_zipcode', isNotEmpty],
  ['address_county', isNotEmpty],
  ['i_care_about', isNotEmpty],
]

const isAtleastOneValidPledgeCardField = formData =>
  pledgeCardValidations.some(([field, isValid]) =>
    isValid(formData.pledge_card?.[field])
  )

export const isValidPledgeCardForm = formData =>
  isAtleastOneValidPledgeCardField(formData) ||
  isValidCopiedAddress(formData) ||
  isValidCopiedName(formData)
