export const FIELD_TYPES = {
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkboxGroup',
  TEXT: 'text',
  RADIO: 'radio',
  DATE: 'date',
  SELECT: 'select',
  NAME_BLOCK: 'nameBlock',
  ADDRESS_BLOCK: 'addressLookup',
  VDR_BLOCK: 'vdrBlock',
  COUNTRY_STATE_SELECT: 'countryStateSelect',
}

export const GROUP = {
  NAME_FIELDS: 'NAME_FIELDS',
  VOTING_ADDRESS_FIELDS: 'VOTING_ADDRESS_FIELDS',
  MAILING_ADDRESS_FIELDS: 'MAILING_ADDRESS_FIELDS',
  VDR_FIELDS: 'VDR_FIELDS',
}

export const RECORD_TYPES = {
  VOTER_REGISTRATION: 'registration_form',
  PLEDGE_CARD: 'pledge_card',
}

export const RADIO_DEFAULT = null
export const CHECKBOX_DEFAULT = false
export const CHECKBOX_GROUP_DEFAULT = []
export const NAME_GROUP_DEFAULT = [
  ['first_name'],
  ['middle_name'],
  ['last_name'],
  ['name_suffix'],
]
export const ADDRESS_GROUP_DEFAULT = [
  ['street_address_one'],
  ['street_address_two'],
  ['city'],
  ['state'],
  ['zipcode'],
  ['county'],
]
export const VDR_GROUP_DEFAULT = [
  ['has_vdr_signature', false],
  ['has_vdr_number', false],
  ['vdr_signature_date'],
]

export const VDR_ISSUES = {
  COUNTY: 'COUNTY',
  NO_MATCHING_VDR: 'NO_MATCHING_VDR',
}

export const REQUIRE_WARN = 'warn'

export const REQUIRE_ERROR = 'error'

export const rADDRESS_SUFFIX =
  /(_street_address_one|_street_address_two|_city|_county|_state|_zipcode)/

export const rADDRESS_SUFFIX_WITHOUT_DANGLE =
  /(street_address_one|street_address_two|city|county|state|zipcode)/
