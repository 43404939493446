import { useState, useRef, memo, useContext } from 'react'
import { useDrop, useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import isEqual from 'react-fast-compare'
import classNames from 'classnames/bind'
import { ContextMenu } from 'components'
import { Icon } from '@politechdev/blocks-design-system'
import { useScroll, useContextMenu } from 'contexts'
import { moveDocument } from 'requests/documents'
import { useRequest } from 'hooks/useRequest'
import SidebarTree from '../SidebarTree/SidebarTree'
import { useSidebar } from '../SidebarProvider/SidebarProvider'
import { useModalTrigger } from '../ModalProvider/ModalProvider'
import { useFolderClipboard } from '../ClipboardProvider/ClipboardProvider'
import { usePermissions } from '../PermissionsProvider/PermissionsProvider'
import { INTERACTABLE_TYPES, MODAL_TYPES } from '../constants'
import { isItemDocument, isItemFolder } from '../utils'
import { DROP_POSITIONS } from './constants'
import styles from './SidebarTreeFolder.module.scss'
import DocumentContext from '../DocumentContext/DocumentContext'

const cx = classNames.bind(styles)

const SidebarTreeFolder = memo(({ parentId, folderId }) => {
  const { t } = useTranslation()
  const { canModify } = usePermissions()
  const { toggleCollapsed, isCollapsed } = useSidebar()
  const { setDocuments, folders, currentFolder, moveFolder } =
    useContext(DocumentContext)

  const folder = folders.find(folder => folder.id === folderId) || {}
  const childFolderIds = folders
    .filter(folder => folder.parent_id === folderId)
    .map(folder => folder.id)
  const hasChildren = !!childFolderIds.length
  const isOpen = currentFolder.id === folderId

  const { makeRequest: moveDocumentRequest } = useRequest(
    folderProps => moveDocument(folderProps, currentFolder.id),
    {
      onSuccess: ({ documents }) => {
        setDocuments(documents)
      },
    }
  )

  const containerRef = useRef(null)
  const itemRef = useRef(null)
  const { endScroll } = useScroll()

  const [dropPosition, setDropPosition] = useState(null)

  const [{ isOver, isDescendant, isBeforeSibling, isAfterSibling }, dropRef] =
    useDrop({
      accept: [
        INTERACTABLE_TYPES.SIDEBAR_FOLDER,
        INTERACTABLE_TYPES.PANEL_FOLDER,
        INTERACTABLE_TYPES.PANEL_DOCUMENT,
        INTERACTABLE_TYPES.SEARCH_FOLDER,
        INTERACTABLE_TYPES.SEARCH_DOCUMENT,
      ],
      hover: (item, monitor) => {
        if (!itemRef.current || !containerRef.current) return
        if (!monitor.isOver({ shallow: true })) {
          setDropPosition(null)
          return
        }

        const itemBounds = itemRef.current?.getBoundingClientRect()
        const containerBounds = containerRef.current?.getBoundingClientRect()

        const padding = Math.ceil((itemBounds.top - containerBounds.top) / 2)

        const { y: yPos } = monitor.getClientOffset()
        const hoverY = yPos - containerBounds.top

        if (hoverY < padding && isItemFolder(item)) {
          parentId && setDropPosition(DROP_POSITIONS.BEFORE)
          return
        }

        if (
          hoverY > padding + itemBounds.height &&
          !childFolderIds.length &&
          isItemFolder(item)
        ) {
          parentId && setDropPosition(DROP_POSITIONS.AFTER)
          return
        }

        setDropPosition(DROP_POSITIONS.INSIDE)
      },
      drop: async (item, monitor) => {
        if (monitor.didDrop()) return
        endScroll()

        if (isItemDocument(item)) {
          await moveDocumentRequest({
            documentId: item.id,
            folderId: folder.id,
          })
        }

        if (isItemFolder(item)) {
          await moveFolder(item.id, folder.id)
        }

        item.onDrop && item.onDrop()
      },
      collect: monitor => {
        const item = monitor.getItem()
        const isOver = monitor.isOver()
        if (!item) {
          return {
            isOver,
          }
        }

        if (isItemDocument(item)) {
          return {
            isOver,
          }
        }

        return {
          isOver,
          isBeforeSibling: item.rgt === folder.lft - 1,
          isAfterSibling: item.lft === folder.rgt + 1,
          isDescendant: item.lft <= folder.lft && item.rgt >= folder.rgt,
        }
      },
      canDrop: item => {
        if (!canModify) return false
        if (isItemDocument(item)) return true

        return !(item.lft <= folder.lft && item.rgt >= folder.rgt)
      },
    })

  const [, dragRef, previewRef] = useDrag({
    item: {
      id: folder.id,
      lft: folder.lft,
      rgt: folder.rgt,
      parent_id: folder.parent_id,
      type: INTERACTABLE_TYPES.SIDEBAR_FOLDER,
    },
    end: endScroll,
    canDrag: () => canModify,
  })

  const { showMenu } = useContextMenu({
    id: [INTERACTABLE_TYPES.SIDEBAR_FOLDER, folder.id],
  })

  const openModal = useModalTrigger()

  const { canPaste, isCutting, pasteItem, cutItem } = useFolderClipboard({
    folder,
  })

  const isRoot = !parentId
  const isBefore =
    isOver && !isDescendant && dropPosition === DROP_POSITIONS.BEFORE
  const isInside =
    isOver && !isDescendant && dropPosition === DROP_POSITIONS.INSIDE
  const isAfter =
    isOver && !isDescendant && dropPosition === DROP_POSITIONS.AFTER

  return (
    <>
      <div
        ref={dropRef(containerRef)}
        className={cx('container', {
          'container--root': isRoot,
        })}
      >
        {isBefore && !isBeforeSibling && <div className={styles.before} />}
        <Link
          to={`/share/documents/${folder.id}`}
          className={cx('item', {
            'item--descendant': isDescendant,
            'item--inside': isInside,
            'item--clipboard': isCutting,
            'item--open': isOpen,
          })}
          ref={dragRef(itemRef)}
          onContextMenu={showMenu}
        >
          <div ref={previewRef} className={styles.preview} />
          {hasChildren && !isRoot && (
            <button
              type="button"
              onClick={e => {
                e.preventDefault()
                toggleCollapsed(folder.id)
              }}
              className={styles.chevron}
            >
              {isCollapsed(folder.id) ? (
                <Icon.ChevronDown />
              ) : (
                <Icon.ChevronUp />
              )}
            </button>
          )}
          <div className={styles.icon}>
            {!isCollapsed(folder.id) && hasChildren ? (
              <Icon.FolderOpen />
            ) : (
              <Icon.Folder />
            )}
          </div>
          <div className={styles.content}>
            <span>{folder.name}</span>
          </div>
        </Link>
        {isAfter && !isAfterSibling && <div className={styles.after} />}
        {!isCollapsed(folder.id) && hasChildren && (
          <SidebarTree folder={folder} childIds={childFolderIds} />
        )}
      </div>
      <ContextMenu id={[INTERACTABLE_TYPES.SIDEBAR_FOLDER, folder.id]}>
        <ContextMenu.Item
          label={t('Paste')}
          icon={<Icon.Paste />}
          onClick={pasteItem}
          isDisabled={!canPaste || !canModify}
        />
        <ContextMenu.Item
          label={t('Cut')}
          icon={<Icon.Cut />}
          onClick={cutItem}
          isHidden={isRoot}
          isDisabled={!canModify}
        />
        <ContextMenu.Divider />
        <ContextMenu.Item
          label={t('Create folder')}
          icon={<Icon.FolderPlus />}
          isDisabled={!canModify}
          onClick={() => {
            openModal(MODAL_TYPES.CREATE_FOLDER, {
              parentId: folder.id,
            })
          }}
        />
        <ContextMenu.Item
          label={t('Rename')}
          icon={<Icon.Pen />}
          isDisabled={!canModify}
          onClick={() => {
            openModal(MODAL_TYPES.RENAME_FOLDER, {
              folder,
            })
          }}
        />
      </ContextMenu>
    </>
  )
}, isEqual)

export default SidebarTreeFolder
