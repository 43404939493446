import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import {
  SelectField,
  MultiSelectField,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks/useRequest'
import { fetchCampaigns } from 'requests/campaigns'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import { buildParams, buildOption, buildCampaign } from './utils'

const CampaignSelectField = ({
  label,
  campaignTypes,
  filters,
  onSelect,
  campaign = {},
  campaigns = [],
  isMulti = false,
  ...props
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'campaign',
    ability: 'view',
  })

  const [options, setOptions] = useState([])
  const [errorMsg, setErrorMsg] = useState()
  const [value, setValue] = useState(campaign?.id)
  const [multiValue, setMultiValue] = useState(
    campaigns.map(campaign => campaign.id)
  )
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  const { makeRequest, isLoading } = useRequest(fetchCampaigns, {
    onSuccess: ({ campaigns: incomingCampaigns }) => {
      setOptions(incomingCampaigns.map(buildOption))
    },
    onError: error => {
      setErrorMsg(error)
    },
  })

  const getCampaigns = () => {
    const params = buildParams({
      query: debounced,
      campaignTypes,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getCampaigns()
  }, [debounced])

  useEffect(() => {
    setValue(campaign?.id)
  }, [campaign])

  useEffect(() => {
    setMultiValue(campaigns.map(campaign => campaign.id))
  }, [JSON.stringify(campaigns)])

  const singleOptions = buildSingleOptions(options, campaign, buildOption)

  const multiOptions = buildMultiOptions(options, campaigns, buildOption)

  const handleChange = selection => {
    const selectedOption = singleOptions.find(
      ({ value }) => value === selection
    )
    onSelect &&
      onSelect(selectedOption ? buildCampaign(selectedOption) : undefined)
  }

  const handleMultiChange = selections => {
    onSelect &&
      onSelect(
        multiOptions
          .filter(({ value }) => selections.includes(value))
          .map(buildCampaign)
      )
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSelectField
          label={label || t('Campaigns')}
          options={multiOptions}
          onSelect={handleMultiChange}
          onInputChange={setQuery}
          values={multiValue || []}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      ) : (
        <SelectField
          label={label || t('Campaign')}
          options={singleOptions}
          onSelect={handleChange}
          onInputChange={setQuery}
          value={value}
          loading={isLoading}
          disabled={!hasPermission}
          hint={
            !hasPermission && t('You do not have permission to edit this field')
          }
          {...props}
        />
      )}
    </>
  )
}

CampaignSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  campaignTypes: PropTypes.array,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  campaign: PropTypes.object,
}

CampaignSelectField.defaultProps = {
  id: 'campaign',
  campaignTypes: [],
  filters: [],
  campaign: null,
}

export default CampaignSelectField
