import { useTranslation } from 'react-i18next'
import {
  CardError,
  EventSelectField,
  ListSelectField,
  TeamSelectField,
} from 'components'
import {
  FieldBlock,
  TextBlock,
  SelectField,
  Font,
  ProgressBar,
} from '@politechdev/blocks-design-system'
import { useContext, useEffect, useState } from 'react'
import { useRequest } from 'hooks'
import { fetchMembers } from 'requests/lists'
import { fetchTeamMemberships } from 'requests/teams'
import { fetchAttendees } from 'requests/events'
import { EMAIL_SOURCES } from '../SendContext/constants'
import { SendContext } from '../SendContext/SendContext'
import styles from './SendSource.module.scss'

const SendSource = () => {
  const { t } = useTranslation()
  const {
    setContacts,
    setContactMeta,
    setEvent,
    event,
    setList,
    list,
    setTeam,
    team,
  } = useContext(SendContext)
  const [source, setSource] = useState('')
  const [resource, setResource] = useState('')
  const {
    makeRequest: listPeopleReq,
    isLoading: listPeopleReqLoading,
    errorMsg: listPeopleReqError,
  } = useRequest(fetchMembers, {
    onSuccess: ({ people, meta }) => {
      setContacts(people)
      setContactMeta(meta)
    },
  })
  const {
    makeRequest: teamPeopleReq,
    isLoading: teamPeopleReqLoading,
    errorMsg: teamPeopleReqError,
  } = useRequest(fetchTeamMemberships, {
    onSuccess: ({ team_memberships, meta }) => {
      setContacts(team_memberships.map(m => m.person))
      setContactMeta(meta)
    },
  })
  const {
    makeRequest: eventPeopleReq,
    isLoading: eventPeopleReqLoading,
    errorMsg: eventPeopleReqError,
  } = useRequest(fetchAttendees, {
    onSuccess: ({ event_attendances, meta }) => {
      setContacts(event_attendances.map(m => m.person))
      setContactMeta(meta)
    },
  })

  const sourceOptions = [
    {
      label: t('List'),
      value: EMAIL_SOURCES.LISTS,
    },
    {
      label: t('Event'),
      value: EMAIL_SOURCES.EVENTS,
    },
    {
      label: t('Team'),
      value: EMAIL_SOURCES.TEAMS,
    },
  ]

  useEffect(() => {
    const sourceRequest = {
      [EMAIL_SOURCES.LISTS]: () =>
        listPeopleReq(resource.id, {
          filters: {
            rules: [
              {
                column: 'primary_email_address',
                operator: 'is_nil',
                invert: true,
              },
            ],
          },
          fields: 'name',
          per: 5,
        }),
      [EMAIL_SOURCES.TEAMS]: () =>
        teamPeopleReq(resource.id, {
          filters: {
            rules: [
              {
                column: 'person_primary_email_address',
                operator: 'is_nil',
                invert: true,
              },
            ],
          },
          fields: [{ person: 'name' }],
          per: 5,
        }),
      [EMAIL_SOURCES.EVENTS]: () =>
        eventPeopleReq(resource.id, {
          filters: {
            rules: [
              {
                column: 'person_primary_email_address',
                operator: 'is_nil',
                invert: true,
              },
            ],
          },
          fields: [{ person: 'name' }],
          per: 5,
        }),
    }
    source && sourceRequest[source]()
  }, [resource])

  return (
    <form>
      <TextBlock>
        <Font.Label>{t('Select source')}</Font.Label>
      </TextBlock>
      <FieldBlock>
        <SelectField
          id="source"
          label={t('Source')}
          options={sourceOptions}
          onSelect={setSource}
          value={source}
        />
      </FieldBlock>
      <FieldBlock>
        {!source && (
          <SelectField
            id="resource"
            label={t('Resource')}
            value=""
            disabled
            options={[]}
            hint={t('Select a source first')}
          />
        )}
        {source === EMAIL_SOURCES.TEAMS && (
          <TeamSelectField
            team={team}
            onSelect={incomingTeam => {
              setResource(incomingTeam)
              setTeam(incomingTeam)
              setContacts([])
            }}
          />
        )}
        {source === EMAIL_SOURCES.EVENTS && (
          <EventSelectField
            event={event}
            onSelect={incomingEvent => {
              setResource(incomingEvent)
              setEvent(incomingEvent)
              setContacts([])
            }}
          />
        )}
        {source === EMAIL_SOURCES.LISTS && (
          <ListSelectField
            list={list}
            onSelect={incomingList => {
              setResource(incomingList)
              setList(incomingList)
              setContacts([])
            }}
          />
        )}
      </FieldBlock>
      <div className={styles['request-status']}>
        <ProgressBar
          show={
            listPeopleReqLoading ||
            teamPeopleReqLoading ||
            eventPeopleReqLoading
          }
        />
        <CardError
          hide={
            !listPeopleReqError || !teamPeopleReqError || !eventPeopleReqError
          }
          message={
            listPeopleReqError || teamPeopleReqError || eventPeopleReqError
          }
        />
      </div>
    </form>
  )
}

export default SendSource
