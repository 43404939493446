import React from 'react'
import { Font, Button, ButtonBlock } from '@politechdev/blocks-design-system'
import { ContentBlock, Sheet, ViewContainer } from 'components'
import { useTranslation } from 'react-i18next'

function SubmitOrContinue({ onSubmit, onContinue, onReview, isLoading }) {
  const { t } = useTranslation()
  return (
    <ViewContainer loading={isLoading}>
      <Sheet>
        <ContentBlock>
          <Font.Copy Element="p">
            {t(
              'Would you like to add any additional registrations without a pledge card?'
            )}
          </Font.Copy>
        </ContentBlock>
        <ButtonBlock>
          <Button onClick={onContinue}>
            {t('Add registration without a pledge card')}
          </Button>
          <Button onClick={onReview}>{t('Review entries')}</Button>
          <Button.Accent onClick={onSubmit} disabled={isLoading}>
            {t('Finish and submit')}
          </Button.Accent>
        </ButtonBlock>
      </Sheet>
    </ViewContainer>
  )
}

export default SubmitOrContinue
