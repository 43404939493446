import {
  ContentBlock,
  ButtonBlock,
  Button,
  Section,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { getTotalPagesFromFilename } from 'shifts/utils'
import { DataEntryUploadStorage } from 'shifts/storageUtils'
import PledgeCardViewer from './PledgeCardViewer'

export const PledgeCardPreview = ({ closePreview, handleCancel }) => {
  const { t } = useTranslation()
  const { shiftId, pageId } = useParams()

  const scan = useMemo(() => {
    const scan = DataEntryUploadStorage.getRecordFromStorage(shiftId, pageId)
    const totalPages = getTotalPagesFromFilename(scan.metadata.filename)
    const fileUrl = scan.url
    return {
      totalPages,
      fileUrl,
    }
  }, [shiftId, pageId])

  return (
    <Section>
      <ContentBlock>
        <PledgeCardViewer
          fileUrl={scan.fileUrl}
          page={+pageId}
          totalPages={scan.totalPages - 1}
        />
      </ContentBlock>
      <TextBlock>
        {t(
          'Fetch the corresponsing registration form if you have it before continuing?'
        )}
      </TextBlock>
      <ButtonBlock>
        <Button.Accent onClick={closePreview}>Continue</Button.Accent>
        <Button.Secondary onClick={handleCancel}>Cancel</Button.Secondary>
      </ButtonBlock>
    </Section>
  )
}
