import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'
import {
  CheckboxGroup,
  DenominationSelectField,
  OrganizationSelectFieldDEPRECATED,
  TeamSelectFieldDEPRECATED,
  ResponsibilitySelectFieldDEPRECATED,
  EventSelectFieldDEPRECATED,
  CountySelectFieldDEPRECATED,
  DateRangeSelector,
  UserSelectFieldDEPRECATED,
  LocationSelectFieldDEPRECATED,
  EventTypeSelectFieldDEPRECATED,
  InterestCheckboxGroup,
  EthnicityCheckboxGroup,
  GenderCheckboxGroup,
  StateSelectFieldDEPRECATED,
  PersonTagSelectFieldDEPRECATED,
  TurfMultiSelectFieldDEPRECATED,
  ZipCodeMultiSelectFieldDEPRECATED,
  FreeformMultiSelectFieldDEPRECATED,
  DistrictSelectFieldDEPRECATED,
} from 'components'
import { useForm } from 'contexts'
import { LOCATION_TYPES } from 'constants/locations'
import { FILTER_TYPES_MAP } from '../constants'
import AgeRangeField from '../AgeRangeField/AgeRangeField'
import LanguageSelectFieldDEPRECATED from '../LanguageSelectField/LanguageSelectFieldDEPRECATED'
import { useListFilters } from '../ListFilterProvider/ListFilterProvider'
import PhoneBankResponseField from '../PhoneBankResponseField/PhoneBankResponseField'
import PhoneBankTagField from '../PhoneBankTagField/PhoneBankTagField'
import EventResponsibilitiesField from '../EventResponsibilitiesField/EventResponsibilitiesField'
import ByEventCreatorField from '../ByEventCreatorField/ByEventCreatorField'
import { toKeyValueMap } from '../utils'
import RelativeDateRangeField from '../RelativeDateRangeField/RelativeDateRangeField'

const FilterCardField = ({ filter: currentFilter }) => {
  const { t } = useTranslation()
  const { group, setFilterValue } = useListFilters()
  const { setFieldError } = useForm()

  const filter = cloneDeep(currentFilter)

  const filterType = FILTER_TYPES_MAP[filter.id]

  switch (filterType.id) {
    case 'ethnicities': {
      return (
        <EthnicityCheckboxGroup
          id={`${group.id}-${filter.chainId || ''}`}
          values={filter.value}
          onChange={value => setFilterValue(filter, value)}
          required
        />
      )
    }
    case 'genders': {
      return (
        <GenderCheckboxGroup
          id={`${group.id}-${filter.chainId || ''}`}
          values={filter.value}
          onChange={value => setFilterValue(filter, value)}
          required
        />
      )
    }
    case 'best_contact_methods':
    case 'phone_types':
    case 'email_types': {
      return (
        <CheckboxGroup
          id={`${group.id}-${filter.chainId || ''}`}
          options={filterType.fieldOptions}
          values={filter.value}
          onChange={value => setFilterValue(filter, value)}
          required
        />
      )
    }
    case 'interest_level':
      return (
        <InterestCheckboxGroup
          id={`${group.id}-${filter.chainId || ''}`}
          options={filterType.fieldOptions}
          values={filter.value?.rules[0]?.param}
          onChange={value =>
            setFilterValue(filter, {
              rules: [
                {
                  column: 'interest_level',
                  operator: 'in',
                  param: value,
                },
              ],
            })
          }
          required
        />
      )
    case 'skills':
    case 'issues': {
      return (
        <PersonTagSelectFieldDEPRECATED
          id={filterType.id}
          context={filterType.id}
          label={t(filterType.name)}
          tags={filter.value}
          onSelect={value => setFilterValue(filter, value)}
          required
        />
      )
    }
    case 'primary_languages':
    case 'languages': {
      return (
        <LanguageSelectFieldDEPRECATED
          id={filterType.id}
          label={t(filterType.name)}
          languages={filter.value}
          onSelect={value => setFilterValue(filter, value)}
          required
        />
      )
    }
    case 'ages': {
      return (
        <AgeRangeField
          id={filterType.id}
          value={filter.value}
          onChange={value => setFilterValue(filter, value)}
        />
      )
    }
    case 'zipcodes': {
      return (
        <ZipCodeMultiSelectFieldDEPRECATED
          label={t(filterType.name)}
          value={filter.value || []}
          onChange={value => setFilterValue(filter, value)}
        />
      )
    }
    case 'counties': {
      return (
        <CountySelectFieldDEPRECATED
          counties={filter.value}
          onSelect={value => setFilterValue(filter, value)}
          usePortal
          isMulti
        />
      )
    }
    case 'states': {
      return (
        <StateSelectFieldDEPRECATED
          id={filterType.id}
          states={filter.value}
          isMulti
          label={t(filterType.name)}
          onSelect={value => setFilterValue(filter, value)}
          usePortal
        />
      )
    }
    case 'congressional_districts':
    case 'state_legislative_upper_districts':
    case 'state_legislative_lower_districts': {
      return (
        <DistrictSelectFieldDEPRECATED
          districtType={filterType.districtType}
          districts={filter.value}
          label={t('District name')}
          onSelect={value => setFilterValue(filter, value)}
          usePortal
        />
      )
    }
    case 'denominations': {
      return (
        <DenominationSelectField
          denominations={(filter.value || []).map(
            denomination => denomination.description
          )}
          onSelect={denominations => {
            setFilterValue(
              filter,
              denominations.map(denomination => ({
                id: denomination,
                description: denomination,
              }))
            )
          }}
          usePortal
        />
      )
    }
    case 'organizations': {
      return (
        <OrganizationSelectFieldDEPRECATED
          canSelectAll
          noOptionsMessage={t('organizations not found.')}
          organizations={filter.value}
          isMulti
          onSelect={value => setFilterValue(filter, value)}
          usePortal
        />
      )
    }
    case 'teams': {
      return (
        <TeamSelectFieldDEPRECATED
          canSelectAll
          noOptionsMessage={t('teams not found.')}
          teams={filter.value}
          isMulti
          onSelect={value => setFilterValue(filter, value)}
          usePortal
        />
      )
    }
    case 'responsibilities': {
      return (
        <ResponsibilitySelectFieldDEPRECATED
          label={t(filterType.name)}
          isMulti
          responsibilities={filter.value}
          onSelect={responsibilities =>
            setFilterValue(filter, responsibilities)
          }
          usePortal
        />
      )
    }
    case 'events_attended': {
      return (
        <EventSelectFieldDEPRECATED
          canSelectAll
          isMulti
          events={filter.value}
          onSelect={value => setFilterValue(filter, value)}
          usePortal
        />
      )
    }
    case 'phone_banking_response': {
      return <PhoneBankResponseField filter={filter} />
    }
    case 'phone_banking_tag': {
      return <PhoneBankTagField filter={filter} />
    }
    case 'created_at': {
      if (filter.value) {
        return (
          <DateRangeSelector
            label={t('Select date range')}
            onDatesChange={(start, end) => {
              if (end) {
                setFilterValue(filter, {
                  min: start,
                  max: moment(end).add(1, 'day'),
                })
                setFieldError(filterType.id, null)
              } else {
                setFieldError(filterType.id, t('End date must be selected'))
              }
            }}
            defaultPresets
            initialStartDate={filter.value.min}
            initialEndDate={
              filter.value.max
                ? moment(filter.value.max).subtract(1, 'day')
                : null
            }
          />
        )
      }
      return null
    }
    case 'events_attended_by_date': {
      return (
        <RelativeDateRangeField
          columns={['attended_events_start_time', 'staffed_events_start_time']}
          filter={filter}
          filterType={filterType}
        />
      )
    }
    case 'meetings_by_date': {
      if (filter.value) {
        return (
          <DateRangeSelector
            label={t('Select date range')}
            onDatesChange={(start, end) => {
              if (end) {
                setFilterValue(filter, {
                  min: start,
                  max: moment(end).add(1, 'day'),
                })
                setFieldError(filterType.id, null)
              } else {
                setFieldError(filterType.id, t('End date must be selected'))
              }
            }}
            defaultPresets
            initialStartDate={filter.value.min}
            initialEndDate={
              filter.value.max
                ? moment(filter.value.max).subtract(1, 'day')
                : null
            }
          />
        )
      }
      return null
    }
    case 'assigned_to': {
      return (
        <UserSelectFieldDEPRECATED
          id="organizer-select"
          label={t('Organizer')}
          isMulti
          onSelect={value => {
            setFilterValue(filter, value)
          }}
          users={filter.value || []}
          usePortal
        />
      )
    }
    case 'cities': {
      return (
        <FreeformMultiSelectFieldDEPRECATED
          id="city-field"
          label={t('Cities')}
          value={filter.value || []}
          onChange={value => {
            setFilterValue(filter, value)
          }}
        />
      )
    }
    case 'meetings_with_user': {
      return (
        <UserSelectFieldDEPRECATED
          id="host-select"
          label={t('Host')}
          isMulti
          onSelect={value => setFilterValue(filter, value)}
          users={filter.value || []}
          usePortal
        />
      )
    }
    case 'events_by_type': {
      return (
        <EventTypeSelectFieldDEPRECATED
          eventTypes={filter.value}
          onSelect={types => setFilterValue(filter, types)}
          isMulti
        />
      )
    }
    case 'events_by_venue': {
      return (
        <LocationSelectFieldDEPRECATED
          id="venue"
          label={t('Venue')}
          locations={filter.value}
          locationTypes={[LOCATION_TYPES.venue]}
          isMulti
          onSelect={value => {
            setFilterValue(filter, value)
          }}
        />
      )
    }
    case 'events_by_org': {
      return (
        <OrganizationSelectFieldDEPRECATED
          id="organization"
          label={t('Organization')}
          isMulti
          noOptionsMessage={t('organizations not found.')}
          organizations={filter.value}
          onSelect={value => setFilterValue(filter, value)}
          canSelectAll
          usePortal
        />
      )
    }
    case 'events_by_team': {
      return (
        <TeamSelectFieldDEPRECATED
          id="teams"
          label={t('Team')}
          noOptionsMessage={t('teams not found.')}
          teams={filter.value}
          isMulti
          onSelect={value => setFilterValue(filter, value)}
          usePortal
        />
      )
    }
    case 'event_responsibilities': {
      return (
        <EventResponsibilitiesField filter={filter} filterType={filterType} />
      )
    }
    case 'event_by_creator': {
      return <ByEventCreatorField filter={filter} />
    }
    case 'turfs': {
      return (
        <TurfMultiSelectFieldDEPRECATED
          turfs={(filter.value || []).map(id => filter.meta[id])}
          onSelect={value =>
            setFilterValue(
              filter,
              value.map(value => value.id),
              value.reduce(toKeyValueMap, {})
            )
          }
        />
      )
    }
    default: {
      return null
    }
  }
}

export default FilterCardField
