import { useEffect, useState } from 'react'
import { Toast } from 'components'
import { useTranslation } from 'react-i18next'
import styles from './NetworkToast.module.scss'

const NetworkToast = () => {
  const { t } = useTranslation()

  const [hasInternet, setHasInternet] = useState<boolean | null>(null)

  useEffect(() => {
    window.addEventListener('online', () => {
      setHasInternet(true)
      setTimeout(() => {
        setHasInternet(null)
      }, 3000)
    })

    window.addEventListener('offline', () => {
      setHasInternet(false)
    })
  }, [])

  if (hasInternet === null) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <Toast
        visible
        success={hasInternet}
        error={!hasInternet}
        text={
          hasInternet
            ? t('Your connection has been reestablished.')
            : t('Your internet connection has been lost.')
        }
      />
    </div>
  )
}

export default NetworkToast
