import { useTranslation } from 'react-i18next'
import {
  advanceFlagStatus,
  requestReviewableCurrentFlag,
} from 'store/qcFlags/actions'
import {
  getCurrentFlag,
  doesCurrentFlagNeedReupload,
  isCurrentFlagComplete,
  doesCurrentFlagHaveDuplicates,
} from 'store/qcFlags/reducer'
import { connect } from 'react-redux'
import {
  useReactRouter,
  DEPRECATED_useRequest,
  useRoutePathParams,
} from 'hooks'
import { CardError, LoadBar } from 'components'
import {
  ButtonBlock,
  Button,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts/index'
import { useContext } from 'react'
import { ToastContext } from 'contexts/ToastProvider/ToastProvider'
import {
  canFlagBeAdvanced,
  getReadyButtonSubText,
  getReadyButtonText,
  isFlagTechnical,
} from './utils'
import DeleteFlagButton from './buttons/DeleteFlagButton'
import { usePacketAssignment } from '../packetAssignment/PacketAssignmentContext'

const FlagSingleReadyButton = ({
  advanceFlagStatus,
  flag,
  requestReviewableCurrentFlag,
  needsReupload,
  isComplete,
  hasDuplicateFlags,
}) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()
  const [, replaceParams] = useRoutePathParams()
  const { setToast } = useContext(ToastContext)
  const { type, originalType } = match.params

  const { currentTurfPerformsExternalQC } = useCurrent()
  const { unassignPacket } = usePacketAssignment()

  const { makeRequest, isLoading, errorMsg } = DEPRECATED_useRequest(
    async flagAction => {
      await advanceFlagStatus(flagAction)
      return flagAction
    },
    async flagAction => {
      const text =
        flagAction === 'reopen'
          ? 'Flag has been restored'
          : 'Flag has been advanced'
      const nextId = await requestReviewableCurrentFlag(
        type,
        currentTurfPerformsExternalQC
      )
      setToast({ text: t(text), success: true })
      nextId
        ? history.push(replaceParams({ flagId: nextId }))
        : history.push(`/quality_control/flags/${originalType}`)
    }
  )

  const duplicatesPresent = isComplete && hasDuplicateFlags

  if (needsReupload && isComplete) {
    return null
  }

  return (
    <div>
      <LoadBar show={isLoading} />
      <CardError hide={!errorMsg} message={errorMsg} />
      <div>
        <ButtonBlock>
          <Button
            raised
            accent
            onClick={() => makeRequest(type)}
            disabled={
              !canFlagBeAdvanced(flag, type, needsReupload) || duplicatesPresent
            }
          >
            {t(getReadyButtonText(type, needsReupload))}
          </Button>
          {flag.status === 'reviewed' && (
            <Button
              onClick={() => makeRequest('reopen')}
              disabled={
                !canFlagBeAdvanced(flag, 'reopen', needsReupload) ||
                duplicatesPresent
              }
            >
              {t(getReadyButtonText('reopen', needsReupload))}
            </Button>
          )}
          {type !== 'review' && (
            <DeleteFlagButton
              onDelete={async () => {
                if (isFlagTechnical(flag)) {
                  await unassignPacket(flag.packet)
                }
                history.push('/quality_control/flags/start')
              }}
              flag={flag}
            />
          )}
        </ButtonBlock>
        {!duplicatesPresent && canFlagBeAdvanced(flag, type) && (
          <TextBlock>{t(getReadyButtonSubText(type))}</TextBlock>
        )}
      </div>
    </div>
  )
}

export default connect(
  state => ({
    flag: getCurrentFlag(state),
    needsReupload: doesCurrentFlagNeedReupload(state),
    isComplete: isCurrentFlagComplete(state),
    hasDuplicateFlags: doesCurrentFlagHaveDuplicates(state),
  }),
  {
    advanceFlagStatus,
    requestReviewableCurrentFlag,
  }
)(FlagSingleReadyButton)
