import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks'
import { fetchUsers } from 'requests/users'
import { ViewContainer, TurfSelectField } from 'components'
import {
  Checkbox,
  SelectField,
  DateField,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import moment from 'moment-timezone'
import useCheckins from 'checkins/hooks/useCheckins'
import { formatDisplayName } from 'utils/formatting'
import { useCurrent, useTurfs } from 'contexts/index'
import ResponseContainer from '../ResponseContainer/ResponseContainer'

const FIRST_ANSWER = 0
const DATETIME_START_POSITION = 0
const DATETIME_END_POSITION = 16

const ReviewControls = () => {
  const { t } = useTranslation()
  const { preparedTurfs: turfs } = useTurfs()

  const {
    currentUser: { time_zone: userTimezone },
  } = useCurrent()

  const [respondentOptions, setRespondentOptions] = useState([])
  const [respondent, setRespondent] = useState({ name: '', id: 0 })
  const [responseNum, setResponseNum] = useState(0)
  const [showArchived, setShowArchived] = useState(false)

  const {
    reviewAnswers,
    reviewTurfId,
    reviewSortDirection,
    answersPage,
    totalAnswers,
    setAnswersPage,
    setReviewTurfId,
    setReviewUserId,
    setReviewStartRange,
    setReviewEndRange,
    setReviewSortDirection,
    setQuestionToDelete,
    reviewIsLoading,
    answersPerPage,
  } = useCheckins()

  const [startDate, setStartDate] = useState(
    moment().subtract(10, 'days').toISOString()
  )
  const [endDate, setEndDate] = useState(moment().toISOString())

  const [groupedAnswers, setGroupedAnswers] = useState({})

  const utcOffset = Math.abs(moment().tz(userTimezone).utcOffset() / 60)

  useEffect(() => {
    if (!reviewAnswers) return
    const newGroupedAnswers = {}
    reviewAnswers.forEach(a => {
      a.created_at = moment(a.created_at).subtract(utcOffset, 'hours').format()
      const key = `${a?.user?.id}-${a?.created_at.slice(0, 10)}`
      if (!newGroupedAnswers[key]) newGroupedAnswers[key] = [a]
      else newGroupedAnswers[key].push(a)
    })
    setGroupedAnswers(Object.values(newGroupedAnswers))
  }, [reviewAnswers])

  const { makeRequest: fetchRespondents } = useRequest(
    () =>
      fetchUsers({
        filters: {
          column: 'turf_name',
          operator: 'is',
          param: turfs.find(t => t.id === reviewTurfId).name,
        },
      }),
    {
      onSuccess: ({ users }) => {
        const newUserArray = [{ id: 0, name: t('All respondents') }, ...users]
        setRespondentOptions(newUserArray)
        setRespondent(newUserArray[0])
      },
    }
  )

  const changeDateRange = (incomingStartDate, incomingEndDate) => {
    if (!incomingStartDate || !incomingEndDate) return
    setStartDate(incomingStartDate)
    setEndDate(incomingEndDate)
    setAnswersPage(1)
    setResponseNum(0)
    setReviewStartRange(
      moment(incomingStartDate)
        .subtract(12 - utcOffset, 'hours')
        .format()
        .slice(DATETIME_START_POSITION, DATETIME_END_POSITION)
    )
    setReviewEndRange(
      moment(incomingEndDate)
        .add(12 + utcOffset, 'hours')
        .format()
        .slice(DATETIME_START_POSITION, DATETIME_END_POSITION)
    )
  }

  useEffect(() => {
    if (!turfs.length) return
    setAnswersPage(1)
    setReviewTurfId(turfs[0].id)
  }, [turfs])

  useEffect(() => {
    if (reviewTurfId) fetchRespondents()
  }, [reviewTurfId])

  useEffect(() => {
    if (reviewTurfId) {
      setAnswersPage(1)
      setResponseNum(0)
      setReviewTurfId(reviewTurfId)
      setReviewUserId(respondent.id)
      setReviewStartRange(
        moment()
          .subtract(10, 'days')
          .format()
          .slice(DATETIME_START_POSITION, DATETIME_END_POSITION)
      )
      setReviewEndRange(
        moment()
          .add(10, 'days')
          .format()
          .slice(DATETIME_START_POSITION, DATETIME_END_POSITION)
      )
    }
  }, [respondent])

  const selectRespondent = () => {
    setResponseNum(0)
    const userIndex = respondentOptions.findIndex(
      r => r.id === groupedAnswers[responseNum][FIRST_ANSWER].user.id
    )
    setRespondent(respondentOptions[userIndex])
  }

  const selectTurf = turfId => {
    setResponseNum(0)
    setAnswersPage(1)
    setReviewTurfId(turfId)
  }

  return (
    <ViewContainer>
      <FieldBlock variant="medium">
        <TurfSelectField
          id="turf"
          label={t('Turf')}
          value={reviewTurfId}
          onSelect={val => {
            setAnswersPage(1)
            setReviewTurfId(val)
          }}
        />
        <DateField
          type="range"
          label={t('Date range')}
          onChange={changeDateRange}
          startDate={startDate}
          endDate={endDate}
        />
        <SelectField
          id="respondents"
          label={t('Respondents')}
          value={respondent}
          options={respondentOptions.map(user => ({
            label: formatDisplayName(user),
            value: user,
          }))}
          onSelect={val => {
            setAnswersPage(1)
            setReviewUserId(val.id)
            setRespondent(val)
          }}
        />
      </FieldBlock>
      <FieldBlock>
        <SelectField
          id="sort"
          label={t('Sort by')}
          value={reviewSortDirection}
          options={[
            { label: t('Latest'), value: 'desc' },
            { label: t('Oldest'), value: 'asc' },
          ]}
          onSelect={val => {
            setAnswersPage(1)
            setReviewSortDirection(val)
          }}
        />
        <Checkbox
          id="show-archived"
          checked={showArchived}
          label={t('Show archived questions')}
          onChange={() => setShowArchived(prev => !prev)}
        />
      </FieldBlock>
      <ResponseContainer
        reviewTurfId={reviewTurfId}
        reviewIsLoading={reviewIsLoading}
        responses={groupedAnswers}
        responseNum={responseNum}
        setResponseNum={setResponseNum}
        selectRespondent={selectRespondent}
        selectTurf={selectTurf}
        setQuestionToDelete={setQuestionToDelete}
        answersPage={answersPage}
        totalAnswers={totalAnswers}
        setAnswersPage={setAnswersPage}
        answersPerPage={answersPerPage}
        showArchived={showArchived}
      />
    </ViewContainer>
  )
}

export default ReviewControls
