import React from 'react'
import {
  Font,
  Button,
  ContentBlock,
  TextBlock,
} from '@politechdev/blocks-design-system'
import IntercomChat from 'components/IntercomChatButton/IntercomChatButton'
import { Sheet, ViewContainer } from 'components'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { DataEntryUploadStorage } from 'shifts/storageUtils'

const IneligibleForEntry = ({ shiftStatus, packetExists, isScansExpired }) => {
  const { t } = useTranslation()
  const { shiftId: shiftIdString } = useParams()
  const history = useHistory()
  const shiftId = parseInt(shiftIdString)

  const handleNonExistentShift = () => {
    DataEntryUploadStorage.removeFromStorage(shiftId)
    history.push(`/collect/voter_registration/shifts`)
  }

  const getErrorMessage = () => {
    if (packetExists) {
      return t('This packet has been uploaded already.')
    }
    if (isScansExpired) {
      return t(
        'The temporary packet upload has expired. Please reupload scans.'
      )
    }
    if (shiftStatus !== 'ready_for_scans') {
      return t('This shift already has data associated with it.')
    }
    return t(
      'This shift may have been deleted by another user, or another issue is causing our system to having trouble finding it.'
    )
  }

  return (
    <ViewContainer>
      <Sheet title={t('Ineligible for data entry')}>
        <ContentBlock>
          <TextBlock>
            <Font.Copy Element="p">{getErrorMessage()}</Font.Copy>
            <Font.Copy Element="p">
              {t('You can try again at a later time or contact support.')}
            </Font.Copy>
          </TextBlock>
        </ContentBlock>
        <IntercomChat
          otherActions={
            <Button.Accent onClick={handleNonExistentShift}>
              {t('Back to Shifts')}
            </Button.Accent>
          }
        />
      </Sheet>
    </ViewContainer>
  )
}

export default IneligibleForEntry
