import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TimePicker,
  Grid,
  Section,
  Button,
  ButtonBlock,
  FieldBlock,
} from '@politechdev/blocks-design-system'
import moment from 'moment-timezone'
import { useRequest } from 'hooks/useRequest'
import {
  deleteEventShift,
  deleteEventShiftVolunteer,
  postEventShift,
  postEventShiftVolunteer,
  putEventShiftVolunteer,
} from 'requests/events'
import { useCurrent } from 'contexts/index'
import VolunteerAssignment from '../VolunteerAssignment/VolunteerAssignment'
import { EventContext } from '../EventContext/EventContext'

const Shifts = () => {
  const { t } = useTranslation()
  const { currentEvent, setCurrentEvent } = useContext(EventContext)
  const {
    currentUser: { time_zone: timezone },
  } = useCurrent()

  const sortedShifts = currentEvent.shifts.sort(
    (a, b) => new Date(a.start_time) - new Date(b.end_time)
  )

  const changeEventShifts = shifts => {
    setCurrentEvent({ ...currentEvent, shifts })
  }
  const changeEventShiftVolunteers = (shiftId, volunteers) => {
    changeEventShifts(
      currentEvent.shifts.map(shift => {
        if (shift.id !== shiftId) {
          return shift
        }
        return {
          ...shift,
          volunteers,
        }
      })
    )
  }

  const { makeRequest: createEventShift } = useRequest(postEventShift, {
    onSuccess: ({ event_shift }) => {
      changeEventShifts([
        ...currentEvent.shifts,
        { ...event_shift, volunteers: [] },
      ])
    },
  })

  const { makeRequest: removeEventShift } = useRequest(deleteEventShift, {
    onSuccess: shiftId => {
      changeEventShifts(
        currentEvent.shifts.filter(shift => shift.id !== shiftId)
      )
    },
  })

  const { makeRequest: createEventShiftVolunteer } = useRequest(
    postEventShiftVolunteer,
    {
      onSuccess: ({ volunteer, shiftId }) => {
        const currentShift = currentEvent.shifts.find(
          shift => shift.id === shiftId
        )
        changeEventShiftVolunteers(shiftId, [
          ...currentShift.volunteers,
          volunteer,
        ])
      },
    }
  )

  const { makeRequest: updateEventShiftVolunteer } = useRequest(
    putEventShiftVolunteer,
    {
      onSuccess: ({ shiftId, volunteer: incomingVolunteer }) => {
        const currentShift = currentEvent.shifts.find(
          shift => shift.id === shiftId
        )
        changeEventShiftVolunteers(
          shiftId,
          currentShift.volunteers.map(volunteer => {
            if (volunteer.id !== incomingVolunteer.id) {
              return volunteer
            }
            return incomingVolunteer
          })
        )
      },
    }
  )

  const { makeRequest: removeEventShiftVolunteer } = useRequest(
    deleteEventShiftVolunteer,
    {
      onSuccess: ({ shiftId, volunteerId }) => {
        const currentShift = currentEvent.shifts.find(
          shift => shift.id === shiftId
        )
        changeEventShiftVolunteers(
          shiftId,
          currentShift.volunteers.filter(
            volunteer => volunteer.id !== volunteerId
          )
        )
      },
    }
  )

  const [startTime, setStartTime] = useState({})
  const [endTime, setEndTime] = useState({})
  const [timeError, setTimeError] = useState(false)

  useEffect(() => {
    setTimeError(false)
    if (moment(startTime).isAfter(moment(endTime))) {
      setTimeError(true)
    }
  }, [startTime, endTime])

  const handleCreateShift = () => {
    createEventShift(currentEvent.id, {
      start_time: moment
        .tz(
          `${moment(currentEvent.start_time).format('YYYY-MM-DD')} ${moment(
            startTime
          ).format('HH:mm:ss')}`,
          timezone
        )
        .format(),
      end_time: moment
        .tz(
          `${moment(currentEvent.end_time).format('YYYY-MM-DD')} ${moment(
            endTime
          ).format('HH:mm:ss')}`,
          timezone
        )
        .format(),
    })
  }

  return (
    <Grid>
      <Section
        label={t('Shifts')}
        empty={!sortedShifts.length}
        emptyMessage={t('Create a new shift to assign a volunteer to a shift')}
      >
        {sortedShifts.map(shift => (
          <VolunteerAssignment
            key={shift.id}
            label={`${moment(shift.start_time).format('h:mm A')} - ${moment(
              shift.end_time
            ).format('h:mm A')}`}
            assignmentLabel={t('Shift')}
            assignmentId={shift.id}
            volunteers={shift.volunteers}
            eventId={currentEvent.id}
            onAddVolunteer={createEventShiftVolunteer}
            onUpdateVolunteer={updateEventShiftVolunteer}
            onRemoveVolunteer={removeEventShiftVolunteer}
            onRemoveAssignment={removeEventShift}
            showRole
            isShift
          />
        ))}
      </Section>
      <Section label={t('New shift')}>
        <FieldBlock>
          <TimePicker
            label="Start Time"
            id="event-start-time"
            onSelect={setStartTime}
            hour={startTime.hour}
            minute={startTime.minute}
            error={timeError}
            errorMessage={t('Start time must be before end time.')}
          />
          <TimePicker
            label="End Time"
            id="event-end-time"
            onSelect={setEndTime}
            hour={endTime.hour}
            minute={endTime.minute}
          />
        </FieldBlock>
        <ButtonBlock>
          <Button.Accent
            onClick={handleCreateShift}
            disabled={!startTime || !endTime || timeError}
          >
            {t('Add')}
          </Button.Accent>
        </ButtonBlock>
      </Section>
    </Grid>
  )
}

export default Shifts
