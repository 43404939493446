import React from 'react'
import { PdfViewer } from 'components'
import { useTranslation } from 'react-i18next'
import { Font, Button, Icon } from '@politechdev/blocks-design-system'
import styles from './ShiftDataEntry.module.scss'

function PledgeCardHeader({ file, page, totalPages }) {
  const { t } = useTranslation()
  return (
    <div className={styles['pdf-header']}>
      <Font.Copy variant="highlight">
        {page === 0
          ? t('Cover sheet')
          : t('Pledge card {{ page }} of {{ totalPages }}', {
              page,
              totalPages,
            })}
      </Font.Copy>
      <a href={file} target="_blank" rel="noopener noreferrer">
        <Button.Secondary>
          <Icon.ExternalLink aria-label="open-pdf" />
        </Button.Secondary>
      </a>
    </div>
  )
}

function PledgeCardViewer({ fileUrl, page, totalPages }) {
  if (!fileUrl) return null
  return (
    <PdfViewer
      file={fileUrl}
      CustomHeader={PledgeCardHeader}
      headerProps={{ file: fileUrl, page, totalPages }}
    />
  )
}

export default PledgeCardViewer
