/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react'
import {
  Font,
  FieldBlock,
  Section,
  SectionLabel,
  DateField,
  TextField,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { ContentBlock, InterestCheckboxGroup } from 'components'
import { useParams } from 'react-router'
import { getTotalPagesFromFilename } from 'shifts/utils'
import { DataEntryUploadStorage } from 'shifts/storageUtils'
import PledgeCardViewer from './PledgeCardViewer'
import styles from './ShiftDataEntry.module.scss'
import AddressLookup from './AddressLookup'
import { isValidPledgeCardForm } from './utils'

const getInterestOptions = t =>
  [
    { value: 'reproductive_rights', label: t('Reproductive rights') },
    { value: 'lgbtq_equality', label: t('LGBTQ equality') },
    { value: 'climate_justice', label: t('Climate justice') },
    { value: 'voting_rights', label: t('Voting rights') },
    { value: 'voter_id', label: t('Voter ID') },
    { value: 'immigration', label: t('Racial and Immigrant justice') },
    { value: 'criminal_justice', label: t('Criminal justice') },
    { value: 'income_inequality', label: t('Income inequality') },
    { value: 'marijuana_legalization', label: t('Marijuana legalization') },
    { value: 'student_debt', label: t('Student Debt') },
    { value: 'healthcare_access', label: t('Healthcare access') },
    { value: 'wants_to_volunteer', label: t('I want to volunteer') },
  ].sort((a, b) => (a.label > b.label ? 1 : -1))

const PledgeCardForm = ({
  setField,
  getField,
  formData,
  fieldErrors,
  setFieldError,
  submitAttempted,
}) => {
  const fieldSetter = field => value => {
    setField(value, field)
  }

  const { t } = useTranslation()
  const { shiftId, pageId } = useParams()
  const [currentPage, setCurrentPage] = useState(null)
  const [totalPages, setTotalPages] = useState(0)
  const fileUrl = currentPage && currentPage.url

  const interestOptions = getInterestOptions(t)

  useEffect(() => {
    if (shiftId && pageId) {
      const scan = DataEntryUploadStorage.getRecordFromStorage(shiftId, pageId)
      setCurrentPage(scan)
      setTotalPages(getTotalPagesFromFilename(scan.metadata.filename))
    } else {
      setCurrentPage(null)
      setTotalPages(0)
    }
  }, [shiftId, pageId])

  useEffect(() => {
    isValidPledgeCardForm(formData)
      ? setFieldError('meta.pledgeCard', null)
      : setFieldError('meta.pledgeCard', 'needs pledge card data')
  }, [formData])

  return (
    <Section>
      <ContentBlock>
        <SectionLabel>{t('Pledge card data')}</SectionLabel>
        <hr className={styles.hr} />
        {fieldErrors['meta.pledgeCard'] && submitAttempted && (
          <Font.Copy variant="hint" className={styles['error-text']}>
            {t('This section cannot be empty')}
          </Font.Copy>
        )}
      </ContentBlock>
      <div className={styles['two-panel']}>
        <div>
          <FieldBlock>
            <div>
              <Font.Copy Element="label" variant="secondary">
                {t('Registrant Name')}
              </Font.Copy>
              <Checkbox
                label={t('Same as registration form')}
                onChange={fieldSetter('meta.pledgeCard.nameSameAsRegistration')}
                checked={getField('meta.pledgeCard.nameSameAsRegistration')}
              />
            </div>
          </FieldBlock>
          {getField('meta.pledgeCard.nameSameAsRegistration') ? null : (
            <FieldBlock className={styles['field-block']}>
              <TextField
                type="text"
                name="pledge_card.first_name"
                id="pledge_card.first_name"
                label={t('First name')}
                value={getField('pledge_card.first_name')}
                onChange={fieldSetter('pledge_card.first_name')}
              />
              <TextField
                type="text"
                name="pledge_card.middle_name"
                id="pledge_card.middle_name"
                label={t('Middle name')}
                value={getField('pledge_card.middle_name')}
                onChange={fieldSetter('pledge_card.middle_name')}
              />
              <TextField
                type="text"
                name="pledge_card.last_name"
                id="pledge_card.last_name"
                label={t('Last name')}
                value={getField('pledge_card.last_name')}
                onChange={fieldSetter('pledge_card.last_name')}
              />
            </FieldBlock>
          )}
          <br />
          <FieldBlock className={styles['field-block']}>
            <TextField
              type="tel"
              id="pledge_card.phone_number"
              label={t('Phone number')}
              value={getField('pledge_card.phone_number')}
              onChange={fieldSetter('pledge_card.phone_number')}
            />
            <TextField
              type="email"
              id="pledge_card.email"
              label={t('Email')}
              value={getField('pledge_card.email')}
              onChange={fieldSetter('pledge_card.email')}
            />
          </FieldBlock>
          <FieldBlock className={styles['field-block']}>
            <DateField
              label={t('Date of birth')}
              value={getField('pledge_card.date_of_birth')}
              onChange={fieldSetter('pledge_card.date_of_birth')}
            />
          </FieldBlock>
          <FieldBlock>
            <div>
              <Font.Copy Element="label" variant="secondary">
                {t('Mailing Address')}
              </Font.Copy>
              <Checkbox
                label={t('Same as registration form')}
                onChange={fieldSetter(
                  'meta.pledgeCard.addressSameAsRegistration'
                )}
                checked={getField('meta.pledgeCard.addressSameAsRegistration')}
              />
            </div>
          </FieldBlock>

          {getField('meta.pledgeCard.addressSameAsRegistration') ? null : (
            <AddressLookup
              prefix="pledge_card.address"
              setField={fieldSetter}
              getField={getField}
            />
          )}
          <FieldBlock>
            <div>
              <Font.Copy Element="label" variant="secondary">
                {t('I care about')}
              </Font.Copy>
              <InterestCheckboxGroup
                options={interestOptions}
                values={getField('pledge_card.i_care_about', [])}
                onChange={fieldSetter('pledge_card.i_care_about')}
              />
            </div>
          </FieldBlock>
        </div>
        <ContentBlock>
          <PledgeCardViewer
            fileUrl={fileUrl}
            page={+pageId}
            totalPages={totalPages - 1}
          />
        </ContentBlock>
      </div>
    </Section>
  )
}
export default PledgeCardForm
