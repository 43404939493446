import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createRowAction } from 'components/DataTable/utils'
import { AutoTableProvider, useCurrent, useAutoTable } from 'contexts'
import { useReactRouter, useEvent, useRequest } from 'hooks'
import { destroyLocation, fetchLocations } from 'requests/locations'
import { formatErrorMessage } from 'utils/formatting'
import tableConfig from './tableConfig'

const LocationTable = ({ tableType }) => {
  const { t } = useTranslation()
  const { match, history } = useReactRouter()

  const { renderTable, refreshPage } = useAutoTable()
  const { doesCurrentUserHavePermission } = useCurrent()
  const canModifyLocation = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'modify',
  })

  const canDeleteLocation = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'remove',
  })

  const [locations, setLocations] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)

  const {
    makeRequest: deleteRequest,
    isLoading,
    errors,
  } = useRequest(destroyLocation, {
    onSuccess: refreshPage,
  })

  const errorMsg = formatErrorMessage(errors)

  const { makeRequest: locationsRequest } = useRequest(
    params =>
      fetchLocations({
        current_page: params?.page,
        per: params?.pageSize,
        sort_attr: params?.sortColumn,
        sort_dir: params?.sortOrder,
        filters: {
          rules: [
            params?.filterRules?.length
              ? {
                  rules: params?.filterRules,
                  conjunction: params?.filterConjunction,
                }
              : undefined,
            tableConfig[tableType].tableFilters,
          ],
        },
        indexed: true,
        fields: [
          'id',
          'name',
          'street_address',
          'city',
          'state',
          'county',
          'zipcode',
          'location_type',
          'score',
          'notes',
          'has_hosted_event',
          'has_public_transportation',
          'maximum_occupancy',
          'has_it_support',
          'has_wifi',
          'has_av',
          'has_projector',
          'contact_name',
          'contact_phone',
          'contact_email',
          'largest_turnout',
          'parking_spots_available',
          'rooms_available',
          'latitude',
          'longitude',
          'geoid',
          'forms_per_hour',
          'signatures_per_hour',
          'category',
        ],
      }),
    {
      onSuccess: ({ locations, meta }) => {
        setLocations(locations)
        setTotalRecords(meta.total_count)
      },
    }
  )

  const fetchAction = useEvent(params => locationsRequest(params))

  const handleEdit = useEvent(rowData =>
    history.push(`${match.url}/${rowData.id}/edit`)
  )

  const handleDelete = rowData => {
    deleteRequest(rowData.id)
  }

  const columns = [
    {
      actions: [
        createRowAction(t('Edit'), handleEdit, canModifyLocation),
        createRowAction(t('Delete'), handleDelete, canDeleteLocation),
      ].filter(Boolean),
      listInline: true,
    },
    ...tableConfig[tableType].columns,
  ]

  return renderTable({
    data: locations,
    loading: isLoading,
    error: errorMsg,
    totalRecords,
    columns,
    fetchAction,
  })
}

export default ({ tableType }) => (
  <AutoTableProvider
    initialTableId={`${tableType}-${tableConfig[tableType].label}`}
    defaultSortColumn={tableConfig[tableType].defaultSortColumn}
    defaultSortOrder={tableConfig[tableType].defaultSortOrder}
    enableQueryParams
    enablePagination
    enableFilters
    enableSort
    showDownloadButton
    showFilterEditor
    showQuickSearch
    showTableLink
    defaultFilters={tableConfig[tableType].defaultFilters}
  >
    <LocationTable tableType={tableType} />
  </AutoTableProvider>
)
