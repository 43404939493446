import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './InputArea.module.scss'

const cx = classNames.bind(styles)

const InputArea = ({
  label,
  children,
  className,
  PrimaryControl,
  SecondaryControl,
}) => (
  <div className={cx('input', className)}>
    <div className={styles.label}>{label}</div>
    <div className={styles.container}>
      <div className={styles.main}>{children}</div>
      {(PrimaryControl || SecondaryControl) && (
        <div className={styles.controls}>
          {PrimaryControl} {SecondaryControl}
        </div>
      )}
    </div>
  </div>
)

InputArea.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

InputArea.defaultProps = {
  label: '',
  className: '',
  children: null,
}

export default InputArea
