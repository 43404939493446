/* eslint-disable no-use-before-define */
import moment from 'moment'
import { FILTER_TYPES_MAP } from '../constants'

const formatFilterForUI = filter => {
  const filterType = FILTER_TYPES_MAP[filter.id]
  let isValid = true

  if (!filterType) return [null, isValid]

  if (filterType.validateFromAPI) {
    isValid = filterType.validateFromAPI(filter, filterType)
  }
  if (filterType.formatForUI) {
    return [filterType.formatForUI(filter, filterType), isValid]
  }

  if (['counties', 'events_by_type'].includes(filterType.id)) {
    const res = Object.values(filter.params).map(({ value }) => value)
    return [res, isValid]
  }

  if (
    [
      'denominations',
      'organizations',
      'teams',
      'events_attended',
      'assigned_to',
      'meetings_with_user',
      'events_by_venue',
      'events_by_org',
    ].includes(filterType.id)
  ) {
    const res = Object.values(filter.params).map(({ id, value }) => ({
      id: Number.isNaN(+id) ? id : +id,
      [filterType.resourceLabelKey]: value,
    }))
    return [res, isValid]
  }

  if (['responsibilities'].includes(filterType.id)) {
    return [
      filter.params?.rules?.find(
        rule => rule.column === 'team_memberships_responsibility'
      )?.param || [],
      isValid,
    ]
  }

  if (['events_by_team'].includes(filterType.id)) {
    return [
      filter.params?.rules?.find(
        rule => rule.column === 'attended_events_teams'
      )?.param || [],
      isValid,
    ]
  }

  if (
    [
      'states',
      'turfs',
      'congressional_districts',
      'state_legislative_upper_districts',
      'state_legislative_lower_districts',
    ].includes(filterType.id)
  ) {
    const res = Object.values(filter.params.rules).map(rule => rule?.param)
    return [res, isValid]
  }

  if (['cities'].includes(filterType.id)) {
    return [
      filter.params.rules
        ? filter.params.rules.map(r => r.param)
        : Object.values(filter.params).map(({ value }) => value),
      isValid,
    ]
  }

  if (['interest_level'].includes(filterType.id)) {
    return [
      filter.params || {
        rules: [
          {
            column: 'interest_level',
            operator: 'in',
            param: [],
          },
        ],
      },
      isValid,
    ]
  }

  if (
    [
      'ethnicities',
      'genders',
      'best_contact_methods',
      'phone_types',
      'email_types',
    ].includes(filterType.id)
  ) {
    return [
      Object.values(filter.params).map(({ id, value }) => ({
        value: id,
        label: value,
      })),
      isValid,
    ]
  }

  if (
    ['primary_languages', 'languages', 'skills', 'issues', 'zipcodes'].includes(
      filterType.id
    )
  ) {
    return [Object.keys(filter.params), isValid]
  }

  if (filterType.id === 'ages') {
    return [
      {
        min: filter.params.min?.value,
        max: filter.params.max?.value,
      },
      isValid,
    ]
  }

  if (filterType.id === 'phone_banking_response') {
    return [filter.params, isValid]
  }

  if (
    [
      'events_attended_by_date',
      'phone_banking_participation_response',
      'event_by_creator',
      'phone_banking_participation',
    ].includes(filterType.id)
  ) {
    return [valueOrFallback(filter.params, { rules: [] }), isValid]
  }

  if (['created_at', 'meetings_by_date'].includes(filterType.id)) {
    return [
      {
        min: filter.params?.min?.value ? moment(filter.params.min.value) : null,
        max: filter.params?.max?.value ? moment(filter.params.max.value) : null,
      },
      isValid,
    ]
  }

  return [null, isValid]
}

export const buildUIFilters = ({ chained = [], ...initialFilters }) => {
  const builtFilters = [
    ...chained
      .map((chainedGroup, index) =>
        Object.values(chainedGroup).map(filter => ({
          ...filter,
          chainId: index + 1,
        }))
      )
      .flat(),
    ...Object.values(initialFilters),
  ]
    .map(filter => {
      const [value, isValid] = formatFilterForUI(filter)
      return {
        id: filter.id,
        chainId: filter.chainId,
        value,
        isValid,
        ...(filter.meta ? { meta: filter.meta } : {}),
      }
    })
    .filter(Boolean)

  return builtFilters
}

const formatFilterForAPI = filter => {
  const filterType = FILTER_TYPES_MAP[filter.id]
  let isValid = true

  if (!filterType) {
    return [null, isValid]
  }

  if (filterType.validateFromUI) {
    isValid = filterType.validateFromUI(filter, filterType)
  }
  if (filterType.formatForAPI) {
    return [filterType.formatForAPI(filter, filterType), isValid]
  }

  if (['counties', 'events_by_type'].includes(filterType.id)) {
    return [
      (filter.value || []).reduce((obj, val) => {
        obj[val.toString()] = {
          id: val.toString(),
          value: val,
        }

        return obj
      }, {}),
      isValid,
    ]
  }

  if (
    [
      'precincts',
      'denominations',
      'organizations',
      'teams',
      'events_attended',
      'assigned_to',
      'meetings_with_user',
      'events_by_venue',
      'events_by_org',
    ].includes(filterType.id)
  ) {
    return [
      (filter.value || []).reduce((obj, val) => {
        obj[val.id.toString()] = {
          id: val.id.toString(),
          value: val[filterType.resourceLabelKey],
        }

        return obj
      }, {}),
      isValid,
    ]
  }

  if (['responsibilities'].includes(filterType.id)) {
    return [
      {
        conjunction: 'inclusive',
        rules: [
          {
            column: 'team_memberships_responsibility',
            operator: 'in',
            param: filter.value || [],
          },
        ],
      },
    ]
  }

  if (['events_by_team'].includes(filterType.id)) {
    return [
      {
        conjunction: 'inclusive',
        rules: [
          {
            column: 'attended_events_teams',
            operator: 'in',
            param: filter.value || [],
          },
        ],
      },
      isValid,
    ]
  }

  if (
    [
      'states',
      'turfs',
      'cities',
      'congressional_districts',
      'state_legislative_upper_districts',
      'state_legislative_lower_districts',
    ].includes(filterType.id)
  ) {
    return [
      {
        conjunction: 'inclusive',
        rules: (filter.value || []).map(value => ({
          column: filterType.column,
          operator: 'is',
          param: value,
        })),
      },
      isValid,
    ]
  }

  if (
    [
      'ethnicities',
      'genders',
      'best_contact_methods',
      'phone_types',
      'email_types',
    ].includes(filterType.id)
  ) {
    return [
      (filter.value || []).reduce((obj, val) => {
        obj[val.value] = {
          id: val.value,
          value: val.label,
        }

        return obj
      }, {}),
      isValid,
    ]
  }

  if (
    ['primary_languages', 'languages', 'skills', 'issues', 'zipcodes'].includes(
      filterType.id
    )
  ) {
    return [
      (filter.value || []).reduce((obj, val) => {
        obj[val] = {
          id: val,
          value: val,
        }

        return obj
      }, {}),
      isValid,
    ]
  }

  if (filterType.id === 'ages') {
    return [
      {
        min: {
          id: 'min',
          value: filter.value?.min,
        },
        max: {
          id: 'max',
          value: filter.value?.max,
        },
      },
      isValid,
    ]
  }

  if (filterType.id === 'phone_banking_response') {
    return [filter.value, isValid]
  }

  if (
    [
      'events_attended_by_date',
      'event_by_creator',
      'phone_banking_participation',
    ].includes(filterType.id)
  ) {
    return [valueOrFallback(filter.value, { rules: [] }), isValid]
  }

  if (filterType.id === 'interest_level') {
    return [filter.value, isValid]
  }

  if (filterType.id === 'phone_banking_participation_response') {
    return [
      filter.value || {
        rules: [
          {
            column: 'phone_bank_calls_participated',
            operator: 'is',
            param: true,
          },
        ],
      },
      isValid,
    ]
  }

  if (['created_at', 'meetings_by_date'].includes(filterType.id)) {
    return [
      {
        min: {
          id: 'min',
          value: filter.value?.min?.format() || null,
        },
        max: {
          id: 'max',
          value: filter.value?.max?.format() || null,
        },
      },
      isValid,
    ]
  }

  return [null, isValid]
}

export const buildAPIFilters = filters => {
  const chainedFilters = filters.filter(filter => filter.chainId)
  const unchainedFilters = filters.filter(filter => !filter.chainId)

  const params = unchainedFilters.reduce((obj, filter) => {
    const [params, isValid] = formatFilterForAPI(filter)
    obj[filter.id] = {
      id: filter.id,
      params,
      isValid,
      ...(filter.meta ? { meta: filter.meta } : {}),
    }

    return obj
  }, {})

  const maxChainId = Math.max(
    ...chainedFilters.map(filter => filter.chainId).filter(Boolean),
    0
  )

  const chained = chainedFilters.reduce((groups, filter) => {
    const [params, isValid] = formatFilterForAPI(filter)
    groups[filter.chainId - 1] = {
      ...(groups[filter.chainId - 1] || {}),
      [filter.id]: {
        id: filter.id,
        params,
        isValid,
        ...(filter.meta ? { meta: filter.meta } : {}),
      },
    }

    return groups
  }, new Array(maxChainId).fill())

  if (chained.length) {
    params.chained = chained
  }

  return params
}

const valueOrFallback = (value, fallback) => value || fallback
